import { gql } from 'graphql-request';

import {
  TodoItemDone,
  TodoItemID,
  TodoItemName,
  TodoItemOrder,
  TodoItemUUID
} from '../todoItemsTypes';

export interface FetchTodoListTodoItemsQueryResponse {
  uuid: TodoItemUUID;
  id: TodoItemID;
  name: TodoItemName;
  order: TodoItemOrder;
  done: TodoItemDone;
}

export const FETCH_TODO_LIST_TODO_ITEMS = gql`
  query IndexTodoListTodoItems(
    $filters: TodoItemsFilters
    $sort: [TodoItemsSortEnum!]
    $limit: Int
    $offset: Int
  ) {
    todoItems(filters: $filters, sort: $sort, limit: $limit, offset: $offset) {
      nodes {
        uuid
        id
        name
        order
        done
      }
      paginationInfo {
        nextPage
        totalCount
      }
    }
  }
`;
