import React, { useCallback } from 'react';

import { IconsEnum } from '../../../../../../../../../assets/icons/types';
import { ItemMessagesListMessageMenuDropdownMessage } from '../../ItemMessagesListMessageMenuDropdown.types';
import { FetchMessagesCacheKey } from '../../../../../../../messagesTypes';

import { usePropagateMessage } from '../../../../../../../hooks/usePropagateMessage';

import { ConfirmModalButton } from '../../../../../../../../../helpers/buttons/ConfirmModalButton';

import {
  PROPAGATE_MESSAGE_QUERY,
  PropagateMessageRecordType
} from '../../../../../../../queries/propagateMessage.query';

import { messagesKeys, words } from '../../../../../../../../../locales/keys';

interface MessageDropdownPropagateButtonProps {
  message: ItemMessagesListMessageMenuDropdownMessage;
  messagesCacheKey: FetchMessagesCacheKey;
}

function MessageDropdownPropagateModalButton({
  message,
  messagesCacheKey
}: MessageDropdownPropagateButtonProps) {
  const {
    propagateMessage,
    propagateMessageLoading,
    propagateMessageReset,
    propagateMessageErrorMessage
  } = usePropagateMessage<PropagateMessageRecordType>({
    indexCacheKey: messagesCacheKey,
    query: PROPAGATE_MESSAGE_QUERY
  });

  const handlePropagateMessage = useCallback(
    () => propagateMessage({ uuid: message.uuid }),
    [propagateMessage, message.uuid]
  );

  return (
    <ConfirmModalButton
      className="text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-3 3xl:px-4 py-1.5 3xl:py-2 w-full flex items-center text-xs 3xl:text-sm whitespace-nowrap"
      errorMessage={propagateMessageErrorMessage}
      i18nButtonText={words.sendToAllTasks}
      i18nSubmitText={words.postMessage}
      i18nText={
        messagesKeys.areYouSureYouWantToSendThisMessageToAllProjectTasks
      }
      i18nTitle={words.sendToAllTasks}
      isLoading={propagateMessageLoading}
      modalIcon={IconsEnum.EXCLAMATION}
      modalIconClassName="h-6 w-6"
      onClose={propagateMessageReset}
      onSubmit={handlePropagateMessage}
    />
  );
}

export default MessageDropdownPropagateModalButton;
