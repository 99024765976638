import { useCallback, useMemo } from 'react';
import { OnDragEndResponder } from '@hello-pangea/dnd';
import { useFieldArray } from 'react-hook-form';
import isEmpty from 'lodash/isEmpty';
import last from 'lodash/last';

import {
  ProjectID,
  ProjectNanoID
} from '../../../../../../projects/projectsTypes';
import { TaskID, TaskNanoID } from '../../../../../../tasks/tasksTypes';
import { MessageVisibleForClient } from '../../../../../../messages/messagesTypes';
import { CreateTodoItemsFormData } from '../../CreateTodoItemsForm.types';
import { CreateTodoItemsFormItemFieldsData } from '../../components/CreateTodoItemsFormItemFields';

import {
  CREATE_MESSAGE_IN_TASK_QUERY,
  CreateMessageInTaskQueryResponse
} from '../../../../../../messages/queries/createMessageInTask.query';
import {
  CREATE_MESSAGE_IN_PROJECT_QUERY,
  CreateMessageInProjectQueryResponse
} from '../../../../../../messages/queries/createMessageInProject.query';

import { useMessagesSettings } from '../../../../../../messages/hooks/useMessagesSettings';
import { useReactHookForm } from '../../../../../../common/hooks/base/useReactHookForm';
import { useCreateMessageInProject } from '../../../../../../messages/hooks/useCreateMessageInProject';
import { useCreateMessageInTask } from '../../../../../../messages/hooks/useCreateMessageInTask';

import {
  TODO_ITEM_NAME_TITLE,
  TODO_ITEM_NAME_WITHOUT_CHECKBOX
} from '../../../../../todoItemsConstants';

import { dateFnsConvert } from '../../../../../../../utils/dateFnsConvert';

import { createTodoItemsFormValidationSchema } from './useCreateTodoItemsForm.schema';

const emptyTodoItem: CreateTodoItemsFormItemFieldsData = {
  name: {
    text: ''
  },
  done: false,
  withoutCheckbox: false
};

interface CreateTodoItemsFormOptions {
  defaultNames?: string[];
  projectId?: ProjectID;
  projectNanoId?: ProjectNanoID;
  taskId?: TaskID;
  taskNanoId?: TaskNanoID;
  visibleForClient?: MessageVisibleForClient;
  afterCreateTodoItems?: () => void;
}

function useCreateTodoItemsForm({
  projectNanoId,
  projectId,
  taskId,
  taskNanoId,
  defaultNames,
  visibleForClient = false,
  afterCreateTodoItems
}: CreateTodoItemsFormOptions) {
  const { messagesSettings } = useMessagesSettings();

  const defaultNamesWithoutEmptyLines =
    !isEmpty(defaultNames) && last(defaultNames) === ''
      ? defaultNames.slice(0, -1)
      : defaultNames;

  const defaultValues = useMemo<CreateTodoItemsFormData>(
    () => ({
      title: '',
      visibleForClient,
      todoItems:
        defaultNamesWithoutEmptyLines?.map((name) => ({
          ...emptyTodoItem,
          name: { text: name }
        })) || []
    }),
    [defaultNamesWithoutEmptyLines, visibleForClient]
  );

  const {
    control,
    handleSubmitReactHookForm,
    register,
    resetForm,
    setValue,
    getValues,
    watch,
    errors
  } = useReactHookForm<CreateTodoItemsFormData>({
    defaultValues,
    isModalForm: true,
    schema: createTodoItemsFormValidationSchema
  });

  const { fields, remove, append, move } = useFieldArray<
    CreateTodoItemsFormData,
    'todoItems'
  >({
    name: 'todoItems',
    control
  });

  const {
    createMessageInTaskLoading,
    createMessageInTaskErrorMessage,
    createMessageInTask
  } = useCreateMessageInTask<CreateMessageInTaskQueryResponse>({
    taskNanoId,
    query: CREATE_MESSAGE_IN_TASK_QUERY
  });

  const {
    createMessageInProjectLoading,
    createMessageInProjectErrorMessage,
    createMessageInProject
  } = useCreateMessageInProject<CreateMessageInProjectQueryResponse>({
    projectNanoId,
    query: CREATE_MESSAGE_IN_PROJECT_QUERY
  });

  const toggleWithoutCheckbox = useCallback<(index: number) => void>(
    (index) => {
      const withoutCheckbox = getValues(`todoItems.${index}.withoutCheckbox`);

      setValue(`todoItems.${index}.withoutCheckbox`, !withoutCheckbox);
    },
    [getValues, setValue]
  );

  const appendTodoItem = useCallback<(name?: string) => void>(
    (name) => {
      append(
        {
          ...emptyTodoItem,
          name: { text: name }
        },
        { focusName: `todoItems.${fields.length || 0}.name` }
      );
    },
    [append, fields.length]
  );

  const batchAppendTodoItem = useCallback<(names: string[]) => void>(
    (names) => {
      if (names.length > 0) {
        const lastItemIndexAfterAppend =
          (fields.length || 0) + names.length - 1;

        append(
          names.map((name) => ({
            ...emptyTodoItem,
            name: { text: name }
          })),
          { focusName: `todoItems.${lastItemIndexAfterAppend}.name` }
        );
      }
    },
    [append, fields.length]
  );

  const handleDragItemEnd = useCallback<OnDragEndResponder>(
    (result) => {
      if (!result.source || !result.destination) {
        return;
      }

      move(result.source.index, result.destination.index);
    },
    [move]
  );

  const resetCreateTodoItemsForm = useCallback<() => void>(() => {
    resetForm(defaultValues);
  }, [resetForm, defaultValues]);

  const handleToggleVisibleForClient = useCallback<() => void>(() => {
    const visibleForClient = getValues('visibleForClient');

    setValue('visibleForClient', !visibleForClient);
  }, [getValues, setValue]);

  return {
    control,
    resetCreateTodoItemsForm,
    createTodoItemsErrorMessage:
      createMessageInTaskErrorMessage || createMessageInProjectErrorMessage,
    createTodoItemsLoading:
      createMessageInTaskLoading || createMessageInProjectLoading,
    handleToggleVisibleForClient,
    visibleForClientValue: watch('visibleForClient'),
    handleCreateTodoItems: handleSubmitReactHookForm({
      onSubmit: async (data) => {
        const todoTitle = data.title
          ? {
              name: TODO_ITEM_NAME_TITLE + data.title,
              order: 0,
              done: true,
              visibleForClients: data.visibleForClient
            }
          : null;

        const todoItems = data.todoItems.map((item, index) => {
          const nameWithoutCheckbox =
            item.withoutCheckbox && item.name?.text
              ? TODO_ITEM_NAME_WITHOUT_CHECKBOX + item.name.text
              : null;

          const defaultName = item.name?.text ? item.name.text : null;

          return {
            name: nameWithoutCheckbox || defaultName,
            order: index + 1,
            done: item.done,
            visibleForClients: data.visibleForClient
          };
        });

        const todoItemsWithTitle = todoTitle
          ? [todoTitle, ...todoItems]
          : todoItems;

        const pinnedAt = messagesSettings.autoPinMessageWithTodoItem
          ? dateFnsConvert.toDateTimeWithTimezone(new Date().toISOString())
          : null;

        if (taskId) {
          await createMessageInTask({
            body: '',
            taskId,
            visibleForClient: data.visibleForClient,
            todoItems: todoItemsWithTitle,
            pinnedAt
          });
        }

        if (projectId) {
          await createMessageInProject({
            body: '',
            projectId,
            visibleForClient: data.visibleForClient,
            todoItems: todoItemsWithTitle,
            pinnedAt
          });
        }

        afterCreateTodoItems?.();
      },
      dirtyFieldsOnly: false
    }),
    register,
    fields,
    fieldsValidationError: errors?.todoItems,
    remove,
    appendTodoItem,
    batchAppendTodoItem,
    handleDragItemEnd,
    toggleWithoutCheckbox
  };
}

export default useCreateTodoItemsForm;
