import { ProjectNanoID } from '../../../projects/projectsTypes';

import {
  CreateMessageInProjectGqlQuery,
  CreateMessageInProjectGqlStatus,
  MessageGqlError,
  MessageUUID,
  MessageBody,
  MessageFileAttachmentIDs,
  MessageSelectedProductIDs,
  MessageSelectedLifestyleIDs,
  MessageSelectedMaterialIDs,
  MessageColors,
  MessageProjectID,
  MessageVisibleForClient,
  MessageMentionedUserIDs,
  MessageTodoItemName,
  MessageTodoItemDone,
  MessageTodoItemVisibleForClients,
  MessageTodoItemOrder,
  MessagePinnedAt,
  MessageID
} from '../../messagesTypes';

import { useCreateQuery } from '../../../common/hooks/base/reactQuery/useCreateQuery';

import { ProjectCache } from '../../../projects/ProjectCache';

export interface CreateMessageInProjectInput {
  uuid?: MessageUUID;
  body: MessageBody;
  mentionedUserIds?: MessageMentionedUserIDs;
  projectId: MessageProjectID;
  visibleForClient: MessageVisibleForClient;
  fileAttachmentIds?: MessageFileAttachmentIDs;
  attachedFileAttachmentIds?: MessageFileAttachmentIDs;
  selectedProductIds?: MessageSelectedProductIDs;
  selectedLifestyleIds?: MessageSelectedLifestyleIDs;
  selectedMaterialIds?: MessageSelectedMaterialIDs;
  colors?: MessageColors;
  todoItems?: {
    name: MessageTodoItemName;
    done: MessageTodoItemDone;
    order?: MessageTodoItemOrder;
    visibleForClients: MessageTodoItemVisibleForClients;
  }[];
  pinnedAt?: MessagePinnedAt;
  repliedMessageId?: MessageID;
}

interface CreateMessageInProjectResponse<CreateMessageInProjectRecordType> {
  createMessageInProject: {
    status: CreateMessageInProjectGqlStatus;
    recordUuid: MessageUUID;
    record: CreateMessageInProjectRecordType;
    errors: CreateMessageInProjectErrors;
  };
}

interface CreateMessageInProjectErrors {
  done: MessageGqlError;
  fullMessages: MessageGqlError;
  name: MessageGqlError;
  projectId: MessageGqlError;
  visibleForClients: MessageGqlError;
}

interface CreateMessageInProjectOptions {
  projectNanoId: ProjectNanoID;
  query: CreateMessageInProjectGqlQuery;
}

const action = 'createMessageInProject';

function useCreateMessageInProject<CreateMessageInProjectRecordType>({
  projectNanoId,
  query
}: CreateMessageInProjectOptions) {
  const {
    createQueryData,
    createQuery,
    createQueryReset,
    createQueryError,
    createQueryErrorMessage,
    createQueryLoading
  } = useCreateQuery<
    CreateMessageInProjectInput,
    CreateMessageInProjectResponse<CreateMessageInProjectRecordType>,
    CreateMessageInProjectErrors,
    CreateMessageInProjectRecordType
  >({
    action,
    cacheKeys: [
      ProjectCache.messagesCacheKey(projectNanoId),
      ProjectCache.pinnedMessagesCacheKey(projectNanoId),
      ProjectCache.lastMessagesCacheKey(projectNanoId)
    ],
    query
  });

  return {
    createMessageInProjectData: createQueryData,
    createMessageInProjectError: createQueryError,
    createMessageInProjectLoading: createQueryLoading,
    createMessageInProjectErrorMessage: createQueryErrorMessage,
    createMessageInProject: createQuery,
    createMessageInProjectReset: createQueryReset
  };
}

export default useCreateMessageInProject;
