type TodoItemType = {
  order: number;
};

function sortTodoItemsByOrder<TodoItem extends TodoItemType>(
  todoItems?: TodoItem[]
): TodoItem[] {
  if (!todoItems) {
    return [];
  }

  return todoItems.sort((a, b) => {
    // Якщо order дорівнює 0, встановлюємо його високим значенням
    const aOrder = a.order === 0 ? Infinity : a.order;
    const bOrder = b.order === 0 ? Infinity : b.order;

    return aOrder - bOrder;
  });
}

export default sortTodoItemsByOrder;
