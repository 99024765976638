import React, { Fragment, memo } from 'react';

import { ProjectNanoID } from '../../../../../../projects/projectsTypes';

import { useAttachmentsModalFoldersTasksList } from './hooks/useAttachmentsModalFoldersTasksList';

import { words } from '../../../../../../../locales/keys';

import { AlertMessage } from '../../../../../../../helpers/AlertMessage';
import { LoadingSkeleton } from '../../../../../../../helpers/LoadingSkeleton';
import {
  FetchMoreInfiniteButtonHelper,
  FetchMoreInfiniteButtonHelperPlacement
} from '../../../../../../../helpers/buttons/FetchMoreInfiniteButtonHelper';

import { FoldersList, FoldersListFolders } from '../../../FoldersList';
import { FoldersListItemFolder } from '../../../FoldersListItem';

interface AttachmentsModalFoldersTasksListProps {
  folders: FoldersListFolders;
  onItemClick?: (folder: FoldersListItemFolder) => void;
  projectNanoId?: ProjectNanoID;
}

function AttachmentsModalFoldersTasksList({
  onItemClick,
  projectNanoId,
  folders
}: AttachmentsModalFoldersTasksListProps) {
  const {
    tasksError,
    tasksIsPlaceholderData,
    tasksFetched,
    tasksFetchingNextPage,
    hasNextTasksPage,
    loadMoreTasks,
    folderList
  } = useAttachmentsModalFoldersTasksList({
    projectNanoId,
    folders
  });
  return (
    <Fragment>
      <AlertMessage addClassName="m-4" message={tasksError} />
      <LoadingSkeleton
        loaded={tasksIsPlaceholderData || tasksFetched}
        count={3}
      >
        <FoldersList folders={folderList} onItemClick={onItemClick} />
        <FetchMoreInfiniteButtonHelper
          errorMessage={tasksError}
          isLoading={tasksFetchingNextPage}
          hasNextPage={hasNextTasksPage}
          i18nText={words.showMore}
          className="py-1.5 pl-3 pr-3 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
          wrapperClassName="text-center"
          placement={FetchMoreInfiniteButtonHelperPlacement.BOTTOM}
          onFetchMore={loadMoreTasks}
        />
      </LoadingSkeleton>
    </Fragment>
  );
}

export default memo<AttachmentsModalFoldersTasksListProps>(
  AttachmentsModalFoldersTasksList
);
