import {
  MessageUUID,
  DownloadMessageFileAttachmentsGqlQuery,
  DownloadMessageFileAttachmentsGqlStatus,
  MessageGqlError,
  MessageDeviceNanoId
} from '../../messagesTypes';

import { useUpdateQuery } from '../../../common/hooks/base/reactQuery/useUpdateQuery';

interface DownloadMessageFileAttachmentsOptions {
  query: DownloadMessageFileAttachmentsGqlQuery;
}

export interface DownloadMessageFileAttachmentsResponse<
  DownloadMessageFileAttachmentsRecordType
> {
  downloadMessageFileAttachments: {
    status: DownloadMessageFileAttachmentsGqlStatus;
    recordUuid: MessageUUID;
    record: DownloadMessageFileAttachmentsRecordType;
    errors: DownloadMessageFileAttachmentsError;
  };
}

export interface DownloadMessageFileAttachmentsInput {
  uuid: MessageUUID;
  deviceNanoId: MessageDeviceNanoId;
}

export interface DownloadMessageFileAttachmentsError {
  fullMessages: MessageGqlError;
}

const action = 'downloadMessageFileAttachments';

function useDownloadMessageFileAttachments<
  DownloadMessageFileAttachmentsRecordType
>({ query }: DownloadMessageFileAttachmentsOptions) {
  const {
    updateQueryData,
    updateQuery,
    updateQueryReset,
    updateQueryError,
    updateQueryLoading,
    updateQueryErrorMessage
  } = useUpdateQuery<
    DownloadMessageFileAttachmentsInput,
    DownloadMessageFileAttachmentsResponse<DownloadMessageFileAttachmentsRecordType>,
    DownloadMessageFileAttachmentsError,
    DownloadMessageFileAttachmentsRecordType
  >({ action, query });

  return {
    downloadMessageFileAttachmentsData: updateQueryData,
    downloadMessageFileAttachmentsError: updateQueryError,
    downloadMessageFileAttachmentsLoading: updateQueryLoading,
    downloadMessageFileAttachmentsErrorMessage: updateQueryErrorMessage,
    downloadMessageFileAttachments: updateQuery,
    downloadMessageFileAttachmentsReset: updateQueryReset
  };
}

export default useDownloadMessageFileAttachments;
