import { useMemo } from 'react';

import { useTranslate } from '../../../../../../../common/hooks/useTranslate';

import { MultiSelectDataType } from '../../../../../../../helpers/MultiSelect/types';

import { projectsKeys, tasksKeys } from '../../../../../../../locales/keys';
import {
  MessageProjectUUID,
  MessageTaskUUID
} from '../../../../../messagesTypes';

export const FORWARD_MESSAGE_DEFAULT_TASK_VALUE = 'project_messages';

interface useForwardMessageFormDefaultValueProps {
  projectUuid: MessageProjectUUID;
  taskUuid?: MessageTaskUUID;
  withAttachments: boolean;
}

function useForwardMessageFormDefaultValue({
  projectUuid,
  taskUuid,
  withAttachments
}: useForwardMessageFormDefaultValueProps) {
  const { t } = useTranslate();

  const taskDefaultValue = useMemo<MultiSelectDataType[]>(() => {
    if (taskUuid) {
      return [
        {
          value: taskUuid,
          label: t(tasksKeys.current)
        }
      ];
    }

    return [
      {
        value: FORWARD_MESSAGE_DEFAULT_TASK_VALUE,
        label: t(projectsKeys.messages)
      }
    ];
  }, [taskUuid, t]);

  const projectDefaultValue = useMemo<MultiSelectDataType>(() => {
    return {
      value: projectUuid,
      label: t(projectsKeys.current)
    };
  }, [projectUuid, t]);

  return {
    taskDefaultValue,
    projectDefaultValue,
    includesAttachmentsDefaultValue: withAttachments
  };
}

export default useForwardMessageFormDefaultValue;
