import React from 'react';
import size from 'lodash/size';

import { ColorButton, ColorButtonCommonProps } from '../ColorButton';

import { ColorButtonsColors } from './ColorButtons.types';

interface ColorButtonsProps {
  colors: ColorButtonsColors;
}

function ColorButtons({
  colors,
  icon,
  isVisibleForClient,
  onClick
}: ColorButtonsProps & Omit<ColorButtonCommonProps, 'color'>) {
  if (size(colors) === 0) {
    return null;
  }

  return (
    <div className="-mt-0.5 -mr-0.5 flex flex-wrap">
      {colors.map((color) => (
        <ColorButton
          key={`color-${color}`}
          icon={icon}
          color={color}
          isVisibleForClient={isVisibleForClient}
          onClick={onClick}
        />
      ))}
    </div>
  );
}

export default ColorButtons;
