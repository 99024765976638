import { gql } from 'graphql-request';

import {
  MessageBody,
  MessageRed,
  MessageUUID,
  MessageVisibleForClient
} from '../messagesTypes';

export interface ForwardMessageQueryResponse {
  uuid: MessageUUID;
  body: MessageBody;
  visibleForClient: MessageVisibleForClient;
  red: MessageRed;
}

export const FORWARD_MESSAGE_QUERY = gql`
  mutation ForwardMessage(
    $uuid: ID!
    $body: String!
    $projectUuid: UUID
    $taskUuids: [UUID!]
    $red: Boolean
    $includesAttachments: Boolean
    $visibleForClient: Boolean
    $mentionedUserIds: [ID!]
  ) {
    forwardMessage(
      input: {
        uuid: $uuid
        body: $body
        projectUuid: $projectUuid
        taskUuids: $taskUuids
        red: $red
        includesAttachments: $includesAttachments
        visibleForClient: $visibleForClient
        mentionedUserIds: $mentionedUserIds
      }
    ) {
      recordUuid
      status
      record {
        uuid
        body
        visibleForClient
        red
      }
      errors {
        fullMessages
      }
    }
  }
`;
