import { useCallback } from 'react';

import { MessagesSettingsType } from '../../messagesTypes';

import { useLocalForageState } from '../../../common/hooks/base/reactQuery/useLocalForageState';

import { INITIAL_MESSAGES_SETTING } from '../../messagesConstants';

import { MessageCache } from '../../MessageCache';

function useMessagesSettings() {
  const {
    value: messagesSettings,
    setValue: setMessagesSettings,
    fetched: fetchedMessageSettings
  } = useLocalForageState<MessagesSettingsType>(
    MessageCache.settingsCacheKey(),
    INITIAL_MESSAGES_SETTING
  );

  const resetMessagesSettings = useCallback<() => void>(() => {
    setMessagesSettings(INITIAL_MESSAGES_SETTING);
  }, [setMessagesSettings]);

  return {
    messagesSettings,
    setMessagesSettings,
    resetMessagesSettings,
    fetchedMessageSettings
  };
}

export default useMessagesSettings;
