import { gql } from 'graphql-request';

import { MessageBody, MessageUUID } from '../messagesTypes';

export interface UpdateMessageAiNotRelevantRecordType {
  body: MessageBody;
  uuid: MessageUUID;
}

export const UPDATE_MESSAGE_AI_NOT_RELEVANT_QUERY = gql`
  mutation UpdateMessageAiNotRelevant($uuid: ID!, $aiNotRelevantAt: DateTime) {
    updateMessageAiNotRelevantAt(
      input: { uuid: $uuid, aiNotRelevantAt: $aiNotRelevantAt }
    ) {
      errors {
        fullMessages
      }
      recordUuid
      status
      record {
        uuid
        body
      }
    }
  }
`;
