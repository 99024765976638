import { useCallback } from 'react';

import { ClassName, I18nText } from '../../../../../../../types';
import { IconsEnum } from '../../../../../../../assets/icons/types';
import { MessageOpenInWhiteboardLinkMessage } from './MessageOpenInWhiteboardLink.types';

import { NextPureTooltipIconLinkHelper } from '../../../../../../../helpers/links/NextPureTooltipIconLinkHelper';

import { getWhiteboardPath } from '../../../../../../whiteboards/utils/getWhiteboardPath';

const whiteboardInitialPageIdStateCacheKey =
  'whiteboard-initial-page-id-ls-state';

const whiteboardInitialShapeIdStateCacheKey =
  'whiteboard-initial-shape-id-ls-state';

interface MessageOpenInWhiteboardLinkProps {
  className?: ClassName;
  divClassName?: ClassName;
  i18nText?: I18nText;
  icon?: IconsEnum;
  iconClassName?: ClassName;
  message: MessageOpenInWhiteboardLinkMessage;
  tooltipI18nText?: I18nText;
}

function MessageOpenInWhiteboardLink({
  className = 'text-left flex dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap',
  divClassName,
  i18nText,
  icon,
  iconClassName,
  message,
  tooltipI18nText
}: MessageOpenInWhiteboardLinkProps) {
  const whiteboardPath = getWhiteboardPath(message.whiteboardKeyId);

  const handleGoToWhiteboard = useCallback(() => {
    const pageId = message.whiteboardKeyId?.match(/page:(.*)/)?.[0];
    const shapeId = message.threadKeyId;

    try {
      window.localStorage.setItem(whiteboardInitialPageIdStateCacheKey, pageId);
      window.localStorage.setItem(
        whiteboardInitialShapeIdStateCacheKey,
        shapeId
      );
    } catch (error) {
      console.error(error);
    }
  }, [message.threadKeyId, message.whiteboardKeyId]);

  return (
    <NextPureTooltipIconLinkHelper
      className={className}
      divClassName={divClassName}
      href={whiteboardPath}
      i18nText={i18nText}
      icon={icon}
      iconClassName={iconClassName}
      onClick={handleGoToWhiteboard}
      tooltipI18nText={tooltipI18nText}
    />
  );
}

export default MessageOpenInWhiteboardLink;
