import { gql } from 'graphql-request';

import { MessageUUID } from '../messagesTypes';

export interface DownloadMessageAttachmentsQueryResponse {
  uuid: MessageUUID;
}

export const DOWNLOAD_MESSAGE_ATTACHMENTS_QUERY = gql`
  mutation DownloadMessageAttachments($uuid: ID!, $deviceNanoId: String!) {
    downloadMessageAttachments(
      input: { uuid: $uuid, deviceNanoId: $deviceNanoId }
    ) {
      recordUuid
      status
      record {
        uuid
      }
      errors {
        fullMessages
      }
    }
  }
`;
