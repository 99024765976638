import React, { useEffect, useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';

import { FetchTodoItemsSortTypes } from '../../../todoItemsTypes';
import { IconsEnum } from '../../../../../assets/icons/types';
import { ClassName, I18nText } from '../../../../../types';
import {
  FetchMessagesCacheKey,
  MessageID,
  MessageUUID,
  MessageVisibleForClient
} from '../../../../messages/messagesTypes';

import {
  FETCH_TODO_LIST_TODO_ITEMS,
  FetchTodoListTodoItemsQueryResponse
} from '../../../queries/fetchTodoListTodoItems.query';

import { useUpdateTodoItemsForm } from '../../forms/UpdateTodoItemsForm/hooks/useUpdateTodoItemsForm';
import { usePaginatedTodoItems } from '../../../hooks/usePaginatedTodoItems';

import { UpdateTodoItemsForm } from '../../forms/UpdateTodoItemsForm';

import { FormModalButton } from '../../../../../helpers/buttons/FormModalButton';
import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { LoadingSkeleton } from '../../../../../helpers/LoadingSkeleton';

import { todoItemsKeys, words } from '../../../../../locales/keys';
import { TodoItemsCache } from '../../../TodoItemsCache';

import { sortTodoItemsByOrder } from '../../../utils/sortTodoItemsByOrder';

const UPDATE_TODO_ITEMS_FORM = 'update-todo-items-form';

interface UpdateMessageWithTodoItemsButtonProps {
  messageUuid: MessageUUID;
  messageId: MessageID;
  visibleForClient: MessageVisibleForClient;
  className?: ClassName;
  iconClassName?: ClassName;
  icon?: IconsEnum;
  i18nText?: I18nText;
  i18nTextClassName?: ClassName;
  messagesCacheKey?: FetchMessagesCacheKey;
}

function UpdateMessageWithTodoItemsButton({
  messageUuid,
  messageId,
  visibleForClient,
  className,
  iconClassName,
  icon,
  i18nText,
  i18nTextClassName,
  messagesCacheKey
}: UpdateMessageWithTodoItemsButtonProps) {
  const { todoItems, todoItemsFetched, todoItemsError } =
    usePaginatedTodoItems<FetchTodoListTodoItemsQueryResponse>({
      cacheKey: TodoItemsCache.messageItemsCacheKey(messageUuid),
      query: FETCH_TODO_LIST_TODO_ITEMS,
      initialFilters: { messageUuid },
      initialSort: [FetchTodoItemsSortTypes.ID_ASC],
      initialLimit: 1000,
      options: {
        withoutPrefetch: true
      }
    });

  const defaultTodoItems = useMemo(
    () => sortTodoItemsByOrder(todoItems),
    [todoItems]
  );

  const {
    appendTodoItem,
    control,
    fields,
    fieldsValidationError,
    remove,
    updateTodoItemsErrorMessage,
    updateTodoItemsLoading,
    handleUpdateTodoItems,
    resetForm,
    resetUpdateTodoItems,
    batchAppendTodoItem,
    toggleWithoutCheckbox,
    handleDragItemEnd
  } = useUpdateTodoItemsForm({
    cacheKeys: [
      TodoItemsCache.messageItemsCacheKey(messageUuid),
      messagesCacheKey
    ],
    defaultTodoItems,
    messageId,
    visibleForClient
  });

  useEffect(() => {
    if (todoItemsFetched) {
      resetForm(defaultTodoItems || []);
    }
  }, [resetForm, defaultTodoItems, todoItemsFetched]);

  return (
    <FormModalButton
      className={
        className ||
        'flex text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap'
      }
      form={UPDATE_TODO_ITEMS_FORM}
      i18nTitle={todoItemsKeys.editTodoList}
      i18nText={i18nText}
      i18nTextClassName={i18nTextClassName || 'mr-auto'}
      i18nSubmitText={words.update}
      icon={icon}
      iconClassName={iconClassName}
      onOpen={resetUpdateTodoItems}
      isLoading={updateTodoItemsLoading}
      onSubmit={handleUpdateTodoItems}
      submitDisabled={isEmpty(fields)}
    >
      <LoadingSkeleton loaded={todoItemsFetched} count={4} className="p-4">
        <UpdateTodoItemsForm
          form={UPDATE_TODO_ITEMS_FORM}
          isLoading={updateTodoItemsLoading}
          control={control}
          fields={fields}
          onAddTodoItem={appendTodoItem}
          onRemoveTodoItem={remove}
          onAddTodoItems={batchAppendTodoItem}
          toggleWithoutCheckbox={toggleWithoutCheckbox}
          handleDragItemEnd={handleDragItemEnd}
          fieldsValidationError={fieldsValidationError}
        />
      </LoadingSkeleton>

      <div className="px-4">
        <AlertMessage message={updateTodoItemsErrorMessage || todoItemsError} />
      </div>
    </FormModalButton>
  );
}

export default UpdateMessageWithTodoItemsButton;
