import * as yup from 'yup';
import map from 'lodash/map';
import some from 'lodash/some';
import isEmpty from 'lodash/isEmpty';

import { useReactHookForm } from '../../../../../../common/hooks/base/useReactHookForm';
import { useForwardMessage } from '../../../../../hooks/useForwardMessage';
import { useForwardMessageFormDefaultValue } from '../useForwardMessageFormDefaultValue';

import {
  FetchMessagesCacheKey,
  ForwardMessageFields,
  MessageProjectUUID,
  MessageTaskUUID,
  MessageUUID
} from '../../../../../messagesTypes';

import { ForwardMessageFormData } from '../../ForwardMessageForm.types';

import {
  ForwardMessageQueryResponse,
  FORWARD_MESSAGE_QUERY
} from '../../../../../queries/forwardMessage.query';

import { formsErrors } from '../../../../../../../locales/keys';
import { FORWARD_MESSAGE_DEFAULT_TASK_VALUE } from '../useForwardMessageFormDefaultValue/useForwardMessageFormDefaultValue';

interface ForwardMessageFormOptions {
  messagesCacheKey: FetchMessagesCacheKey;
  projectUuid: MessageProjectUUID;
  taskUuid?: MessageTaskUUID;
  uuid: MessageUUID;
  withAttachments: boolean;
}

const forwardMessageValidationSchema = yup.object({
  [ForwardMessageFields.TASK_UUIDS]: yup.array().min(1, formsErrors.required)
});

function useForwardMessageForm({
  messagesCacheKey,
  projectUuid,
  taskUuid,
  withAttachments,
  uuid
}: ForwardMessageFormOptions) {
  const {
    taskDefaultValue,
    projectDefaultValue,
    includesAttachmentsDefaultValue
  } = useForwardMessageFormDefaultValue({
    projectUuid,
    taskUuid,
    withAttachments: withAttachments
  });

  const defaultForwardMessageFormData: ForwardMessageFormData = {
    body: { text: '', mentionIds: [] },
    red: false,
    visibleForClient: false,
    includesAttachments: includesAttachmentsDefaultValue,
    projectUuid: projectDefaultValue,
    taskUuids: taskDefaultValue
  };

  const {
    control,
    errors,
    handleSubmitReactHookForm,
    register,
    resetForm,
    watch
  } = useReactHookForm<ForwardMessageFormData>({
    defaultValues: defaultForwardMessageFormData,
    isModalForm: true,
    schema: forwardMessageValidationSchema
  });

  const {
    forwardMessageLoading,
    forwardMessageErrorMessage,
    forwardMessage,
    forwardMessageReset
  } = useForwardMessage<ForwardMessageQueryResponse>({
    query: FORWARD_MESSAGE_QUERY,
    cacheKeys: [messagesCacheKey]
  });

  // ignore because of "Type instantiation is excessively deep and possibly infinite." error
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const watchProjectUuid = watch(
    ForwardMessageFields.PROJECT_UUID as keyof ForwardMessageFormData
  )?.['value'] as MessageProjectUUID;

  return {
    control,
    forwardMessageProjectDefaultValue: projectDefaultValue,
    forwardMessageTaskDefaultValue: taskDefaultValue,
    forwardMessageSelectedProjectUuid: watchProjectUuid,
    forwardMessageLoading,
    forwardMessageErrorMessage,
    validationErrors: {
      taskUuidsValidationError: errors?.taskUuids?.['message']
    },
    resetForwardMessageForm: resetForm,
    handleCloseForwardMessage: forwardMessageReset,
    handleSubmitForwardMessage: handleSubmitReactHookForm({
      dirtyFieldsOnly: false,
      onSubmit: async (values: ForwardMessageFormData) => {
        const isSelectedProjectMessages = some(
          values.taskUuids,
          ({ value }) => value === FORWARD_MESSAGE_DEFAULT_TASK_VALUE
        );

        const projectUuid = isSelectedProjectMessages
          ? (values.projectUuid?.value as MessageProjectUUID)
          : undefined;

        const taskUuids = isEmpty(values.taskUuids)
          ? undefined
          : (map(values.taskUuids, 'value') as MessageTaskUUID[]);

        const body = values.body?.text || '';

        const mentionedUserIds = values.body?.mentionIds;

        await forwardMessage({
          ...values,
          body,
          mentionedUserIds,
          projectUuid,
          taskUuids,
          uuid
        });
      }
    }),
    registerFields: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      registerTaskIds: register(ForwardMessageFields.TASK_UUIDS),
      registerProjectId: register(ForwardMessageFields.PROJECT_UUID),
      registerIncludesAttachments: register(
        ForwardMessageFields.INCLUDES_ATTACHMENTS
      ),
      registerVisibleForClient: register(
        ForwardMessageFields.VISIBLE_FOR_CLIENT
      )
    }
  };
}

export default useForwardMessageForm;
