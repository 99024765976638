import { gql } from 'graphql-request';

export const UPDATE_TODO_ITEMS = gql`
  mutation UpdateTodoItems($todoItems: [UpdateTodoItemsInputObject!]!) {
    updateTodoItems(input: { todoItems: $todoItems }) {
      status
      errors {
        fullMessages
      }
    }
  }
`;
