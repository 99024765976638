import { useCallback, useMemo } from 'react';
import find from 'lodash/find';
import map from 'lodash/map';
import fromPairs from 'lodash/fromPairs';
import filter from 'lodash/filter';
import isString from 'lodash/isString';
import sortBy from 'lodash/sortBy';

import { ModelingCategorySlug } from '../../modelingCategoriesTypes';
import {
  ModelingCategoriesSelectCategory,
  ModelingCategoriesSelectSearchTypes
} from './ModelingCategoriesSelect.types';

import { useModelingCategories } from '../../hooks/useModelingCategories';

import { useProductCategoriesAll } from '../../../products/hooks/useProductCategoriesAll';

import {
  MultiSelectChangeCallbackType,
  MultiSelectDataType
} from '../../../../helpers/MultiSelect/types';
import { MultiSelect } from '../../../../helpers/MultiSelect';

import { productsKeys } from '../../../../locales/keys';

interface ModelingCategoriesSelectProps {
  value: ModelingCategoriesSelectCategory | ModelingCategorySlug;
  disabled?: boolean;
  searchType?: ModelingCategoriesSelectSearchTypes;
  onChange: (value: ModelingCategoriesSelectCategory) => void;
}

function ModelingCategoriesSelect({
  value,
  disabled,
  searchType = ModelingCategoriesSelectSearchTypes.DEFAULT,
  onChange
}: ModelingCategoriesSelectProps) {
  const {
    modelingCategories,
    modelingCategoriesLoading,
    modelingCategoriesError
  } = useModelingCategories();

  const {
    productCategories,
    productCategoriesErrorMessage,
    productCategoriesFetched
  } = useProductCategoriesAll();

  const data = useMemo<MultiSelectDataType[]>(() => {
    const modelingCategoriesByExternalId = fromPairs(
      map(modelingCategories, (modelingCategory) => [
        modelingCategory.externalId,
        modelingCategory
      ])
    );

    const supportedProductCategories = filter(
      productCategories,
      (productCategory) => {
        if (searchType === ModelingCategoriesSelectSearchTypes.DEFAULT) {
          return modelingCategoriesByExternalId[productCategory.id]
            ?.isMatchingAllowed;
        }
        if (searchType === ModelingCategoriesSelectSearchTypes.TEXT) {
          return modelingCategoriesByExternalId[productCategory.id]
            ?.isTextSearchAllowed;
        }
        return false;
      }
    );

    return sortBy(
      supportedProductCategories.map((productCategory) => ({
        value: productCategory.id,
        label: productCategory.localizedName
      })),
      'label'
    );
  }, [searchType, productCategories, modelingCategories]);

  const handleChange = useCallback<MultiSelectChangeCallbackType>(
    (value: MultiSelectDataType) => {
      const productCategoryId = value?.value;
      if (!productCategoryId) {
        return onChange(null);
      }

      const productCategory = find(productCategories, [
        'id',
        productCategoryId
      ]);
      const modelingCategory = find(modelingCategories, [
        'externalId',
        productCategoryId
      ]);

      onChange({ ...modelingCategory, productCategory });
    },
    [modelingCategories, productCategories, onChange]
  );

  const multiSelectValue = useMemo<MultiSelectDataType>(() => {
    const productCategoryId = isString(value)
      ? find(modelingCategories, ['slug', value])?.externalId
      : value?.productCategory?.id;

    return find(data, ['value', productCategoryId]);
  }, [value, data, modelingCategories]);

  return (
    <MultiSelect
      error={modelingCategoriesError || productCategoriesErrorMessage}
      inputWrapperClassName="block"
      onChange={handleChange}
      value={multiSelectValue}
      multi={false}
      isSearchable={true}
      isClearable={true}
      optionsLoading={modelingCategoriesLoading && !productCategoriesFetched}
      isLoading={modelingCategoriesLoading && !productCategoriesFetched}
      data={data}
      classNamePrefix="av"
      menuPosition="fixed"
      i18nPlaceholder={productsKeys.category}
      disabled={disabled}
    />
  );
}

export default ModelingCategoriesSelect;
