import { useCallback, useMemo } from 'react';
import { useFieldArray } from 'react-hook-form';
import { OnDragEndResponder } from '@hello-pangea/dnd';
import compact from 'lodash/compact';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import concat from 'lodash/concat';
import find from 'lodash/find';
import reduce from 'lodash/reduce';

import {
  MessageID,
  MessageVisibleForClient
} from '../../../../../../messages/messagesTypes';
import { UpdateTodoItemsFormData } from '../../UpdateTodoItemsForm.types';
import { UpdateItemCacheKeys } from '../../../../../../../types';

import { UPDATE_TODO_ITEMS } from '../../../../../queries/updateTodoItems.query';
import { CREATE_TODO_ITEMS } from '../../../../../queries/createTodoItems.query';
import { DELETE_TODO_ITEM_QUERY } from '../../../../../queries/deleteTodoItem.query';

import { useUpdateTodoItems } from '../../../../../hooks/useUpdateTodoItems';
import { useCreateTodoItems } from '../../../../../hooks/useCreateTodoItems';
import { useDeleteTodoItem } from '../../../../../hooks/useDeleteTodoItem';
import { useReactHookForm } from '../../../../../../common/hooks/base/useReactHookForm';

import {
  UpdateTodoItemsFormItemFieldsData,
  UpdateTodoItemsFormItemFieldsDefaultData
} from '../../components/UpdateTodoItemsFormItemFields';
import {
  TODO_ITEM_NAME_TITLE,
  TODO_ITEM_NAME_WITHOUT_CHECKBOX
} from '../../../../../todoItemsConstants';
import { updateTodoItemsFormValidationSchema } from './useUpdateTodoItemsForm.schema';

const emptyTodoItem: UpdateTodoItemsFormItemFieldsData = {
  name: { text: '' },
  done: false
};

const regex = new RegExp(`^${TODO_ITEM_NAME_WITHOUT_CHECKBOX}`);
const regexTitle = new RegExp(`^\\${TODO_ITEM_NAME_TITLE}`);

interface UpdateTodoItemsFormOptions {
  cacheKeys: UpdateItemCacheKeys;
  defaultTodoItems: UpdateTodoItemsFormItemFieldsDefaultData[];
  messageId: MessageID;
  visibleForClient: MessageVisibleForClient;
}

function useUpdateTodoItemsForm({
  cacheKeys,
  defaultTodoItems,
  messageId,
  visibleForClient
}: UpdateTodoItemsFormOptions) {
  const defaultValues = useMemo<UpdateTodoItemsFormData>(
    () =>
      reduce(
        defaultTodoItems,
        (acc, item) => {
          if (regexTitle.test(item.name)) {
            return {
              ...acc,
              title: item.name.replace(regexTitle, '')
            };
          }

          return {
            ...acc,
            todoItems: [
              ...acc.todoItems,
              {
                id: item.id,
                name: { text: item.name.replace(regex, '') },
                done: item.done,
                order: item.order,
                withoutCheckbox: regex.test(item.name)
              }
            ]
          };
        },
        { todoItems: [], title: '' }
      ),
    [defaultTodoItems]
  );

  const {
    control,
    handleSubmitReactHookForm,
    register,
    resetForm,
    getValues,
    setValue,
    errors
  } = useReactHookForm<UpdateTodoItemsFormData>({
    defaultValues,
    isModalForm: true,
    schema: updateTodoItemsFormValidationSchema
  });

  const { fields, remove, append, move } = useFieldArray<
    UpdateTodoItemsFormData,
    'todoItems'
  >({
    name: 'todoItems',
    control
  });

  const {
    updateTodoItemsLoading,
    updateTodoItemsErrorMessage,
    updateTodoItems,
    updateTodoItemsReset
  } = useUpdateTodoItems({
    query: UPDATE_TODO_ITEMS,
    cacheKeys
  });

  const {
    createTodoItemsLoading,
    createTodoItemsErrorMessage,
    createTodoItems,
    createTodoItemsReset
  } = useCreateTodoItems({
    query: CREATE_TODO_ITEMS,
    cacheKeys
  });

  const {
    deleteTodoItemLoading,
    deleteTodoItemErrorMessage,
    deleteTodoItem,
    deleteTodoItemReset
  } = useDeleteTodoItem({
    cacheKeys,
    query: DELETE_TODO_ITEM_QUERY
  });

  const resetUpdateTodoItems = useCallback<() => void>(() => {
    deleteTodoItemReset();
    updateTodoItemsReset();
    createTodoItemsReset();
    resetForm();
  }, [
    createTodoItemsReset,
    deleteTodoItemReset,
    resetForm,
    updateTodoItemsReset
  ]);

  const toggleWithoutCheckbox = useCallback<(index: number) => void>(
    (index) => {
      const withoutCheckbox = getValues(`todoItems.${index}.withoutCheckbox`);

      setValue(`todoItems.${index}.withoutCheckbox`, !withoutCheckbox);
    },
    [getValues, setValue]
  );

  const appendTodoItem = useCallback<(name?: string) => void>(
    (name) => {
      const lastItemIndexAfterAppend = fields.length || 0;

      append(
        {
          ...emptyTodoItem,
          name: {
            text: name || ''
          }
        },
        { focusName: `todoItems.${lastItemIndexAfterAppend}.name` }
      );
    },
    [append, fields.length]
  );

  const batchAppendTodoItem = useCallback<(names: string[]) => void>(
    (names) => {
      if (names.length > 0) {
        const lastItemIndexAfterAppend =
          (fields.length || 0) + names.length - 1;

        append(
          names.map((name) => ({
            ...emptyTodoItem,
            name: {
              text: name
            }
          })),
          { focusName: `todoItems.${lastItemIndexAfterAppend}.name` }
        );
      }
    },
    [append, fields.length]
  );

  const handleDragItemEnd = useCallback<OnDragEndResponder>(
    (result) => {
      if (!result.source || !result.destination) {
        return;
      }

      move(result.source.index, result.destination.index);
    },
    [move]
  );

  return {
    control,
    resetForm: useCallback<
      (defaultTodoItems: UpdateTodoItemsFormItemFieldsDefaultData[]) => void
    >(
      (defaultTodoItems) =>
        resetForm(
          reduce(
            defaultTodoItems,
            (acc, item) => {
              if (regexTitle.test(item.name)) {
                return {
                  ...acc,
                  title: item.name.replace(regexTitle, '')
                };
              }

              return {
                ...acc,
                todoItems: [
                  ...acc.todoItems,
                  {
                    id: item.id,
                    name: { text: item.name.replace(regex, '') },
                    done: item.done,
                    order: item.order,
                    withoutCheckbox: regex.test(item.name)
                  }
                ]
              };
            },
            { todoItems: [], title: '' }
          )
        ),
      [resetForm]
    ),
    resetUpdateTodoItems,
    updateTodoItemsErrorMessage:
      updateTodoItemsErrorMessage ||
      createTodoItemsErrorMessage ||
      deleteTodoItemErrorMessage,
    updateTodoItemsLoading:
      updateTodoItemsLoading || createTodoItemsLoading || deleteTodoItemLoading,
    handleUpdateTodoItems: handleSubmitReactHookForm({
      onSubmit: async (data) => {
        const { todoItems, title } = data;

        const defaultTitleTodoItem = find(defaultTodoItems, (item) =>
          regexTitle.test(item.name)
        );

        const newTitleTodoItem = defaultTitleTodoItem
          ? {
              ...defaultTitleTodoItem,
              name: TODO_ITEM_NAME_TITLE + title,
              order: 0
            }
          : { name: TODO_ITEM_NAME_TITLE + title, done: true, order: 0 };

        const formTodoItems = map(todoItems, (item, index) => {
          const nameWithoutCheckbox =
            item.withoutCheckbox && item.name?.text
              ? TODO_ITEM_NAME_WITHOUT_CHECKBOX + item.name.text
              : null;

          const defaultName = item.name?.text ? item.name.text : null;

          return {
            ...item,
            name: nameWithoutCheckbox || defaultName,
            order: index + 1
          };
        });

        const todoItemsWithOrder = title
          ? concat(formTodoItems, [newTitleTodoItem])
          : formTodoItems;

        const todoItemsForDelete = filter(
          defaultTodoItems,
          (item) =>
            !todoItemsWithOrder?.some((todoItem) => todoItem?.id === item?.id)
        );

        const todoItemsForUpdate = filter(
          todoItemsWithOrder,
          (item) => !!item?.id
        );

        const todoItemsForCreate = filter(
          todoItemsWithOrder,
          (item) => !item?.id
        );

        const deleteItems = map(todoItemsForDelete, (item) =>
          deleteTodoItem({ uuid: item.id })
        );

        const updateItems = updateTodoItems({
          todoItems: map(todoItemsForUpdate, (item) => ({
            id: item.id,
            todoItem: {
              name: item.name,
              done: item.done,
              order: item.order,
              visibleForClients: visibleForClient
            }
          }))
        });

        const createItems = createTodoItems({
          todoItems: map(todoItemsForCreate, (item) => ({
            name: item.name,
            done: item.done,
            order: item.order,
            visibleForClients: visibleForClient,
            messageId
          }))
        });

        return Promise.all(
          compact([
            ...deleteItems,
            isEmpty(todoItemsForUpdate) ? null : updateItems,
            isEmpty(todoItemsForCreate) ? null : createItems
          ])
        );
      },
      dirtyFieldsOnly: false
    }),
    register,
    fields,
    fieldsValidationError: errors?.todoItems,
    remove,
    appendTodoItem,
    batchAppendTodoItem,
    toggleWithoutCheckbox,
    handleDragItemEnd
  };
}

export default useUpdateTodoItemsForm;
