import { gql } from 'graphql-request';

import { MessageUUID } from '../messagesTypes';

export interface AddToDescriptionMessageRecordType {
  uuid: MessageUUID;
}

export const ADD_TO_DESCRIPTION_MESSAGE_QUERY = gql`
  mutation AddToDescriptionMessage($uuid: ID!) {
    addToDescriptionMessage(input: { uuid: $uuid }) {
      errors {
        fullMessages
      }
      recordUuid
      status
      record {
        uuid
      }
    }
  }
`;
