import React, { memo } from 'react';
import cl from 'classnames';

import { FoldersListItem, FoldersListItemFolder } from '../FoldersListItem';

import { FoldersListFolders } from './FoldersList.types';

import {
  Checkable,
  CheckedHashItem,
  OnSetCheckedIds
} from '../../../../../common/hooks/useTableCheckable';

import { Translate } from '../../../../../helpers/Translate';

import { I18nText } from '../../../../../types';

interface FoldersListProps {
  i18nTitle?: I18nText;
  folders: FoldersListFolders;
  isGridView?: boolean;
  onCheck?: OnSetCheckedIds;
  checkedHash?: CheckedHashItem;
  checkable?: Checkable;
  onItemClick?: (folder: FoldersListItemFolder) => void;
}

function FoldersList({
  i18nTitle,
  folders,
  isGridView,
  onCheck,
  checkedHash,
  checkable,
  onItemClick
}: FoldersListProps) {
  return (
    <div>
      {i18nTitle && (
        <div className="mb-3">
          <Translate id={i18nTitle} />
        </div>
      )}
      <div
        className={cl({
          'grid grid-cols-auto-fill grid-cell-min-56 3xl:grid-cell-min-72 gap-2 pb-4':
            isGridView
        })}
      >
        {folders.map((folder) => (
          <FoldersListItem
            key={folder.id}
            folder={folder}
            isGridView={isGridView}
            onCheck={onCheck}
            checked={checkedHash?.[folder.id]}
            checkable={checkable}
            onClick={onItemClick}
          />
        ))}
      </div>
    </div>
  );
}

export default memo<FoldersListProps>(FoldersList);
