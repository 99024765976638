import {
  MessageUUID,
  DownloadMessageSelectedLifestylesGqlQuery,
  DownloadMessageSelectedLifestylesGqlStatus,
  MessageGqlError,
  MessageDeviceNanoId
} from '../../messagesTypes';

import { useUpdateQuery } from '../../../common/hooks/base/reactQuery/useUpdateQuery';

interface DownloadMessageSelectedLifestylesOptions {
  query: DownloadMessageSelectedLifestylesGqlQuery;
}

export interface DownloadMessageSelectedLifestylesResponse<
  DownloadMessageSelectedLifestylesRecordType
> {
  downloadMessageSelectedLifestyles: {
    status: DownloadMessageSelectedLifestylesGqlStatus;
    recordUuid: MessageUUID;
    record: DownloadMessageSelectedLifestylesRecordType;
    errors: DownloadMessageSelectedLifestylesError;
  };
}

export interface DownloadMessageSelectedLifestylesInput {
  uuid: MessageUUID;
  deviceNanoId: MessageDeviceNanoId;
}

export interface DownloadMessageSelectedLifestylesError {
  fullMessages: MessageGqlError;
}

const action = 'downloadMessageSelectedLifestyles';

function useDownloadMessageSelectedLifestyles<
  DownloadMessageSelectedLifestylesRecordType
>({ query }: DownloadMessageSelectedLifestylesOptions) {
  const {
    updateQueryData,
    updateQuery,
    updateQueryReset,
    updateQueryError,
    updateQueryLoading,
    updateQueryErrorMessage
  } = useUpdateQuery<
    DownloadMessageSelectedLifestylesInput,
    DownloadMessageSelectedLifestylesResponse<DownloadMessageSelectedLifestylesRecordType>,
    DownloadMessageSelectedLifestylesError,
    DownloadMessageSelectedLifestylesRecordType
  >({ action, query });

  return {
    downloadMessageSelectedLifestylesData: updateQueryData,
    downloadMessageSelectedLifestylesError: updateQueryError,
    downloadMessageSelectedLifestylesLoading: updateQueryLoading,
    downloadMessageSelectedLifestylesErrorMessage: updateQueryErrorMessage,
    downloadMessageSelectedLifestyles: updateQuery,
    downloadMessageSelectedLifestylesReset: updateQueryReset
  };
}

export default useDownloadMessageSelectedLifestyles;
