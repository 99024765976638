import React, { useCallback } from 'react';

import { IconsEnum } from '../../../../../assets/icons/types';
import {
  ClassName,
  I18nText,
  IsDisabled,
  FetchItemsCacheKey
} from '../../../../../types';
import { MessageID } from '../../../../messages/messagesTypes';

import { RESEND_MESSAGES_QUERY } from '../../../queries/resendMessages.query';

import { useShowToastOnErrorChange } from '../../../../../common/hooks/useShowToastOnErrorChange';
import { useToastNotification } from '../../../../../common/hooks/useToastNotification';
import { useResendMessages } from '../../../hooks/useResendMessages';

import { PureIconButtonHelper } from '../../../../../helpers/buttons/PureIconButtonHelper';

import { words } from '../../../../../locales/keys';

interface MessageSendEmailNotificationButtonProps {
  messageId: MessageID;
  className?: ClassName;
  icon?: IconsEnum;
  iconClassName?: ClassName;
  i18nText?: I18nText;
  disabled?: IsDisabled;
  cacheKeys?: FetchItemsCacheKey[];
}

function MessageSendEmailNotificationButton({
  messageId,
  disabled,
  className,
  icon,
  iconClassName,
  i18nText,
  cacheKeys
}: MessageSendEmailNotificationButtonProps) {
  const { resendMessages, resendMessagesLoading, resendMessagesErrorMessage } =
    useResendMessages({
      query: RESEND_MESSAGES_QUERY,
      cacheKeys
    });

  useShowToastOnErrorChange({
    error: resendMessagesErrorMessage
  });

  const { showToastI18nNotification } = useToastNotification({
    appearance: 'success',
    i18nMessage: words.success
  });

  const handleMessageSendEmailNotificationButton = useCallback(
    () =>
      resendMessages({ messageIds: [messageId], onlyClients: true }).then(
        () => {
          showToastI18nNotification();
        }
      ),
    [resendMessages, messageId, showToastI18nNotification]
  );

  return (
    <PureIconButtonHelper
      className={className}
      disabled={resendMessagesLoading || disabled}
      icon={icon}
      iconClassName={iconClassName}
      onClick={handleMessageSendEmailNotificationButton}
      i18nText={i18nText}
    />
  );
}

export default MessageSendEmailNotificationButton;
