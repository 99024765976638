import { MessageUUID } from '../../messagesTypes';
import { UpdateItemsCacheKeys } from '../../../../types/updateItemsTypes';

import { useUpdateQuery } from '../../../common/hooks/base/reactQuery/useUpdateQuery';

interface ToggleMessagePinnedOptions {
  cacheKeys: UpdateItemsCacheKeys;
  query: string;
}

interface ToggleMessagePinnedInput {
  uuid: MessageUUID;
}

interface ToggleMessagePinnedError {
  fullMessages: string[] | null;
}

interface ToggleMessagePinnedResponse<ToggleMessagePinnedRecordType> {
  toggleMessagePinned: {
    status: string;
    record: ToggleMessagePinnedRecordType;
    errors: ToggleMessagePinnedError;
  };
}

const action = 'toggleMessagePinned';

function useToggleMessagePinned<ToggleMessagePinnedRecordType>({
  cacheKeys,
  query
}: ToggleMessagePinnedOptions) {
  const {
    updateQueryData,
    updateQuery,
    updateQueryReset,
    updateQueryError,
    updateQueryLoading,
    updateQueryErrorMessage
  } = useUpdateQuery<
    ToggleMessagePinnedInput,
    ToggleMessagePinnedResponse<ToggleMessagePinnedRecordType>,
    ToggleMessagePinnedError,
    ToggleMessagePinnedRecordType
  >({ action, cacheKeys, query });

  return {
    toggleMessagePinnedData: updateQueryData,
    toggleMessagePinnedError: updateQueryError,
    toggleMessagePinnedLoading: updateQueryLoading,
    toggleMessagePinnedErrorMessage: updateQueryErrorMessage,
    toggleMessagePinned: updateQuery,
    toggleMessagePinnedReset: updateQueryReset
  };
}

export default useToggleMessagePinned;
