import React from 'react';
import isEmpty from 'lodash/isEmpty';

import { ItemMessagesListMessageMenuDropdownMessage } from '../../ItemMessagesListMessageMenuDropdown.types';

import { UpdateMessageWithTodoItemsButton } from '../../../../../../../../todoItems/components/modalButtons/UpdateMessageWithTodoItemsButton';

import { todoItemsKeys } from '../../../../../../../../../locales/keys';
import { FetchMessagesCacheKey } from '../../../../../../../messagesTypes';

interface MessageDropdownUpdateTodoListButtonProps {
  message: ItemMessagesListMessageMenuDropdownMessage;
  messagesCacheKey?: FetchMessagesCacheKey;
}

function MessageDropdownUpdateTodoListButton({
  message,
  messagesCacheKey
}: MessageDropdownUpdateTodoListButtonProps) {
  if (isEmpty(message.todoItems)) {
    return null;
  }

  return (
    <UpdateMessageWithTodoItemsButton
      messageUuid={message.uuid}
      messageId={message.id}
      visibleForClient={message.visibleForClient}
      className="text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-3 3xl:px-4 py-1.5 3xl:py-2 w-full flex items-center text-xs 3xl:text-sm whitespace-nowrap"
      i18nText={todoItemsKeys.editTodoList}
      messagesCacheKey={messagesCacheKey}
    />
  );
}

export default MessageDropdownUpdateTodoListButton;
