import React, {
  ReactNode,
  MouseEvent,
  useCallback,
  CSSProperties,
  Fragment,
  useState
} from 'react';

import { Tooltip } from '../../tooltips/Tooltip';

import { TooltipPlacement } from '../../tooltips/tooltipsConstants';

type TooltipButtonHelperOnClick = (e: MouseEvent<HTMLButtonElement>) => void;

interface TooltipButtonHelperProps {
  className?: string;
  disabled?: boolean;
  style?: CSSProperties;
  blurOnMouseLeave?: boolean;
  tooltipPlacement?: TooltipPlacement;
  tooltipI18nText: string;
  onClick?: TooltipButtonHelperOnClick;
  children: ReactNode;
}

function TooltipButtonHelper({
  children,
  className,
  disabled,
  style,
  blurOnMouseLeave,
  tooltipPlacement,
  tooltipI18nText,
  onClick
}: TooltipButtonHelperProps) {
  const [referenceTooltipElement, setReferenceTooltipElement] =
    useState<HTMLButtonElement | null>(null);

  const handleClick = useCallback<TooltipButtonHelperOnClick>(
    (e) => {
      e.preventDefault();
      onClick?.(e);
    },
    [onClick]
  );

  const handleMouseLeave = useCallback(
    (event) => (event.target as HTMLButtonElement)?.blur(),
    []
  );

  return (
    <Fragment>
      <button
        type="button"
        className={className}
        disabled={disabled}
        style={style}
        ref={setReferenceTooltipElement}
        onClick={handleClick}
        onMouseLeave={blurOnMouseLeave ? handleMouseLeave : undefined}
      >
        {children}
      </button>
      <Tooltip
        withArrow
        referenceElement={referenceTooltipElement}
        placement={tooltipPlacement}
        tooltipI18nText={tooltipI18nText}
      />
    </Fragment>
  );
}

export default TooltipButtonHelper;
