import { FetchModelingCategoriesModelingCategoryItemType } from '../../hooks/useModelingCategories';

import { FetchProductCategoriesQueryResponse } from '../../../products/queries/fetchProductCategories.query';

export type ModelingCategoriesSelectCategory =
  FetchModelingCategoriesModelingCategoryItemType & {
    productCategory: FetchProductCategoriesQueryResponse;
  };

export const enum ModelingCategoriesSelectSearchTypes {
  DEFAULT = 'default',
  TEXT = 'text'
}
