import React, { useCallback, useState } from 'react';
import { ColorChangeHandler, SketchPicker } from 'react-color';

import includes from 'lodash/includes';
import uniq from 'lodash/uniq';
import without from 'lodash/without';

import { HexColor } from '../../types';

import { IconsEnum } from '../../assets/icons/types';

import { SimpleModalButton } from '../buttons/SimpleModalButton';

import { ColorsModalButtonCommonProps } from './ColorsModalButton.types';

import { ColorButtons } from '../colors/ColorButtons';
import { PureButtonHelper } from '../buttons/PureButtonHelper';

import { words } from '../../locales/keys';

interface ColorsModalButtonProps {
  value: string[];
  disabled?: boolean;
  onChange: (value: string[]) => void;
}

const initialState = [];

function ColorsModalButton({
  value,
  disabled,
  onChange,
  className,
  i18nTitle,
  icon,
  iconClassName,
  buttonText,
  modalIcon,
  i18nSubmitText,
  tooltipPlacement,
  tooltipI18nText,
  withoutInitialFocus
}: ColorsModalButtonProps & ColorsModalButtonCommonProps) {
  const [color, setColor] = useState<HexColor>('#000000' as HexColor);
  const [selectedColors, setSelectedColors] = useState<HexColor[]>(
    value || initialState
  );
  const [selectedColorsBeforeOpen, setSelectedColorsBeforeOpen] = useState<
    HexColor[]
  >(value || initialState);

  const handleOpen = useCallback<() => void>(
    () => setSelectedColorsBeforeOpen(selectedColors),
    [selectedColors, setSelectedColorsBeforeOpen]
  );

  const handleCancel = useCallback<() => void>(
    () => setSelectedColors(selectedColorsBeforeOpen),
    [selectedColorsBeforeOpen, setSelectedColors]
  );

  const handleSubmit = useCallback<() => Promise<void>>(async () => {
    setSelectedColorsBeforeOpen(selectedColors);
    onChange(selectedColors);
  }, [selectedColors, onChange]);

  const handleChange = useCallback<ColorChangeHandler>(
    (color) => setColor(color.hex as HexColor),
    [setColor]
  );

  const handleSelect = useCallback<() => void>(
    () =>
      setSelectedColors((prevState) => uniq<HexColor>([...prevState, color])),
    [color, setSelectedColors]
  );

  const handleRemove = useCallback<(removeColor: HexColor) => void>(
    (removeColor: HexColor) =>
      setSelectedColors((prevState) =>
        without<HexColor>(prevState, removeColor)
      ),
    [setSelectedColors]
  );

  return (
    <SimpleModalButton
      className={className}
      i18nTitle={i18nTitle}
      icon={icon}
      iconClassName={iconClassName}
      modalIcon={modalIcon}
      buttonText={buttonText}
      i18nSubmitText={i18nSubmitText}
      isLoading={disabled}
      tooltipPlacement={tooltipPlacement}
      tooltipI18nText={tooltipI18nText}
      onOpen={handleOpen}
      onCancel={handleCancel}
      onSubmit={handleSubmit}
      childrenClassName="p-4 flex-1 overflow-y-auto"
      withoutInitialFocus={withoutInitialFocus}
    >
      <SketchPicker color={color} onChange={handleChange} disableAlpha />

      <div className="mt-5">
        <PureButtonHelper
          className="mt-5 flex-1 sm:flex-initial rounded-md border px-4 py-2 border-transparent text-sm font-medium text-white shadow-sm hover:shadow-md bg-blue-600 hover:bg-blue-700  "
          disabled={includes(selectedColors, color)}
          i18nText={words.select}
          onClick={handleSelect}
        />
        <div className="mt-5">
          <ColorButtons
            icon={IconsEnum.X}
            colors={selectedColors}
            onClick={handleRemove}
          />
        </div>
      </div>
    </SimpleModalButton>
  );
}

export default ColorsModalButton;
