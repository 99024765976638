import { useCurrentUser } from '../../../../auth/hooks/useAuth';

import { ModelLightboxTitleContent } from './components/ModelLightboxTitleContent';

import { LifestylesPermissions } from '../../../lifestyles/lifestylesConstants';

interface AttachmentWithProduct {
  product: {
    name: string;
    nda: boolean;
    brand: {
      localizedName: string;
    };
  };
  lifestyle?: never;
  material?: never;
}

interface AttachmentWithLifestyle {
  product?: never;
  lifestyle: {
    name: string;
    nda: boolean;
    category: {
      localizedName: string;
    };
    author?: {
      localizedName: string;
    };
  };
  material?: never;
}

interface AttachmentWithMaterial {
  product?: never;
  lifestyle?: never;
  material: {
    name: string;
    nda: boolean;
  };
}

export type ModelLightboxTitleAttachment =
  | AttachmentWithProduct
  | AttachmentWithLifestyle
  | AttachmentWithMaterial;

interface ModelLightboxTitleProps {
  attachment: ModelLightboxTitleAttachment;
}

function ModelLightboxTitle({ attachment }: ModelLightboxTitleProps) {
  const currentUser = useCurrentUser();

  if (attachment.product) {
    return (
      <ModelLightboxTitleContent
        name={attachment.product.name}
        nda={attachment.product.nda}
        brandName={attachment.product.brand?.localizedName}
      />
    );
  }

  if (attachment.lifestyle) {
    return (
      <ModelLightboxTitleContent
        name={attachment.lifestyle.name}
        nda={attachment.lifestyle.nda}
        categoryName={attachment.lifestyle.category?.localizedName}
        author={
          currentUser.hasPermissions(
            LifestylesPermissions.READ_LIFESTYLES_LIGHT_BOX_TITLE_AUTHOR
          ) && attachment.lifestyle.author
        }
      />
    );
  }

  if (attachment.material) {
    return (
      <ModelLightboxTitleContent
        name={attachment.material.name}
        nda={attachment.material.nda}
      />
    );
  }
}

export default ModelLightboxTitle;
