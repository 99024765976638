import React, { useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';

import { ItemMessagesListMessageMenuDropdownMessage } from '../../ItemMessagesListMessageMenuDropdown.types';

import { CreateMessageWithTodoItemsButton } from '../../../../../../../../todoItems/components/modalButtons/CreateMessageWithTodoItemsButton';

import { todoItemsKeys } from '../../../../../../../../../locales/keys';

interface MessageDropdownCreateTodoListButtonProps {
  message: ItemMessagesListMessageMenuDropdownMessage;
}

function MessageDropdownCreateTodoListButton({
  message
}: MessageDropdownCreateTodoListButtonProps) {
  const body = useMemo<string>(
    () => message.body || message.forwardedMessage?.body,
    [message.body, message.forwardedMessage?.body]
  );

  if (!isEmpty(message.todoItems) || !body) {
    return null;
  }

  return message.project?.nanoId ? (
    <CreateMessageWithTodoItemsButton
      className="text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-3 3xl:px-4 py-1.5 3xl:py-2 w-full flex items-center text-xs 3xl:text-sm whitespace-nowrap"
      i18nText={todoItemsKeys.createTodoList}
      visibleForClient={message.visibleForClient}
      defaultValues={body.split(/(?:\r?\n)+/)}
      projectId={message.projectId}
      projectNanoId={message.project.nanoId}
    />
  ) : (
    <CreateMessageWithTodoItemsButton
      className="text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-3 3xl:px-4 py-1.5 3xl:py-2 w-full flex items-center text-xs 3xl:text-sm whitespace-nowrap"
      i18nText={todoItemsKeys.createTodoList}
      visibleForClient={message.visibleForClient}
      defaultValues={body.split(/(?:\r?\n)+/)}
      taskId={message.taskId}
      taskNanoId={message.task?.nanoId}
    />
  );
}

export default MessageDropdownCreateTodoListButton;
