import React, { useCallback, useState } from 'react';
import Twemoji from 'react-twemoji';

import { I18nText } from '../../../types';

import { Tooltip } from '../../tooltips/Tooltip';
import { TooltipSingletonTarget } from '../../tooltips/TooltipSingletonTarget';

import { TooltipPlacement } from '../../tooltips/tooltipsConstants';

type PureTooltipIconButtonHelperOnClick = (
  e: React.MouseEvent<HTMLButtonElement>
) => void;

export interface PureTooltipIconButtonHelperDefaultProps {
  className?: string;
  disabled?: boolean;
  emojiText: string;
  id?: string;
  onClick?: PureTooltipIconButtonHelperOnClick;
  onMouseEnter?: () => void;
  style?: React.CSSProperties;
  tooltipPlacement?: TooltipPlacement;
  tooltipSingleton?: boolean;
}

interface PureTooltipIconButtonHelperWithTooltipI18nTextProps {
  tooltipI18nText: I18nText;
  tooltipText?: never;
}

interface PureTooltipIconButtonHelperWithTooltipTextProps {
  tooltipI18nText?: never;
  tooltipText: string;
}

type PureTooltipIconButtonHelperProps =
  PureTooltipIconButtonHelperDefaultProps &
    (
      | PureTooltipIconButtonHelperWithTooltipI18nTextProps
      | PureTooltipIconButtonHelperWithTooltipTextProps
    );

const twemojiOptions = {
  className: 'twemoji',
  base: 'https://cdn.jsdelivr.net/gh/jdecked/twemoji@latest/assets/'
};

function PureTooltipTwemojiButtonHelper({
  id,
  className,
  style,
  disabled,
  tooltipI18nText,
  tooltipText,
  tooltipPlacement,
  tooltipSingleton,
  onClick,
  onMouseEnter,
  emojiText
}: PureTooltipIconButtonHelperProps) {
  const [referenceTooltipElement, setReferenceTooltipElement] =
    useState<HTMLButtonElement | null>(null);

  const handleClick = useCallback<PureTooltipIconButtonHelperOnClick>(
    (e) => {
      e.preventDefault();
      onClick?.(e);
    },
    [onClick]
  );

  const tooltipView = tooltipI18nText ? (
    <Tooltip
      withArrow
      referenceElement={referenceTooltipElement}
      tooltipI18nText={tooltipI18nText}
      placement={tooltipPlacement}
    />
  ) : (
    <Tooltip
      withArrow
      referenceElement={referenceTooltipElement}
      tooltipText={tooltipText as string}
      placement={tooltipPlacement}
    />
  );

  const tooltipSingletonView = tooltipI18nText ? (
    <TooltipSingletonTarget
      referenceElement={referenceTooltipElement}
      tooltipI18nText={tooltipI18nText}
    />
  ) : (
    <TooltipSingletonTarget
      referenceElement={referenceTooltipElement}
      tooltipText={tooltipText as string}
    />
  );

  return (
    <button
      id={id}
      className={className}
      style={style}
      disabled={disabled}
      onClick={handleClick}
      onMouseEnter={onMouseEnter}
      ref={setReferenceTooltipElement}
      role="menuItem"
      type="button"
    >
      {emojiText ? (
        <Twemoji options={twemojiOptions}>{emojiText}</Twemoji>
      ) : null}

      {tooltipSingleton ? tooltipSingletonView : tooltipView}
    </button>
  );
}

export default PureTooltipTwemojiButtonHelper;
