import React from 'react';

import { CopyColorButton } from '../CopyColorButton';
import { CustomColorButton } from '../CustomColorButton';

import { ColorButtonCommonProps } from './ColorButton.types';

type ColorButtonProps = ColorButtonCommonProps;

function ColorButton({
  icon,
  color,
  isVisibleForClient,
  onClick
}: ColorButtonProps) {
  if (onClick) {
    return <CustomColorButton icon={icon} color={color} onClick={onClick} />;
  }

  return (
    <CopyColorButton color={color} isVisibleForClient={isVisibleForClient} />
  );
}

export default ColorButton;
