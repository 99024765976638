import { useCallback } from 'react';

import { ClassName, I18nText, IsDisabled } from '../../../../../types';
import { MessageID } from '../../../messagesTypes';

import { PureTooltipIconButtonHelper } from '../../../../../helpers/buttons/PureTooltipIconButtonHelper';

import { IconsEnum } from '../../../../../assets/icons/types';

interface ReplyMessageButtonProps {
  messageId: MessageID;
  onReplyMessage: (messageId: MessageID) => void;
  icon?: IconsEnum;
  iconClassName?: ClassName;
  i18nText?: I18nText;
  disabled?: IsDisabled;
  className?: ClassName;
  tooltipI18nText?: I18nText;
}

function ReplyMessageButton({
  messageId,
  onReplyMessage,
  i18nText,
  icon,
  iconClassName,
  className,
  disabled,
  tooltipI18nText
}: ReplyMessageButtonProps) {
  const handleReplyMessage = useCallback<() => void>(() => {
    onReplyMessage(messageId);
  }, [messageId, onReplyMessage]);

  return (
    <PureTooltipIconButtonHelper
      className={
        className ||
        'py-0.5 pl-0.5 pr-0.5 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base text-gray-500 dark:text-gray-400 hover:text-gray-800 dark:hover:text-gray-100 focus:ring-offset-0'
      }
      disabled={disabled}
      i18nText={i18nText}
      onClick={handleReplyMessage}
      icon={icon}
      iconClassName={iconClassName || 'h-5 w-5'}
      tooltipI18nText={tooltipI18nText}
    />
  );
}

export default ReplyMessageButton;
