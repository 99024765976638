import {
  MessageGqlError,
  ForwardMessageGqlQuery,
  ForwardMessageGqlStatus,
  MessageUUID,
  MessageBody,
  MessageVisibleForClient,
  ForwardMessageIndexCacheKey,
  MessageProjectUUID,
  MessageTaskUUID,
  MessageMentionedUserIDs
} from '../../messagesTypes';

import { useUpdateQuery } from '../../../common/hooks/base/reactQuery/useUpdateQuery';

interface ForwardMessageOptions {
  query: ForwardMessageGqlQuery;
  cacheKeys: ForwardMessageIndexCacheKey[];
}

export interface ForwardMessageResponse<ForwardMessageRecordType> {
  forwardMessage: {
    status: ForwardMessageGqlStatus;
    errors: ForwardMessageError;
    record: ForwardMessageRecordType;
  };
}

export interface ForwardMessageInput {
  uuid: MessageUUID;
  body: MessageBody;
  includesAttachments?: boolean;
  visibleForClient?: MessageVisibleForClient;
  projectUuid?: MessageProjectUUID;
  taskUuids: MessageTaskUUID[];
  mentionedUserIds?: MessageMentionedUserIDs;
}

export interface ForwardMessageError {
  fullMessages: MessageGqlError;
}

const action = 'forwardMessage';

function useForwardMessage<ForwardMessageRecordType>({
  query,
  cacheKeys
}: ForwardMessageOptions) {
  const {
    updateQueryData,
    updateQuery,
    updateQueryReset,
    updateQueryError,
    updateQueryLoading,
    updateQueryErrorMessage
  } = useUpdateQuery<
    ForwardMessageInput,
    ForwardMessageResponse<ForwardMessageRecordType>,
    ForwardMessageError,
    undefined
  >({ action, query, cacheKeys });

  return {
    forwardMessageData: updateQueryData,
    forwardMessageError: updateQueryError,
    forwardMessageLoading: updateQueryLoading,
    forwardMessageErrorMessage: updateQueryErrorMessage,
    forwardMessage: updateQuery,
    forwardMessageReset: updateQueryReset
  };
}

export default useForwardMessage;
