import React, { useCallback } from 'react';

import { MessageDropdownToggleAiNotRelevantButtonMessage } from './MessageDropdownToggleAiNotRelevantButton.types';
import { FetchMessagesCacheKey } from '../../../../../../../messagesTypes';

import { UPDATE_MESSAGE_AI_NOT_RELEVANT_QUERY } from '../../../../../../../queries/updateMessageAiNotRelevant.query';

import { useUpdateMessageAiNotRelevant } from '../../../../../../../hooks/useUpdateMessageAiNotRelevant';
import { useShowToastOnErrorChange } from '../../../../../../../../../common/hooks/useShowToastOnErrorChange';

import { PureButtonHelper } from '../../../../../../../../../helpers/buttons/PureButtonHelper';

import { messagesKeys } from '../../../../../../../../../locales/keys';

interface MessageDropdownToggleAiNotRelevantButtonProps {
  message: MessageDropdownToggleAiNotRelevantButtonMessage;
  messagesCacheKey: FetchMessagesCacheKey;
}

function MessageDropdownToggleAiNotRelevantButton({
  message,
  messagesCacheKey
}: MessageDropdownToggleAiNotRelevantButtonProps) {
  const {
    updateMessageAiNotRelevant,
    updateMessageAiNotRelevantLoading,
    updateMessageAiNotRelevantErrorMessage
  } = useUpdateMessageAiNotRelevant({
    cacheKeys: [messagesCacheKey],
    query: UPDATE_MESSAGE_AI_NOT_RELEVANT_QUERY
  });

  useShowToastOnErrorChange({
    error: updateMessageAiNotRelevantErrorMessage
  });

  const handleToggleMessageAiNotRelevant = useCallback<() => void>(
    () =>
      updateMessageAiNotRelevant({
        uuid: message.uuid,
        aiNotRelevantAt: message.aiNotRelevantAt
          ? null
          : new Date().toISOString()
      }),
    [message.aiNotRelevantAt, message.uuid, updateMessageAiNotRelevant]
  );

  return (
    <PureButtonHelper
      className="text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-3 3xl:px-4 py-1.5 3xl:py-2 w-full flex items-center text-xs 3xl:text-sm whitespace-nowrap"
      disabled={updateMessageAiNotRelevantLoading}
      i18nText={
        message.aiNotRelevantAt
          ? messagesKeys.markAsRelevant
          : messagesKeys.markAsUnrelevant
      }
      onClick={handleToggleMessageAiNotRelevant}
    />
  );
}

export default MessageDropdownToggleAiNotRelevantButton;
