import React, { useCallback } from 'react';

import { PureButtonHelper } from '../../../../../../../../../helpers/buttons/PureButtonHelper';

import { useDownloadNanoId } from '../../../../../../../../downloads/hooks/useDownloadNanoId';
import { useDownloadMessageSelectedMaterials } from '../../../../../../../hooks/useDownloadMessageSelectedMaterials';

import {
  DownloadMessageSelectedMaterialsQueryResponse,
  DOWNLOAD_MESSAGE_SELECTED_MATERIALS_QUERY
} from '../../../../../../../queries/downloadMessageSelectedMaterials.query';

import { selectedMaterialsKeys } from '../../../../../../../../../locales/keys';

import { ItemMessagesListMessageMenuDropdownMessage } from '../../ItemMessagesListMessageMenuDropdown.types';

interface MessageDropdownDownloadSelectedMaterialsProps {
  message: ItemMessagesListMessageMenuDropdownMessage;
}

function MessageDropdownDownloadSelectedMaterials({
  message
}: MessageDropdownDownloadSelectedMaterialsProps) {
  const { downloadNanoId } = useDownloadNanoId();

  const {
    downloadMessageSelectedMaterials,
    downloadMessageSelectedMaterialsLoading
  } = useDownloadMessageSelectedMaterials<DownloadMessageSelectedMaterialsQueryResponse>(
    {
      query: DOWNLOAD_MESSAGE_SELECTED_MATERIALS_QUERY
    }
  );

  const handleDownloadTaskResults = useCallback<() => void>(
    () =>
      downloadMessageSelectedMaterials({
        uuid: message.uuid,
        deviceNanoId: downloadNanoId
      }),
    [message, downloadNanoId, downloadMessageSelectedMaterials]
  );

  return (
    <PureButtonHelper
      className="text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-3 3xl:px-4 py-1.5 3xl:py-2 w-full flex items-center text-xs 3xl:text-sm whitespace-nowrap"
      disabled={downloadMessageSelectedMaterialsLoading}
      i18nText={selectedMaterialsKeys.download}
      onClick={handleDownloadTaskResults}
    />
  );
}

export default MessageDropdownDownloadSelectedMaterials;
