import React from 'react';
import isEmpty from 'lodash/isEmpty';

import { ProjectNanoID } from '../../../../projects/projectsTypes';
import { CheckedAttachmentItems } from '../AttachmentsListItem';

import { useAttachmentsModalAttachmentsList } from './hooks/useAttachmentsModalAttachmentsList';

import { AttachmentsModalFilesList } from './components/AttachmentsModalFilesList';
import { AttachmentsModalFoldersList } from './components/AttachmentsModalFoldersList';

interface AttachmentsModalAttachmentsListProps {
  projectNanoId: ProjectNanoID;
  checkedIds?: CheckedAttachmentItems;
  onChange?: (items: CheckedAttachmentItems) => void;
}

function AttachmentsModalAttachmentsList({
  projectNanoId,
  checkedIds,
  onChange
}: AttachmentsModalAttachmentsListProps) {
  const {
    breadCrumbsHistory,
    currentBreadCrumb,
    currentTaskNanoId,
    handleSetCurrentFolder
  } = useAttachmentsModalAttachmentsList();

  return (
    <div className="pt-6 pb-8">
      <div className="sm:px-2">
        <div className="p-2 space-y-2">
          {!isEmpty(breadCrumbsHistory) && currentBreadCrumb.i18nText ? (
            <AttachmentsModalFilesList
              projectNanoId={projectNanoId}
              taskNanoId={currentTaskNanoId}
              onChange={onChange}
              checkedIds={checkedIds}
              changeCurrentFolder={handleSetCurrentFolder}
              breadCrumbsHistory={breadCrumbsHistory}
              currentBreadCrumb={currentBreadCrumb}
            />
          ) : (
            <AttachmentsModalFoldersList
              projectNanoId={projectNanoId}
              taskNanoId={currentTaskNanoId}
              changeCurrentFolder={handleSetCurrentFolder}
              breadCrumbsHistory={breadCrumbsHistory}
              currentBreadCrumb={currentBreadCrumb}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default AttachmentsModalAttachmentsList;
