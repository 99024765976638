import { gql } from 'graphql-request';

export const UNCHECK_TODO_ITEMS_QUERY = gql`
  mutation UncheckTodoItems($ids: [ID!]!) {
    uncheckTodoItems(input: { ids: $ids }) {
      status
      errors {
        fullMessages
      }
    }
  }
`;
