import {
  MessageUUID,
  MessageID,
  MessagesResendOnlyClients,
  FetchMessagesCacheKey
} from '../../messagesTypes';

import { useUpdateQuery } from '../../../common/hooks/base/reactQuery/useUpdateQuery';

interface ResendMessagesOptions {
  cacheKeys: FetchMessagesCacheKey[];
  query: string;
}

interface ResendMessagesInput {
  messageIds: (MessageUUID | MessageID)[];
  onlyClients?: MessagesResendOnlyClients;
}

interface ResendMessagesError {
  fullMessages: string[] | null;
}

interface ResendMessagesResponse<ResendMessagesRecordType> {
  resendMessages: {
    status: string;
    record: ResendMessagesRecordType;
    errors: ResendMessagesError;
  };
}

const action = 'resendMessages';

function useResendMessages<ResendMessagesRecordType>({
  cacheKeys,
  query
}: ResendMessagesOptions) {
  const {
    updateQueryData,
    updateQuery,
    updateQueryReset,
    updateQueryError,
    updateQueryLoading,
    updateQueryErrorMessage
  } = useUpdateQuery<
    ResendMessagesInput,
    ResendMessagesResponse<ResendMessagesRecordType>,
    ResendMessagesError,
    ResendMessagesRecordType
  >({ action, cacheKeys, query });

  return {
    resendMessagesData: updateQueryData,
    resendMessagesError: updateQueryError,
    resendMessagesLoading: updateQueryLoading,
    resendMessagesErrorMessage: updateQueryErrorMessage,
    resendMessages: updateQuery,
    resendMessagesReset: updateQueryReset
  };
}

export default useResendMessages;
