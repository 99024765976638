import {
  FetchTeamsUsersFilters,
  FetchTeamsUsersSort,
  FetchTeamsUsersPage,
  FetchTeamsUsersLimit
} from '../../teamsUsersTypes';

import { useFinIndexQuery } from '../../../common/hooks/base/reactQuery/useFinIndexQuery';

import {
  INITIAL_TEAMS_USERS_FILTERS,
  INITIAL_TEAMS_USERS_LIMIT,
  INITIAL_TEAMS_USERS_PAGE,
  INITIAL_TEAMS_USERS_SORT
} from '../../teamsUsersConstants';

interface PaginatedTeamsUsersOptions {
  cacheKey: string;
  query: string;
  initialFilters?: FetchTeamsUsersFilters;
  initialSort?: FetchTeamsUsersSort;
  initialPage?: FetchTeamsUsersPage;
  initialLimit?: FetchTeamsUsersLimit;
  options?: {
    cacheTime?: number;
    enabled?: boolean;
  };
}

const scope = 'teamsUsers';

function usePaginatedTeamsUsers<ItemType>({
  cacheKey,
  query,
  initialFilters = INITIAL_TEAMS_USERS_FILTERS,
  initialSort = INITIAL_TEAMS_USERS_SORT,
  initialPage = INITIAL_TEAMS_USERS_PAGE,
  initialLimit = INITIAL_TEAMS_USERS_LIMIT,
  options = {}
}: PaginatedTeamsUsersOptions) {
  const {
    data,
    items,
    itemsError,
    itemsTotalCount,
    isFetched,
    isLoading,
    isPlaceholderData,
    changeItemsFilters,
    currentPage,
    currentLimit,
    currentFilters,
    currentSort,
    clearItemsFilters,
    filterItems,
    prefetchItems,
    sortItems,
    paginateItems,
    limitItems,
    updateItemCache
  } = useFinIndexQuery<ItemType>({
    cacheKey,
    initialFilters,
    initialLimit,
    initialPage,
    initialSort,
    options,
    query,
    scope
  });

  return {
    teamsUsersData: data,
    teamsUsers: items,
    teamsUsersError: itemsError,
    teamsUsersTotalCount: itemsTotalCount,
    teamsUsersFetched: isFetched,
    teamsUsersLoading: isLoading,
    teamsUsersIsPlaceholderData: isPlaceholderData,
    teamsUsersFilters: currentFilters,
    teamsUsersSort: currentSort,
    teamsUsersPage: currentPage,
    teamsUsersLimit: currentLimit,
    filterTeamsUsers: filterItems,
    clearTeamsUsersFilters: clearItemsFilters,
    sortTeamsUsers: sortItems,
    paginateTeamsUsers: paginateItems,
    limitTeamsUsers: limitItems,
    prefetchTeamsUsers: prefetchItems,
    updateTeamsUsersCache: updateItemCache,
    changeTeamsUsersFilters: changeItemsFilters
  };
}

export default usePaginatedTeamsUsers;
