import { gql } from 'graphql-request';

import { MessageFavorite } from '../messagesTypes';

export interface ToggleMessageFavoriteRecordType {
  favorite: MessageFavorite;
}

export const TOGGLE_MESSAGE_FAVORITE_QUERY = gql`
  mutation ToggleMessageFavorite($uuid: ID!) {
    toggleMessageFavorite(input: { uuid: $uuid }) {
      errors {
        fullMessages
      }
      recordUuid
      status
      record {
        favorite
      }
    }
  }
`;
