import { ClassName, I18nText, IsDisabled } from '../../../../../types';
import { FetchMessagesCacheKey, MessageUUID } from '../../../messagesTypes';

import {
  TOGGLE_MESSAGE_VISIBLE_FOR_CLIENT_QUERY,
  ToggleMessageVisibleForClientRecordType
} from '../../../queries/toggleMessageVisibleForClient.query';

import { useToggleMessageVisibleForClient } from '../../../hooks/useToggleMessageVisibleForClient';

import { PureTooltipIconButtonHelper } from '../../../../../helpers/buttons/PureTooltipIconButtonHelper';

import { IconsEnum } from '../../../../../assets/icons/types';

interface MessageVisibleForClientButtonProps {
  uuid: MessageUUID;
  cacheKey?: FetchMessagesCacheKey;
  icon?: IconsEnum;
  iconClassName?: ClassName;
  i18nText?: I18nText;
  disabled?: IsDisabled;
  className?: ClassName;
  tooltipI18nText?: I18nText;
}

function MessageVisibleForClientButton({
  uuid,
  cacheKey,
  i18nText,
  icon,
  iconClassName,
  className,
  disabled,
  tooltipI18nText
}: MessageVisibleForClientButtonProps) {
  const {
    toggleMessageVisibleForClient,
    toggleMessageVisibleForClientLoading
  } = useToggleMessageVisibleForClient<ToggleMessageVisibleForClientRecordType>(
    {
      indexCacheKey: cacheKey,
      query: TOGGLE_MESSAGE_VISIBLE_FOR_CLIENT_QUERY
    }
  );

  const handleToggleMessageVisibleForClient = () =>
    toggleMessageVisibleForClient({ uuid });

  return (
    <PureTooltipIconButtonHelper
      className={
        className ||
        'text-left flex dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap'
      }
      disabled={disabled || toggleMessageVisibleForClientLoading}
      i18nText={i18nText}
      onClick={handleToggleMessageVisibleForClient}
      icon={icon}
      iconClassName={iconClassName || 'h-5 w-5'}
      tooltipI18nText={tooltipI18nText}
    />
  );
}

export default MessageVisibleForClientButton;
