import React, { useCallback } from 'react';

import { ItemMessagesListMessageMenuDropdownMessage } from '../../ItemMessagesListMessageMenuDropdown.types';

import { PureButtonHelper } from '../../../../../../../../../helpers/buttons/PureButtonHelper';

import { copyToClipboard } from '../../../../../../../../../utils/copyToClipboard';

import { words } from '../../../../../../../../../locales/keys';

interface MessageDropdownCopyUuidButtonProps {
  message: ItemMessagesListMessageMenuDropdownMessage;
}

function MessageDropdownCopyUuidButton({
  message
}: MessageDropdownCopyUuidButtonProps) {
  const handleCopy = useCallback<() => void>(
    () => copyToClipboard(message.uuid),
    [message]
  );

  return (
    <PureButtonHelper
      className="text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-3 3xl:px-4 py-1.5 3xl:py-2 w-full flex items-center text-xs 3xl:text-sm whitespace-nowrap"
      i18nText={words.copyUuid}
      onClick={handleCopy}
    />
  );
}

export default MessageDropdownCopyUuidButton;
