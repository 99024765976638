import React, { useCallback } from 'react';

import { FoldersListItemGridView } from './components/FoldersListItemGridView';
import { FoldersListItemListView } from './components/FoldersListItemListView';

import { FoldersListItemFolder } from './FoldersListItem.types';

import {
  Checkable,
  OnSetCheckedIds
} from '../../../../../common/hooks/useTableCheckable';

interface FoldersListItemProps {
  folder: FoldersListItemFolder;
  isGridView: boolean;
  onCheck: OnSetCheckedIds;
  checkable?: Checkable;
  checked: boolean;
  onClick?: (folder: FoldersListItemFolder) => void;
}

function FoldersListItem({
  folder,
  isGridView,
  onCheck,
  checkable,
  checked,
  onClick
}: FoldersListItemProps) {
  const handleCheck = useCallback(
    () => onCheck?.(folder.id),
    [folder, onCheck]
  );

  return isGridView ? (
    <FoldersListItemGridView
      folder={folder}
      handleCheck={handleCheck}
      checkboxChecked={checked}
      checkable={checkable}
    />
  ) : (
    <FoldersListItemListView
      folder={folder}
      handleCheck={handleCheck}
      checkboxChecked={checked}
      checkable={checkable}
      onClick={onClick}
    />
  );
}

export default FoldersListItem;
