import React, { useCallback } from 'react';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';

import { ItemMessagesListMessageMenuDropdownMessage } from '../../ItemMessagesListMessageMenuDropdown.types';

import { UNCHECK_TODO_ITEMS_QUERY } from '../../../../../../../../todoItems/queries/uncheckTodoItems.query';

import { useUncheckTodoItems } from '../../../../../../../../todoItems/hooks/useUncheckTodoItems';
import { useShowToastOnErrorChange } from '../../../../../../../../../common/hooks/useShowToastOnErrorChange';

import { PureButtonHelper } from '../../../../../../../../../helpers/buttons/PureButtonHelper';

import { TodoItemsCache } from '../../../../../../../../todoItems/TodoItemsCache';

import { todoItemsKeys } from '../../../../../../../../../locales/keys';

interface MessageDropdownUncheckTodoItemsButtonProps {
  message: ItemMessagesListMessageMenuDropdownMessage;
}

function MessageDropdownUncheckTodoItemsButton({
  message
}: MessageDropdownUncheckTodoItemsButtonProps) {
  const {
    uncheckTodoItems,
    uncheckTodoItemsLoading,
    uncheckTodoItemsErrorMessage
  } = useUncheckTodoItems({
    query: UNCHECK_TODO_ITEMS_QUERY,
    indexCacheKey: TodoItemsCache.messageItemsCacheKey(message.uuid)
  });

  useShowToastOnErrorChange({ error: uncheckTodoItemsErrorMessage });

  const handleUncheckTodoItems = useCallback(() => {
    uncheckTodoItems({
      ids: map(message.todoItems, (todoItem) => todoItem.id)
    });
  }, [uncheckTodoItems, message.todoItems]);

  if (isEmpty(message.todoItems)) {
    return null;
  }

  return (
    <PureButtonHelper
      onClick={handleUncheckTodoItems}
      className="text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-3 3xl:px-4 py-1.5 3xl:py-2 w-full flex items-center text-xs 3xl:text-sm whitespace-nowrap"
      i18nText={todoItemsKeys.uncheckAll}
      disabled={uncheckTodoItemsLoading}
    />
  );
}

export default MessageDropdownUncheckTodoItemsButton;
