import { useCallback, useState } from 'react';
import last from 'lodash/last';

import { TaskNanoID } from '../../../../../../tasks/tasksTypes';

import { FoldersListItemFolder } from '../../../FoldersListItem';

function useAttachmentsModalAttachmentsList() {
  const [breadCrumbsHistory, setBreadCrumbsHistory] = useState<
    FoldersListItemFolder[]
  >([]);

  const [currentTaskNanoId, setCurrentTaskNanoId] = useState<
    TaskNanoID | undefined
  >();

  const handleSetCurrentFolder = useCallback<
    (folder?: FoldersListItemFolder) => void
  >(
    (folder) => {
      if (!folder) {
        setBreadCrumbsHistory([]);
        setCurrentTaskNanoId(undefined);
        return;
      }
      const newBreadCrumbsHistory = [...breadCrumbsHistory];
      const folderIndex = newBreadCrumbsHistory.findIndex(
        (item) => item.id === folder.id
      );
      if (folderIndex === -1) {
        newBreadCrumbsHistory.push(folder);
      } else {
        newBreadCrumbsHistory.splice(folderIndex + 1);
      }
      setBreadCrumbsHistory(newBreadCrumbsHistory);
      folder.name && setCurrentTaskNanoId(folder.taskNanoId);
    },
    [breadCrumbsHistory]
  );

  return {
    breadCrumbsHistory,
    currentBreadCrumb: last(breadCrumbsHistory),
    currentTaskNanoId,
    handleSetCurrentFolder
  };
}

export default useAttachmentsModalAttachmentsList;
