import { gql } from 'graphql-request';

import { MessageFlagged } from '../messagesTypes';

export interface ToggleMessageFlaggedRecordType {
  flagged: MessageFlagged;
}

export const TOGGLE_MESSAGE_FLAGGED_QUERY = gql`
  mutation ToggleMessageFlagged($uuid: ID!) {
    toggleMessageFlagged(input: { uuid: $uuid }) {
      errors {
        fullMessages
      }
      recordUuid
      status
      record {
        flagged
      }
    }
  }
`;
