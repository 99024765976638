import {
  MessageUUID,
  DownloadMessageAttachmentsGqlQuery,
  DownloadMessageAttachmentsGqlStatus,
  MessageGqlError,
  MessageDeviceNanoId
} from '../../messagesTypes';

import { useUpdateQuery } from '../../../common/hooks/base/reactQuery/useUpdateQuery';

interface DownloadMessageAttachmentsOptions {
  query: DownloadMessageAttachmentsGqlQuery;
}

export interface DownloadMessageAttachmentsResponse<
  DownloadMessageAttachmentsRecordType
> {
  downloadMessageAttachments: {
    status: DownloadMessageAttachmentsGqlStatus;
    recordUuid: MessageUUID;
    record: DownloadMessageAttachmentsRecordType;
    errors: DownloadMessageAttachmentsError;
  };
}

export interface DownloadMessageAttachmentsInput {
  uuid: MessageUUID;
  deviceNanoId: MessageDeviceNanoId;
}

export interface DownloadMessageAttachmentsError {
  fullMessages: MessageGqlError;
}

const action = 'downloadMessageAttachments';

function useDownloadMessageAttachments<DownloadMessageAttachmentsRecordType>({
  query
}: DownloadMessageAttachmentsOptions) {
  const {
    updateQueryData,
    updateQuery,
    updateQueryReset,
    updateQueryError,
    updateQueryLoading,
    updateQueryErrorMessage
  } = useUpdateQuery<
    DownloadMessageAttachmentsInput,
    DownloadMessageAttachmentsResponse<DownloadMessageAttachmentsRecordType>,
    DownloadMessageAttachmentsError,
    DownloadMessageAttachmentsRecordType
  >({ action, query });

  return {
    downloadMessageAttachmentsData: updateQueryData,
    downloadMessageAttachmentsError: updateQueryError,
    downloadMessageAttachmentsLoading: updateQueryLoading,
    downloadMessageAttachmentsErrorMessage: updateQueryErrorMessage,
    downloadMessageAttachments: updateQuery,
    downloadMessageAttachmentsReset: updateQueryReset
  };
}

export default useDownloadMessageAttachments;
