import { KeyboardEvent, useCallback, useEffect, useRef } from 'react';
import { Control, FieldArrayWithId, FieldError } from 'react-hook-form';
import {
  DragDropContext,
  Droppable,
  OnDragEndResponder
} from '@hello-pangea/dnd';
import last from 'lodash/last';

import { IsLoading } from '../../../../../types';
import { UpdateTodoItemsFormData } from './UpdateTodoItemsForm.types';
import { IconsEnum } from '../../../../../assets/icons/types';
import { LexicalFieldRefType } from '../../../../../helpers/FormFields/LexicalEditorField/LexicalEditorField';

import { UpdateTodoItemsFormItemFields } from './components/UpdateTodoItemsFormItemFields';

import { LexicalEditorHelper } from '../../../../../helpers/LexicalEditorHelper';
import { Form } from '../../../../../helpers/Form';
import { Icon } from '../../../../../helpers/Icon';
import { TextareaAutosizeField } from '../../../../../helpers/FormFields/TextareaAutosizeField';

import { todoItemsKeys } from '../../../../../locales/keys';

interface UpdateTodoItemsFormProps {
  form: string;
  control: Control<UpdateTodoItemsFormData>;
  fields: FieldArrayWithId<UpdateTodoItemsFormData, 'todoItems', 'id'>[];
  isLoading?: IsLoading;
  onAddTodoItem: (name?: string) => void;
  onAddTodoItems: (names: string[]) => void;
  onRemoveTodoItem: (index: number) => void;
  toggleWithoutCheckbox: (index: number) => void;
  handleDragItemEnd: OnDragEndResponder;
  fieldsValidationError?: {
    name?: {
      text?: FieldError;
    };
  }[];
}

function UpdateTodoItemsForm({
  form,
  fields,
  control,
  onAddTodoItem,
  onAddTodoItems,
  onRemoveTodoItem,
  toggleWithoutCheckbox,
  isLoading,
  handleDragItemEnd,
  fieldsValidationError
}: UpdateTodoItemsFormProps) {
  const addFieldRef = useRef<LexicalFieldRefType>(null);

  useEffect(() => {
    addFieldRef.current?.focus();
  }, []);

  const handleKeyDown = useCallback<(e: KeyboardEvent<HTMLDivElement>) => void>(
    (e) => {
      if (e.key === 'Enter' && e.shiftKey) {
        return;
      }

      if (e.key === 'Enter') {
        e.stopPropagation();
        addFieldRef.current?.focus();
      }
    },
    []
  );

  return (
    <Form className="flex-1 overflow-y-auto px-2 z-0" id={form}>
      <div className="p-4">
        <DragDropContext onDragEnd={handleDragItemEnd}>
          <div className="-mx-4">
            <div className="mb-2">
              <TextareaAutosizeField
                i18nPlaceholder={todoItemsKeys.enterListTitle}
                className="w-full resize-none font-semibold text-base bg-transparent placeholder-gray-600 placeholder:font-normal border-0 px-8 py-1 rounded focus:bg-gray-100 dark:focus:bg-gray-800 focus:border-0 dark:focus:border-0 focus:outline-none focus:ring-0 focus:ring-offset-0"
                disabled={isLoading}
                control={control}
                name="title"
              />
            </div>

            <div onKeyDownCapture={handleKeyDown}>
              <Droppable droppableId={form}>
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {fields.map((field, index) => (
                      <UpdateTodoItemsFormItemFields
                        todoItemFieldId={field.id}
                        key={field.id}
                        index={index}
                        onRemove={onRemoveTodoItem}
                        control={control}
                        isLoading={isLoading}
                        toggleWithoutCheckbox={toggleWithoutCheckbox}
                        error={
                          fieldsValidationError?.[index]?.name?.text?.message
                        }
                      />
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>

            <div className="flex items-start gap-x-0.5">
              <div className="flex items-center">
                <Icon icon={IconsEnum.PLUS_SOLID} className="w-5 h-5" />
              </div>

              <LexicalEditorHelper
                inputClassName="markdown min-h-[1.25rem] outline-none resize-none bg-transparent placeholder-gray-500 border-0 px-1 py-0 rounded focus:outline-none focus:ring-4 ring-blue-200 focus:bg-transparent"
                wrapperClassName="flex flex-1 -mt-0.5"
                disabled={isLoading}
                name="empty"
                onChange={(value) => {
                  if (!value) return;

                  const text = value.text?.split(/(?:\r?\n)+/) || [];
                  if (text.length > 0) {
                    const textWithoutEmptyLines =
                      last(text) === '' ? text.slice(0, -1) : text;

                    if (textWithoutEmptyLines.length === 1) {
                      onAddTodoItem(text[0]);
                      addFieldRef.current?.blur();
                    }
                    if (textWithoutEmptyLines.length > 1) {
                      onAddTodoItems(text);
                      addFieldRef.current?.blur();
                    }
                  }
                }}
                value={{ text: '' }}
                editorRef={addFieldRef}
              />
            </div>
          </div>
        </DragDropContext>
      </div>
    </Form>
  );
}

export default UpdateTodoItemsForm;
