import React, { Fragment, useMemo } from 'react';
import size from 'lodash/size';
import { Control } from 'react-hook-form';

import {
  ForwardMessageFields,
  MessageProjectUUID
} from '../../../messagesTypes';
import { ErrorMessage, IsLoading } from '../../../../../types';
import { MultiSelectDataType } from '../../../../../helpers/MultiSelect/types';
import { IconsEnum } from '../../../../../assets/icons/types';
import {
  ForwardMessageFormData,
  ForwardMessageFormMessage
} from './ForwardMessageForm.types';

import { useTranslate } from '../../../../../common/hooks/useTranslate';

import { Form } from '../../../../../helpers/Form';
import { Icon } from '../../../../../helpers/Icon';
import { Translate } from '../../../../../helpers/Translate';
import { IconBooleanField } from '../../../../../helpers/FormFields/IconBooleanField';
import { TasksByProjectSelectField } from '../../../../tasks/helpers/TasksByProjectSelectField';
import { CheckPermissions } from '../../../../../helpers/CheckPermissions';
import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { LexicalEditorField } from '../../../../../helpers/FormFields/LexicalEditorField';
import { ProjectUuidsSelectField } from '../../../../projects/helpers/ProjectUuidsSelectField';

import { MessagesPermissions } from '../../../messagesConstants';
import {
  attachmentsKeys,
  messagesKeys,
  projectsKeys,
  tasksKeys,
  words
} from '../../../../../locales/keys';
import { FORWARD_MESSAGE_DEFAULT_TASK_VALUE } from './hooks/useForwardMessageFormDefaultValue/useForwardMessageFormDefaultValue';

interface ForwardMessageFormProps {
  message: ForwardMessageFormMessage;
  form: string;
  control: Control<ForwardMessageFormData>;
  forwardMessageLoading: IsLoading;
  selectedProjectUuid: MessageProjectUUID;
  forwardMessageProjectDefaultValue: MultiSelectDataType;
  forwardMessageTaskDefaultValue: MultiSelectDataType[];
  taskUuidsValidationError: ErrorMessage;
  withAttachments: boolean;
}

function ForwardMessageForm({
  message,
  form,
  control,
  forwardMessageLoading,
  selectedProjectUuid,
  forwardMessageProjectDefaultValue,
  forwardMessageTaskDefaultValue,
  taskUuidsValidationError,
  withAttachments
}: ForwardMessageFormProps) {
  const { t } = useTranslate();
  const sizeAttachments =
    size(message.fileAttachments) +
    size(message.selectedLifestyles) +
    size(message.selectedMaterials) +
    size(message.selectedProducts);

  const body = message.body || message.forwardedMessage?.body;

  const projectOption = useMemo<MultiSelectDataType>(
    () => ({
      value: FORWARD_MESSAGE_DEFAULT_TASK_VALUE,
      label: t(projectsKeys.messages)
    }),
    [t]
  );

  return (
    <Form id={form}>
      <div className="p-4">
        <div className="mb-2 flex">
          <div className="flex-1 text-xs">
            <div className="mb-0.5 ml-2 text-gray-600 dark:text-gray-400">
              <Icon
                icon={IconsEnum.ARROW_RIGHT_SOLID}
                className="w-3.5 h-3.5 inline mr-1 pb-0.5"
              />{' '}
              <Translate id={messagesKeys.forwardMessageFrom} />{' '}
              {message.user.fullName}
            </div>
            <div className="line-clamp-2 pl-3 py-0.5 border-l-4 break-wrap">
              <div>
                {withAttachments && (
                  <Fragment>
                    <Icon
                      icon={IconsEnum.PAPER_CLIP_SOLID}
                      className="w-3.5 h-3.5 inline mr-0.5"
                    />{' '}
                    <span className="italic">
                      {sizeAttachments}{' '}
                      <Translate id={attachmentsKeys.pluralLowerCase} />{' '}
                    </span>
                    {body ? <span className="mx-0.5">•</span> : null}
                  </Fragment>
                )}
              </div>
              <div>{body}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="p-4 flex space-x-1">
        <LexicalEditorField
          // ignore because of "Type instantiation is excessively deep and possibly infinite." error
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          control={control}
          name={ForwardMessageFields.BODY}
          disabled={forwardMessageLoading}
          wrapperClassName="flex-1"
          inputClassName="markdown markdown-sm min-h-[2rem] basic-input"
          companyNanoId={
            message.task?.project?.team?.nanoId || message.project?.team?.nanoId
          }
          projectNanoId={
            message.task?.project?.nanoId || message.project?.nanoId
          }
          taskNanoId={message.task?.nanoId}
        />

        <CheckPermissions
          action={MessagesPermissions.READ_MESSAGE_VISIBLE_FOR_CLIENTS_BUTTON}
        >
          <div>
            <IconBooleanField
              name={ForwardMessageFields.VISIBLE_FOR_CLIENT}
              control={control}
              yesClassName="py-2 pl-2 pr-2 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base text-blue-600 hover:text-blue-700 bg-blue-500 bg-opacity-20 hover:bg-opacity-30 dark:text-blue-400 dark:hover:text-blue-300 focus:ring-offset-0"
              noClassName="py-2 pl-2 pr-2 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:outline-none focus:ring-2 focus:ring-gray-900 dark:focus:ring-gray-100 hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
              yesIcon={IconsEnum.EYE}
              noIcon={IconsEnum.EYE_CROSSED}
            />
          </div>
        </CheckPermissions>
      </div>

      {withAttachments ? (
        <div className="p-4 flex items-center gap-1">
          <IconBooleanField
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            yesClassName="w-6 h-6 rounded-md flex items-center justify-center focus:ring-base text-blue-600 hover:text-blue-700 bg-blue-500 bg-opacity-20 hover:bg-opacity-30 dark:text-blue-400 dark:hover:text-blue-300 focus:ring-offset-0"
            noClassName="w-6 h-6 rounded-md flex items-center justify-center outline-none ring-2 ring-gray-900 dark:ring-gray-100 hover:bg-gray-200 dark:hover:bg-gray-700 ring-offset-0 focus:ring-offset-0"
            noIconClassName="w-0 h-0 hidden"
            name={ForwardMessageFields.INCLUDES_ATTACHMENTS}
            yesIcon={IconsEnum.CHECK}
            noIcon={IconsEnum.CHECK_OUTLINE}
            control={control}
          />

          <span className="ml-2 block text-sm text-gray-900 dark:text-gray-100">
            <Translate id={words.include} /> {sizeAttachments}{' '}
            <Translate id={attachmentsKeys.pluralLowerCase} />
          </span>
        </div>
      ) : null}

      <div className="p-4">
        <ProjectUuidsSelectField<ForwardMessageFormData>
          // ignore because of "Type instantiation is excessively deep and possibly infinite." error
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          control={control}
          name={ForwardMessageFields.PROJECT_UUID}
          i18nPlaceholder={projectsKeys.selectSingular}
          inputWrapperClassName="mt-1 relative"
          i18nLabel={projectsKeys.selectSingular}
          labelClassName="block text-sm font-medium text-gray-700 dark:text-gray-300"
          disabled={forwardMessageLoading}
          defaultValue={forwardMessageProjectDefaultValue}
          initialFilters={{
            teamNanoIds: [
              message.task?.project?.team?.nanoId ||
                message.project?.team?.nanoId
            ]
          }}
        />
      </div>

      <div className="p-4">
        <TasksByProjectSelectField<ForwardMessageFormData>
          multi
          projectUuid={selectedProjectUuid}
          control={control}
          name={ForwardMessageFields.TASK_UUIDS}
          i18nPlaceholder={tasksKeys.selectSingular}
          inputWrapperClassName="mt-1 relative"
          i18nLabel={tasksKeys.selectSingular}
          labelClassName="block text-sm font-medium text-gray-700 dark:text-gray-300"
          disabled={forwardMessageLoading}
          tasksSelectDefaultValue={forwardMessageTaskDefaultValue}
          currentProjectOption={projectOption}
          closeMenuOnSelect={false}
        />
        <AlertMessage
          addClassName="mt-4"
          i18nMessage={taskUuidsValidationError}
        />
      </div>
    </Form>
  );
}

export default ForwardMessageForm;
