import { MessageUUID } from '../../messagesTypes';

import { useUpdateQuery } from '../../../common/hooks/base/reactQuery/useUpdateQuery';

interface PropagateMessageOptions {
  indexCacheKey: string;
  query: string;
}

interface PropagateMessageInput {
  uuid: MessageUUID;
}

interface PropagateMessageError {
  fullMessages: string[] | null;
}

interface PropagateMessageResponse<PropagateMessageRecordType> {
  propagateMessage: {
    status: string;
    record: PropagateMessageRecordType;
    errors: PropagateMessageError;
  };
}

const action = 'propagateMessage';

function usePropagateMessage<PropagateMessageRecordType>({
  indexCacheKey,
  query
}: PropagateMessageOptions) {
  const {
    updateQueryData,
    updateQuery,
    updateQueryReset,
    updateQueryError,
    updateQueryLoading,
    updateQueryErrorMessage
  } = useUpdateQuery<
    PropagateMessageInput,
    PropagateMessageResponse<PropagateMessageRecordType>,
    PropagateMessageError,
    PropagateMessageRecordType
  >({ action, cacheKeys: [indexCacheKey], query });

  return {
    propagateMessageData: updateQueryData,
    propagateMessageError: updateQueryError,
    propagateMessageLoading: updateQueryLoading,
    propagateMessageErrorMessage: updateQueryErrorMessage,
    propagateMessage: updateQuery,
    propagateMessageReset: updateQueryReset
  };
}

export default usePropagateMessage;
