import {
  AddToDescriptionMessageGqlQuery,
  AddToDescriptionMessageGqlStatus,
  FetchMessageInProjectCacheKey,
  FetchMessageInTaskCacheKey,
  MessageGqlError,
  MessageUUID
} from '../../messagesTypes';

import { useUpdateQuery } from '../../../common/hooks/base/reactQuery/useUpdateQuery';

interface AddToDescriptionMessageOptions {
  cacheKeys: FetchMessageInProjectCacheKey[] | FetchMessageInTaskCacheKey[];
  query: AddToDescriptionMessageGqlQuery;
}

interface AddToDescriptionMessageInput {
  uuid: MessageUUID;
}

interface AddToDescriptionMessageError {
  fullMessages: MessageGqlError;
}

interface AddToDescriptionMessageResponse<AddToDescriptionMessageRecordType> {
  addToDescriptionMessage: {
    status: AddToDescriptionMessageGqlStatus;
    recordUuid: MessageUUID;
    record: AddToDescriptionMessageRecordType;
    errors: AddToDescriptionMessageError;
  };
}

const action = 'addToDescriptionMessage';

function useAddToDescriptionMessage<AddToDescriptionMessageRecordType>({
  cacheKeys,
  query
}: AddToDescriptionMessageOptions) {
  const {
    updateQueryData,
    updateQuery,
    updateQueryReset,
    updateQueryError,
    updateQueryLoading,
    updateQueryErrorMessage
  } = useUpdateQuery<
    AddToDescriptionMessageInput,
    AddToDescriptionMessageResponse<AddToDescriptionMessageRecordType>,
    AddToDescriptionMessageError,
    AddToDescriptionMessageRecordType
  >({ action, cacheKeys, query });

  return {
    addToDescriptionMessageData: updateQueryData,
    addToDescriptionMessageError: updateQueryError,
    addToDescriptionMessageLoading: updateQueryLoading,
    addToDescriptionMessageErrorMessage: updateQueryErrorMessage,
    addToDescriptionMessage: updateQuery,
    addToDescriptionMessageReset: updateQueryReset
  };
}

export default useAddToDescriptionMessage;
