import { useMemo } from 'react';

import { IconsEnum } from '../../../../../../../../../assets/icons/types';

import { ProjectCache } from '../../../../../../../../projects/ProjectCache';
import { ProjectPath } from '../../../../../../../../projects/ProjectPath';
import { ProjectNanoID } from '../../../../../../../../projects/projectsTypes';
import { FoldersListFolders } from '../../../../../FoldersList';

import {
  FetchProjectAttachmentsTasksQueryResponse,
  FETCH_PROJECT_ATTACHMENTS_TASKS_QUERY
} from '../../../../../../../../tasks/queries/fetchProjectAttachmentsTasks.query';

import { useTasks } from '../../../../../../../../tasks/hooks/useTasks';

interface AttachmentsModalFoldersTasksListOptions {
  folders: FoldersListFolders;
  projectNanoId?: ProjectNanoID;
}

function useAttachmentsModalFoldersTasksList({
  folders,
  projectNanoId
}: AttachmentsModalFoldersTasksListOptions) {
  const {
    tasks,
    tasksError,
    tasksIsPlaceholderData,
    tasksFetched,
    tasksFetchingNextPage,
    hasNextTasksPage,
    loadMoreTasks
  } = useTasks<FetchProjectAttachmentsTasksQueryResponse>({
    cacheKey: ProjectCache.attachmentsTasksCacheKey(projectNanoId),
    query: FETCH_PROJECT_ATTACHMENTS_TASKS_QUERY,
    initialFilters: { projectNanoId }
  });

  const tasksFolders = useMemo<FoldersListFolders>(
    () =>
      tasks.map((task) => ({
        id: ProjectPath.taskAttachments(projectNanoId, task.nanoId).replace(
          /\\/,
          '-'
        ),
        taskNanoId: task.nanoId,
        uuid: task.uuid,
        name: task.name,
        href: ProjectPath.taskAttachments(projectNanoId, task.nanoId),
        icon: IconsEnum.FOLDER_SOLID,
        user: task.user
      })),
    [projectNanoId, tasks]
  );

  const folderList = useMemo<FoldersListFolders>(
    () => [...folders, ...tasksFolders],
    [folders, tasksFolders]
  );

  return {
    tasksError,
    tasksIsPlaceholderData,
    tasksFetched,
    tasksFetchingNextPage,
    hasNextTasksPage,
    loadMoreTasks,
    folderList
  };
}

export default useAttachmentsModalFoldersTasksList;
