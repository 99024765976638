import {
  DeleteTodoItemGqlQuery,
  DeleteTodoItemGqlStatus,
  DeleteTodoItemsCacheKeys,
  TodoItemGqlError,
  TodoItemID,
  TodoItemUUID
} from '../../todoItemsTypes';

import { useDeleteQuery } from '../../../common/hooks/base/reactQuery/useDeleteQuery';

interface DeleteTodoItemInput {
  uuid: TodoItemUUID | TodoItemID;
}

interface DeleteTodoItemResponse {
  deleteTodoItem: {
    status: DeleteTodoItemGqlStatus;
    errors: DeleteTodoItemError;
  };
}

interface DeleteTodoItemError {
  fullMessages: TodoItemGqlError;
}

interface DeleteTodoItemOptions {
  indexCacheKey?: string;
  query: DeleteTodoItemGqlQuery;
  cacheKeys?: DeleteTodoItemsCacheKeys;
}

const action = 'deleteTodoItem';

function useDeleteTodoItem({
  query,
  indexCacheKey,
  cacheKeys
}: DeleteTodoItemOptions) {
  const {
    deleteQueryData,
    deleteQuery,
    deleteQueryReset,
    deleteQueryError,
    deleteQueryLoading,
    deleteQueryErrorMessage
  } = useDeleteQuery<
    DeleteTodoItemInput,
    DeleteTodoItemResponse,
    DeleteTodoItemError
  >({ action, pluralScope: indexCacheKey, query, cacheKeys });

  return {
    deleteTodoItemData: deleteQueryData,
    deleteTodoItemError: deleteQueryError,
    deleteTodoItemLoading: deleteQueryLoading,
    deleteTodoItemErrorMessage: deleteQueryErrorMessage,
    deleteTodoItem: deleteQuery,
    deleteTodoItemReset: deleteQueryReset
  };
}

export default useDeleteTodoItem;
