class InsertTextTextarea {
  static insertText?: any;
  static initialize() {
    if (typeof window !== 'undefined') {
      import('insert-text-textarea').then((imported) => {
        InsertTextTextarea.insertText = imported?.default;
      });
    }
  }
}

InsertTextTextarea.initialize();

export default InsertTextTextarea;
