import { useCallback } from 'react';

import {
  TodoItemName,
  TodoItemUUID,
  UpdateTodoItemGqlQuery,
  UpdateTodoItemGqlStatus,
  TodoItemGqlError,
  TodoItemDone,
  TodoItemVisibleForClients,
  TodoItemID,
  UpdateTodoItemsCacheKeys
} from '../../todoItemsTypes';

import { UpdateIndexQueryItemCacheAction } from '../../../common/hooks/base/reactQuery/useIndexQuery';

import { useUpdateQuery } from '../../../common/hooks/base/reactQuery/useUpdateQuery';

interface UpdateTodoItemInput {
  uuid: TodoItemUUID | TodoItemID;
  name?: TodoItemName;
  done?: TodoItemDone;
  visibleForClients?: TodoItemVisibleForClients;
}

interface UpdateTodoItemResponse<UpdateTodoItemRecordType> {
  updateTodoItem: {
    status: UpdateTodoItemGqlStatus;
    recordUuid: TodoItemUUID;
    record: UpdateTodoItemRecordType;
    errors: UpdateTodoItemErrors;
  };
}

interface UpdateTodoItemErrors {
  name: TodoItemGqlError;
  fullMessages: TodoItemGqlError;
}

interface UpdateTodoItemOptions<TodoItemRecordType> {
  query: UpdateTodoItemGqlQuery;
  /**
   * @deprecated You should use cacheKeys instead
   */
  indexCacheKey?: string;
  /**
   * @deprecated You should use cacheKeys instead
   */
  showCacheKey?: string;
  cacheKeys?: UpdateTodoItemsCacheKeys;
  updateTodoItemCache?: UpdateIndexQueryItemCacheAction<TodoItemRecordType>;
}

interface UpdateTodoItem {
  uuid: TodoItemUUID;
  name: TodoItemName;
  done: TodoItemDone;
}

const action = 'updateTodoItem';

function useUpdateTodoItem<UpdateTodoItemRecordType extends UpdateTodoItem>({
  indexCacheKey,
  showCacheKey,
  cacheKeys,
  query,
  updateTodoItemCache
}: UpdateTodoItemOptions<UpdateTodoItemRecordType>) {
  const handleOptimisticUpdate = useCallback<
    (input: UpdateTodoItemInput) => null | (() => void)
  >(
    (input) =>
      updateTodoItemCache?.({
        selector: (record) => record.uuid === input.uuid,
        updateFunction: (prevTodoItem) => ({
          ...prevTodoItem,
          name: input.name ?? prevTodoItem?.name,
          done: !prevTodoItem?.done
        })
      }),
    [updateTodoItemCache]
  );

  const {
    updateQueryData,
    updateQuery,
    updateQueryReset,
    updateQueryError,
    updateQueryLoading,
    updateQueryErrorMessage
  } = useUpdateQuery<
    UpdateTodoItemInput,
    UpdateTodoItemResponse<UpdateTodoItemRecordType>,
    UpdateTodoItemErrors,
    UpdateTodoItemRecordType
  >({
    action,
    pluralScope: indexCacheKey,
    scope: showCacheKey,
    cacheKeys,
    query,
    onOptimisticUpdate: updateTodoItemCache ? handleOptimisticUpdate : undefined
  });

  return {
    updateTodoItemData: updateQueryData,
    updateTodoItemError: updateQueryError,
    updateTodoItemLoading: updateQueryLoading,
    updateTodoItemErrorMessage: updateQueryErrorMessage,
    updateTodoItem: updateQuery,
    updateTodoItemReset: updateQueryReset
  };
}

export default useUpdateTodoItem;
