import React, { Fragment, memo } from 'react';

import { ProjectNanoID } from '../../../../../../projects/projectsTypes';
import { TaskNanoID } from '../../../../../../tasks/tasksTypes';

import { useAttachmentsModalFoldersList } from './hooks/useAttachmentsModalFoldersList';

import { FoldersListItemFolder } from '../../../FoldersListItem';
import { FoldersListBreadCrumbs } from '../../../FoldersListBreadCrumbs';
import { AttachmentsModalFoldersTasksList } from '../AttachmentsModalFoldersTasksList';
import { FoldersList } from '../../../FoldersList';
import { LoadingSkeleton } from '../../../../../../../helpers/LoadingSkeleton';

interface AttachmentsModalFoldersListProps {
  projectNanoId?: ProjectNanoID;
  taskNanoId?: TaskNanoID;
  changeCurrentFolder?: (folder?: FoldersListItemFolder) => void;
  breadCrumbsHistory?: FoldersListItemFolder[];
  currentBreadCrumb?: FoldersListItemFolder;
}

function AttachmentsModalFoldersList({
  projectNanoId,
  taskNanoId,
  changeCurrentFolder,
  breadCrumbsHistory,
  currentBreadCrumb
}: AttachmentsModalFoldersListProps) {
  const {
    folders,
    foldersLoaded,
    breadCrumbsI18nText,
    hrefItems,
    handleClickBack,
    handleClickBreadCrumbsHref
  } = useAttachmentsModalFoldersList({
    projectNanoId,
    taskNanoId,
    breadCrumbsHistory,
    currentBreadCrumb,
    changeCurrentFolder
  });

  return (
    <Fragment>
      <FoldersListBreadCrumbs
        i18nText={breadCrumbsI18nText}
        hrefItems={hrefItems}
        onClickBack={handleClickBack}
        onClickBreadCrumbsHref={handleClickBreadCrumbsHref}
      />
      <LoadingSkeleton loaded={foldersLoaded} count={3}>
        {taskNanoId ? (
          <FoldersList folders={folders} onItemClick={changeCurrentFolder} />
        ) : (
          <AttachmentsModalFoldersTasksList
            folders={folders}
            projectNanoId={projectNanoId}
            onItemClick={changeCurrentFolder}
          />
        )}
      </LoadingSkeleton>
    </Fragment>
  );
}

export default memo<AttachmentsModalFoldersListProps>(
  AttachmentsModalFoldersList
);
