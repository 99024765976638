import { MessageID } from '../../../messages/messagesTypes';

import { CreateItemCacheKeys } from '../../../../types';
import { ProjectID } from '../../../projects/projectsTypes';
import { TaskID } from '../../../tasks/tasksTypes';
import {
  CreateTodoItemsGqlQuery,
  CreateTodoItemsGqlStatus,
  TodoItemDone,
  TodoItemGqlError,
  TodoItemName,
  TodoItemVisibleForClients
} from '../../todoItemsTypes';

import { useCreateQuery } from '../../../common/hooks/base/reactQuery/useCreateQuery';

interface CreateTodoItemsInputItem {
  done: TodoItemDone;
  name: TodoItemName;
  visibleForClients: TodoItemVisibleForClients;
  projectId?: ProjectID;
  taskId?: TaskID;
  messageId?: MessageID;
}

interface CreateTodoItemsInput {
  todoItems: CreateTodoItemsInputItem[];
}

interface CreateTodoItemsResponse {
  createTodoItems: {
    status: CreateTodoItemsGqlStatus;
    errors: CreateTodoItemsErrors;
  };
}

interface CreateTodoItemsErrors {
  fullMessages: TodoItemGqlError;
}

interface CreateTodoItemsOptions {
  query: CreateTodoItemsGqlQuery;
  cacheKeys?: CreateItemCacheKeys;
}

const action = 'createTodoItems';

function useCreateTodoItems<CreateTodoItemsRecordType>({
  query,
  cacheKeys
}: CreateTodoItemsOptions) {
  const {
    createQueryData,
    createQuery,
    createQueryReset,
    createQueryError,
    createQueryErrorMessage,
    createQueryLoading
  } = useCreateQuery<
    CreateTodoItemsInput,
    CreateTodoItemsResponse,
    CreateTodoItemsErrors,
    CreateTodoItemsRecordType
  >({
    action,
    cacheKeys,
    query
  });

  return {
    createTodoItemsData: createQueryData,
    createTodoItemsError: createQueryError,
    createTodoItemsLoading: createQueryLoading,
    createTodoItemsErrorMessage: createQueryErrorMessage,
    createTodoItems: createQuery,
    createTodoItemsReset: createQueryReset
  };
}

export default useCreateTodoItems;
