import { gql } from 'graphql-request';

import { MessageLikeItems } from '../messagesTypes';

export interface DownloadMessageSelectedProductsQueryResponse {
  messageLikes: MessageLikeItems;
}

export const DOWNLOAD_MESSAGE_SELECTED_PRODUCTS_QUERY = gql`
  mutation DownloadMessageSelectedProducts($uuid: ID!, $deviceNanoId: String!) {
    downloadMessageSelectedProducts(
      input: { uuid: $uuid, deviceNanoId: $deviceNanoId }
    ) {
      recordUuid
      status
      record {
        uuid
      }
      errors {
        fullMessages
      }
    }
  }
`;
