import { ClassName, I18nText } from '../../../../../types';
import { IconsEnum } from '../../../../../assets/icons/types';
import { PageNanoID } from '../../../../pages/pagesTypes';

import { ShowPageModalButton } from '../../../../pages/components/modalButtons/ShowPageModalButton';

import { stringsKeys } from '../../../../../locales/keys';

import { MARKDOWN_HELP_PAGE_NANOID } from '../../../../../config';
import { TooltipPlacement } from '../../../../../helpers/tooltips/tooltipsConstants';

interface MarkdownHelpModalButtonProps {
  icon?: IconsEnum;
  className?: ClassName;
  iconClassName?: ClassName;
  i18nText?: I18nText;
}

function MarkdownHelpModalButton({
  icon,
  className,
  iconClassName,
  i18nText
}: MarkdownHelpModalButtonProps) {
  return (
    <ShowPageModalButton
      className={
        className ||
        'py-2 pl-2 pr-2 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0'
      }
      i18nText={i18nText}
      i18nTitle={stringsKeys.helpWithTextFormatting}
      icon={icon || IconsEnum.QUESTION_MARK_CIRCLE_OUTLINE}
      iconClassName={iconClassName || 'h-6 w-6'}
      pageNanoId={MARKDOWN_HELP_PAGE_NANOID as PageNanoID}
      tooltipI18nText={stringsKeys.textFormattingHelp}
      tooltipPlacement={TooltipPlacement.TOP}
    />
  );
}

export default MarkdownHelpModalButton;
