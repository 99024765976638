import { useCallback, useEffect, useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';
import compact from 'lodash/compact';

import {
  BreadcrumbsUrl,
  BreadcrumbsUrls,
  I18nText
} from '../../../../../../../../../types';
import { TaskNanoID } from '../../../../../../../../tasks/tasksTypes';
import { IconsEnum } from '../../../../../../../../../assets/icons/types';
import { ProjectNanoID } from '../../../../../../../../projects/projectsTypes';

import {
  FetchFileAttachmentsQueryResponse,
  FETCH_FILE_ATTACHMENTS_QUERY
} from '../../../../../../../../fileAttachments/queries/fetchFileAttachments.query';

import { useFileAttachments } from '../../../../../../../../fileAttachments/hooks/useFileAttachments';

import { FoldersListFolders } from '../../../../../FoldersList';
import { FoldersListItemFolder } from '../../../../../FoldersListItem';

import { Files } from '../../../../../../../../../utils/Files';

import { ProjectPath } from '../../../../../../../../projects/ProjectPath';
import { TaskPath } from '../../../../../../../../tasks/TaskPath';
import { ProjectCache } from '../../../../../../../../projects/ProjectCache';
import { TaskCache } from '../../../../../../../../tasks/TaskCache';

import {
  filesKeys,
  projectsKeys,
  imagesKeys,
  tasksKeys,
  words
} from '../../../../../../../../../locales/keys';

interface AttachmentsModalFoldersListOptions {
  projectNanoId?: ProjectNanoID;
  taskNanoId?: TaskNanoID;
  breadCrumbsHistory?: FoldersListItemFolder[];
  currentBreadCrumb?: FoldersListItemFolder;
  changeCurrentFolder?: (folder?: FoldersListItemFolder) => void;
}

function useAttachmentsModalFoldersList({
  projectNanoId,
  taskNanoId,
  breadCrumbsHistory,
  currentBreadCrumb,
  changeCurrentFolder
}: AttachmentsModalFoldersListOptions) {
  const {
    fileAttachments: files,
    fileAttachmentsFetched: filesFetched,
    fileAttachmentsIsPlaceholderData: filesPlaceholderData,
    fileAttachmentsTotalCount: filesTotalCount,
    filterFileAttachments
  } = useFileAttachments<FetchFileAttachmentsQueryResponse>({
    cacheKey: taskNanoId
      ? TaskCache.fileAttachmentsCacheKey(taskNanoId)
      : ProjectCache.fileAttachmentsCacheKey(projectNanoId),
    query: FETCH_FILE_ATTACHMENTS_QUERY,
    initialFilters: {
      projectNanoId: taskNanoId ? undefined : projectNanoId,
      taskNanoId: taskNanoId ? taskNanoId : undefined,
      ext: { notIn: Files.imageExtensions }
    },
    options: {
      enabled: true
    }
  });

  const {
    fileAttachments: imageAttachments,
    fileAttachmentsFetched: imagesFetched,
    fileAttachmentsIsPlaceholderData: imagesPlaceholderData,
    fileAttachmentsTotalCount: imageAttachmentsTotalCount,
    filterFileAttachments: filterImageAttachments
  } = useFileAttachments<FetchFileAttachmentsQueryResponse>({
    cacheKey: taskNanoId
      ? TaskCache.imagesCacheKey(taskNanoId)
      : ProjectCache.imagesCacheKey(projectNanoId),
    query: FETCH_FILE_ATTACHMENTS_QUERY,
    initialFilters: {
      projectNanoId: taskNanoId ? undefined : projectNanoId,
      taskNanoId: taskNanoId ? taskNanoId : undefined,
      ext: { in: Files.imageExtensions }
    }
  });

  useEffect(() => {
    filterFileAttachments({
      projectNanoId: taskNanoId ? undefined : projectNanoId,
      taskNanoId: taskNanoId ? taskNanoId : undefined,
      ext: { notIn: Files.imageExtensions }
    });
    filterImageAttachments({
      projectNanoId: taskNanoId ? undefined : projectNanoId,
      taskNanoId: taskNanoId ? taskNanoId : undefined,
      ext: { in: Files.imageExtensions }
    });
  }, [
    filterFileAttachments,
    filterImageAttachments,
    projectNanoId,
    taskNanoId
  ]);

  const projectFolders = useMemo<FoldersListFolders>(
    () =>
      compact([
        filesTotalCount
          ? {
              id: `${projectNanoId}_files`,
              uuid: projectNanoId,
              i18nText: filesKeys.plural,
              href: ProjectPath.files(projectNanoId),
              icon: IconsEnum.FOLDER_FILE_SOLID,
              user: files[0]?.user,
              type: 'files'
            }
          : null,
        imageAttachmentsTotalCount
          ? {
              id: `${projectNanoId}_images`,
              uuid: projectNanoId,
              i18nText: imagesKeys.plural,
              href: ProjectPath.images(projectNanoId),
              icon: IconsEnum.FOLDER_IMAGE_SOLID,
              user: imageAttachments[0]?.user,
              type: 'images'
            }
          : null
      ]),
    [
      files,
      filesTotalCount,
      imageAttachments,
      imageAttachmentsTotalCount,
      projectNanoId
    ]
  );

  const taskFolders = useMemo<FoldersListFolders>(
    () =>
      taskNanoId
        ? compact([
            filesTotalCount
              ? {
                  id: `${taskNanoId}_files`,
                  uuid: null,
                  i18nText: filesKeys.plural,
                  href: TaskPath.files(taskNanoId),
                  icon: IconsEnum.FOLDER_FILE_SOLID,
                  user: files[0]?.user,
                  type: 'files'
                }
              : null,
            imageAttachmentsTotalCount
              ? {
                  id: `${taskNanoId}_images`,
                  uuid: null,
                  i18nText: imagesKeys.plural,
                  href: TaskPath.images(taskNanoId),
                  icon: IconsEnum.FOLDER_FILE_SOLID,
                  user: imageAttachments[0]?.user,
                  type: 'images'
                }
              : null
          ])
        : [],
    [
      files,
      filesTotalCount,
      imageAttachments,
      imageAttachmentsTotalCount,
      taskNanoId
    ]
  );

  const handleClickBack = useCallback<() => void>(() => {
    changeCurrentFolder(breadCrumbsHistory[breadCrumbsHistory.length - 2]);
  }, [breadCrumbsHistory, changeCurrentFolder]);

  const handleClickBreadCrumbsHref = useCallback<
    (href: BreadcrumbsUrl) => void
  >(
    (href) => {
      const selectedFolder = breadCrumbsHistory.find(
        (breadCrumb) => breadCrumb.href === href.pathUrl
      );
      changeCurrentFolder(selectedFolder);
    },
    [breadCrumbsHistory, changeCurrentFolder]
  );

  const getbreadCrumbs = useCallback<
    () => { hrefItems: BreadcrumbsUrls; breadCrumbsI18nText: I18nText }
  >(() => {
    if (isEmpty(breadCrumbsHistory)) {
      return {
        hrefItems: [],
        breadCrumbsI18nText: projectNanoId
          ? projectsKeys.allAttachments
          : tasksKeys.allAttachments
      };
    }
    const hrefs = breadCrumbsHistory.map((item) => ({
      i18nText: item.i18nText || item.name,
      pathUrl: item.href
    }));
    hrefs.splice(-1);
    return {
      hrefItems: [
        {
          tooltipI18nText: words.allAttachments,
          icon: IconsEnum.PROJECTS,
          pathUrl: projectNanoId
            ? ProjectPath.attachments(projectNanoId)
            : TaskPath.attachments(taskNanoId)
        },
        ...hrefs
      ],
      breadCrumbsI18nText: currentBreadCrumb.i18nText || currentBreadCrumb.name
    };
  }, [breadCrumbsHistory, currentBreadCrumb, projectNanoId, taskNanoId]);

  return {
    folders: taskNanoId ? taskFolders : projectFolders,
    foldersLoaded:
      filesFetched ||
      filesPlaceholderData ||
      imagesFetched ||
      imagesPlaceholderData,
    breadCrumbsI18nText: getbreadCrumbs().breadCrumbsI18nText,
    currentFolderHref: currentBreadCrumb?.href,
    hrefItems: getbreadCrumbs().hrefItems,
    handleClickBack,
    handleClickBreadCrumbsHref
  };
}

export default useAttachmentsModalFoldersList;
