import { ItemMessagesListMessageMenuDropdownMessage } from '../../ItemMessagesListMessageMenuDropdown.types';
import { FetchMessagesCacheKey } from '../../../../../../../messagesTypes';

import { MessageVisibleForClientButton } from '../../../../../../buttons/MessageVisibleForClientButton';

import { words } from '../../../../../../../../../locales/keys';

interface MessageDropdownVFCButtonProps {
  message: ItemMessagesListMessageMenuDropdownMessage;
  messagesCacheKey: FetchMessagesCacheKey;
}

function MessageDropdownVFCButton({
  message,
  messagesCacheKey
}: MessageDropdownVFCButtonProps) {
  return (
    <MessageVisibleForClientButton
      className="text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-3 3xl:px-4 py-1.5 3xl:py-2 w-full flex items-center text-xs 3xl:text-sm whitespace-nowrap"
      uuid={message.uuid}
      cacheKey={messagesCacheKey}
      i18nText={
        message.visibleForClient
          ? words.invisibleForClient
          : words.visibleForClient
      }
    />
  );
}

export default MessageDropdownVFCButton;
