import React from 'react';

import { ItemMessagesListMessageMenuDropdownMessage } from '../../ItemMessagesListMessageMenuDropdown.types';
import { FetchMessagesCacheKey } from '../../../../../../../messagesTypes';

import { useToggleMessageRed } from '../../../../../../../hooks/useToggleMessageRed';

import { PureButtonHelper } from '../../../../../../../../../helpers/buttons/PureButtonHelper';

import {
  TOGGLE_MESSAGE_RED_QUERY,
  ToggleMessageRedRecordType
} from '../../../../../../../queries/toggleMessageRed.query';

import { messagesKeys } from '../../../../../../../../../locales/keys';

interface MessageDropdownRedButtonProps {
  message: ItemMessagesListMessageMenuDropdownMessage;
  messagesCacheKey: FetchMessagesCacheKey;
}

function MessageDropdownRedButton({
  message,
  messagesCacheKey
}: MessageDropdownRedButtonProps) {
  const { toggleMessageRed, toggleMessageRedLoading } =
    useToggleMessageRed<ToggleMessageRedRecordType>({
      indexCacheKey: messagesCacheKey,
      query: TOGGLE_MESSAGE_RED_QUERY
    });

  const handleToggleMessageRed = () => toggleMessageRed({ uuid: message.uuid });

  return (
    <PureButtonHelper
      className="text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-3 3xl:px-4 py-1.5 3xl:py-2 w-full flex items-center text-xs 3xl:text-sm whitespace-nowrap"
      disabled={toggleMessageRedLoading}
      i18nText={message.red ? messagesKeys.restore : messagesKeys.archive}
      onClick={handleToggleMessageRed}
    />
  );
}

export default MessageDropdownRedButton;
