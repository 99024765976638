import React, { useCallback, useState } from 'react';
import cl from 'classnames';

import { IconsEnum } from '../../../assets/icons/types';

import { Icon } from '../../../helpers/Icon';
import { TooltipButtonHelper } from '../../../helpers/buttons/TooltipButtonHelper';
import { TooltipPlacement } from '../../tooltips/tooltipsConstants';

import { copyToClipboard } from '../../../utils/copyToClipboard';

import { words } from '../../../locales/keys';

import { CopyColorButtonCommonProps } from './CopyColorButton.types';
import { CheckPermissions } from '../../CheckPermissions';
import { MessagesPermissions } from '../../../main/messages/messagesConstants';

import { isBlackText } from '../../../utils/isBlackText';

type CopyColorButtonProps = CopyColorButtonCommonProps;

function CopyColorButton({ color, isVisibleForClient }: CopyColorButtonProps) {
  const [copied, setCopied] = useState<boolean>(false);

  const handleCopy = useCallback(() => {
    const result = copyToClipboard(color);
    if (result) {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    }
  }, [color, setCopied]);

  const isBlackTextButton = isBlackText(color);

  return (
    <TooltipButtonHelper
      className={cl(
        'flex items-center mr-0.5 mt-0.5 px-3 py-2 rounded space-x-1 focus:ring-0 focus:ring-offset-0',
        isBlackTextButton ? 'text-black' : 'text-white'
      )}
      tooltipPlacement={TooltipPlacement.TOP}
      tooltipI18nText={words.clickToCopy}
      style={{ background: color }}
      onClick={handleCopy}
    >
      <span>{color}</span>
      <Icon
        icon={copied ? IconsEnum.CHECK : IconsEnum.COPY}
        className="h-4 w-4"
      />
      {isVisibleForClient && (
        <CheckPermissions
          action={MessagesPermissions.READ_MESSAGE_VISIBLE_FOR_CLIENT_ICON}
        >
          <Icon icon={IconsEnum.EYE_SOLID} className="h-3.5 w-3.5" />
        </CheckPermissions>
      )}
    </TooltipButtonHelper>
  );
}

export default CopyColorButton;
