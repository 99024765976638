import React from 'react';
import { Controller, FieldPath } from 'react-hook-form';

import {
  ModalSelectProductsFieldControl,
  ModalSelectProductsFieldControlRequiredProps
} from './components/ModalSelectProductsFieldControl';

import { ModalSelectProductsFieldRequiredProps } from './ModalSelectProductsField.types';

interface ModalSelectProductsFieldProps<T> {
  name: FieldPath<T>;
  disabled?: boolean;
}

function ModalSelectProductsField<T>({
  control,
  name,
  disabled,
  className,
  i18nTitle,
  icon,
  iconClassName,
  i18nText,
  modalIcon,
  i18nSubmitText,
  tooltipPlacement,
  tooltipI18nText,
  withoutInitialFocus
}: ModalSelectProductsFieldProps<T> &
  ModalSelectProductsFieldRequiredProps<T> &
  ModalSelectProductsFieldControlRequiredProps) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <ModalSelectProductsFieldControl
          value={value as string[]}
          disabled={disabled}
          className={className}
          i18nTitle={i18nTitle}
          i18nText={i18nText}
          icon={icon}
          iconClassName={iconClassName}
          modalIcon={modalIcon}
          i18nSubmitText={i18nSubmitText}
          tooltipPlacement={tooltipPlacement}
          tooltipI18nText={tooltipI18nText}
          onChange={onChange}
          withoutInitialFocus={withoutInitialFocus}
        />
      )}
    />
  );
}

export default ModalSelectProductsField;
