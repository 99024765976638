import { UpdateItemCacheKeys } from '../../../../types';

import {
  UpdateTodoItemsGqlQuery,
  UpdateTodoItemsGqlStatus,
  TodoItemDone,
  TodoItemGqlError,
  TodoItemName,
  TodoItemVisibleForClients,
  TodoItemID
} from '../../todoItemsTypes';

import { useUpdateQuery } from '../../../common/hooks/base/reactQuery/useUpdateQuery';

interface UpdateTodoItemsInputItem {
  id: TodoItemID;
  todoItem: {
    done: TodoItemDone;
    name: TodoItemName;
    visibleForClients: TodoItemVisibleForClients;
  };
}

interface UpdateTodoItemsInput {
  todoItems: UpdateTodoItemsInputItem[];
}

interface UpdateTodoItemsResponse {
  updateTodoItems: {
    status: UpdateTodoItemsGqlStatus;
    errors: UpdateTodoItemsErrors;
  };
}

interface UpdateTodoItemsErrors {
  fullMessages: TodoItemGqlError;
}

interface UpdateTodoItemsOptions {
  query: UpdateTodoItemsGqlQuery;
  cacheKeys?: UpdateItemCacheKeys;
}

const action = 'updateTodoItems';

function useUpdateTodoItems<UpdateTodoItemsRecordType>({
  query,
  cacheKeys
}: UpdateTodoItemsOptions) {
  const {
    updateQueryData,
    updateQuery,
    updateQueryReset,
    updateQueryError,
    updateQueryErrorMessage,
    updateQueryLoading
  } = useUpdateQuery<
    UpdateTodoItemsInput,
    UpdateTodoItemsResponse,
    UpdateTodoItemsErrors,
    UpdateTodoItemsRecordType
  >({
    action,
    cacheKeys,
    query
  });

  return {
    updateTodoItemsData: updateQueryData,
    updateTodoItemsError: updateQueryError,
    updateTodoItemsLoading: updateQueryLoading,
    updateTodoItemsErrorMessage: updateQueryErrorMessage,
    updateTodoItems: updateQuery,
    updateTodoItemsReset: updateQueryReset
  };
}

export default useUpdateTodoItems;
