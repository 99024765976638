import React, {
  ChangeEventHandler,
  ClipboardEvent,
  FocusEvent,
  KeyboardEvent,
  useCallback,
  useEffect,
  useState
} from 'react';
import cl from 'classnames';
import TextareaAutosize from 'react-textarea-autosize';
import TurndownService from 'turndown';
import includes from 'lodash/includes';
import replace from 'lodash/replace';

import { useTranslate } from '../../common/hooks/useTranslate';

import { InsertTextTextarea } from './imports/InsertTextTextarea';

import { ClassName, ErrorMessage, I18nText } from '../../types';

import { Translate } from '../Translate';

const turndownService = new TurndownService().addRule('strikethrough', {
  filter: ['del', 's'], // 'strike'
  replacement: (content) => `~~${content}~~`
});

interface TextareaAutosizeInputProps {
  autoFocus?: boolean;
  className?: string;
  disabled?: boolean;
  errorClassName?: ClassName;
  errorMessage?: ErrorMessage;
  i18nLabel?: I18nText;
  i18nPlaceholder?: I18nText;
  inputWrapperClassName?: ClassName;
  label?: string;
  labelClassName?: ClassName;
  maxRows?: number;
  minRows?: number;
  name: string;
  onBlur?: (e: FocusEvent<HTMLTextAreaElement>) => void;
  onChange: (value: string) => void;
  onKeyDown?: (e: KeyboardEvent<HTMLTextAreaElement>) => void;
  pasteAsMarkdown?: boolean;
  placeholder?: string;
  value: string;
  readOnly?: boolean;
}

function TextareaAutosizeInput({
  autoFocus,
  className,
  disabled,
  errorClassName,
  errorMessage,
  i18nLabel,
  i18nPlaceholder,
  inputWrapperClassName,
  label,
  labelClassName,
  maxRows,
  minRows,
  name,
  onBlur,
  onChange,
  onKeyDown,
  pasteAsMarkdown,
  placeholder,
  value = '',
  readOnly
}: TextareaAutosizeInputProps) {
  const { t } = useTranslate();

  const handleValueChange = useCallback<
    ChangeEventHandler<HTMLTextAreaElement>
  >((e) => onChange(e?.target?.value), [onChange]);

  const [textareaElem, setTextareaElem] = useState<HTMLTextAreaElement | null>(
    null
  );

  useEffect(() => {
    if (autoFocus && textareaElem) {
      textareaElem.focus();
      textareaElem.selectionStart = textareaElem.value.length;
    }
  }, [autoFocus, textareaElem]);

  const handlePasteAsMarkdown = useCallback<
    (event: ClipboardEvent<HTMLTextAreaElement>) => void
  >(
    (e) => {
      if (!InsertTextTextarea.insertText) {
        return;
      }

      if (includes(e?.clipboardData?.types, 'text/rtf')) {
        return;
      }

      const htmlToPaste = e?.clipboardData?.getData('text/html');

      if (htmlToPaste) {
        e?.preventDefault();
        const markdownToPaste = replace(
          turndownService.turndown(htmlToPaste),
          /(?=<!--)([\s\S]*?)-->/g,
          ''
        );

        if (textareaElem) {
          InsertTextTextarea.insertText(textareaElem, markdownToPaste);
        }
      }
    },
    [textareaElem]
  );

  return (
    <>
      {i18nLabel || label ? (
        <label
          htmlFor={name}
          className={cl(
            labelClassName ||
              'block text-sm font-medium text-gray-700 dark:text-gray-300'
          )}
        >
          {i18nLabel ? <Translate id={i18nLabel} /> : label}
        </label>
      ) : null}

      <div className={inputWrapperClassName}>
        <TextareaAutosize
          className={className}
          disabled={disabled}
          maxRows={maxRows}
          minRows={minRows}
          name={name}
          onBlur={onBlur}
          onChange={handleValueChange}
          onKeyDown={onKeyDown}
          onPaste={pasteAsMarkdown ? handlePasteAsMarkdown : undefined}
          placeholder={i18nPlaceholder ? t(i18nPlaceholder) : placeholder}
          readOnly={readOnly}
          ref={setTextareaElem}
          value={value}
        />
      </div>

      {errorMessage && (
        <p className={errorClassName || 'mt-2 text-sm text-red-600'}>
          {/^forms\.errors+/.test(errorMessage) ? (
            <Translate id={errorMessage} />
          ) : (
            errorMessage
          )}
        </p>
      )}
    </>
  );
}

export default TextareaAutosizeInput;
