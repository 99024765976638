import React, { useCallback } from 'react';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';

import { ItemMessagesListMessageMenuDropdownMessage } from '../../ItemMessagesListMessageMenuDropdown.types';

import { CHECK_TODO_ITEMS_QUERY } from '../../../../../../../../todoItems/queries/checkTodoItems.query';

import { useCheckTodoItems } from '../../../../../../../../todoItems/hooks/useCheckTodoItems';
import { useShowToastOnErrorChange } from '../../../../../../../../../common/hooks/useShowToastOnErrorChange';

import { PureButtonHelper } from '../../../../../../../../../helpers/buttons/PureButtonHelper';

import { TodoItemsCache } from '../../../../../../../../todoItems/TodoItemsCache';

import { todoItemsKeys } from '../../../../../../../../../locales/keys';

interface MessageDropdownCheckTodoItemsButtonProps {
  message: ItemMessagesListMessageMenuDropdownMessage;
}

function MessageDropdownCheckTodoItemsButton({
  message
}: MessageDropdownCheckTodoItemsButtonProps) {
  const { checkTodoItems, checkTodoItemsLoading, checkTodoItemsErrorMessage } =
    useCheckTodoItems({
      query: CHECK_TODO_ITEMS_QUERY,
      indexCacheKey: TodoItemsCache.messageItemsCacheKey(message.uuid)
    });

  useShowToastOnErrorChange({ error: checkTodoItemsErrorMessage });

  const handleCheckTodoItems = useCallback(() => {
    checkTodoItems({ ids: map(message.todoItems, (todoItem) => todoItem.id) });
  }, [checkTodoItems, message.todoItems]);

  if (isEmpty(message.todoItems)) {
    return null;
  }

  return (
    <PureButtonHelper
      onClick={handleCheckTodoItems}
      className="text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-3 3xl:px-4 py-1.5 3xl:py-2 w-full flex items-center text-xs 3xl:text-sm whitespace-nowrap"
      i18nText={todoItemsKeys.checkAll}
      disabled={checkTodoItemsLoading}
    />
  );
}

export default MessageDropdownCheckTodoItemsButton;
