import React from 'react';
import { Controller, FieldPath } from 'react-hook-form';

import {
  ModalColorsFieldControl,
  ModalColorsFieldControlRequiredProps
} from './components/ModalColorsFieldControl';

import { ModalColorsFieldRequiredProps } from './ModalColorsField.types';

interface ModalColorsFieldProps<T> {
  name: FieldPath<T>;
  disabled?: boolean;
}

function ModalColorsField<T>({
  control,
  name,
  disabled,
  className,
  i18nTitle,
  modalIcon,
  icon,
  iconClassName,
  i18nText,
  i18nSubmitText,
  tooltipPlacement,
  tooltipI18nText,
  withoutInitialFocus
}: ModalColorsFieldProps<T> &
  ModalColorsFieldRequiredProps<T> &
  ModalColorsFieldControlRequiredProps) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <ModalColorsFieldControl
          value={value as string[]}
          disabled={disabled}
          className={className}
          i18nTitle={i18nTitle}
          icon={icon}
          iconClassName={iconClassName}
          i18nText={i18nText}
          modalIcon={modalIcon}
          i18nSubmitText={i18nSubmitText}
          tooltipPlacement={tooltipPlacement}
          tooltipI18nText={tooltipI18nText}
          onChange={onChange}
          withoutInitialFocus={withoutInitialFocus}
        />
      )}
    />
  );
}

export default ModalColorsField;
