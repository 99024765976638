import {
  MessageUUID,
  DownloadMessageSelectedProductsGqlQuery,
  DownloadMessageSelectedProductsGqlStatus,
  MessageGqlError,
  MessageDeviceNanoId
} from '../../messagesTypes';

import { useUpdateQuery } from '../../../common/hooks/base/reactQuery/useUpdateQuery';

interface DownloadMessageSelectedProductsOptions {
  query: DownloadMessageSelectedProductsGqlQuery;
}

export interface DownloadMessageSelectedProductsResponse<
  DownloadMessageSelectedProductsRecordType
> {
  downloadMessageSelectedProducts: {
    status: DownloadMessageSelectedProductsGqlStatus;
    recordUuid: MessageUUID;
    record: DownloadMessageSelectedProductsRecordType;
    errors: DownloadMessageSelectedProductsError;
  };
}

export interface DownloadMessageSelectedProductsInput {
  uuid: MessageUUID;
  deviceNanoId: MessageDeviceNanoId;
}

export interface DownloadMessageSelectedProductsError {
  fullMessages: MessageGqlError;
}

const action = 'downloadMessageSelectedProducts';

function useDownloadMessageSelectedProducts<
  DownloadMessageSelectedProductsRecordType
>({ query }: DownloadMessageSelectedProductsOptions) {
  const {
    updateQueryData,
    updateQuery,
    updateQueryReset,
    updateQueryError,
    updateQueryLoading,
    updateQueryErrorMessage
  } = useUpdateQuery<
    DownloadMessageSelectedProductsInput,
    DownloadMessageSelectedProductsResponse<DownloadMessageSelectedProductsRecordType>,
    DownloadMessageSelectedProductsError,
    DownloadMessageSelectedProductsRecordType
  >({ action, query });

  return {
    downloadMessageSelectedProductsData: updateQueryData,
    downloadMessageSelectedProductsError: updateQueryError,
    downloadMessageSelectedProductsLoading: updateQueryLoading,
    downloadMessageSelectedProductsErrorMessage: updateQueryErrorMessage,
    downloadMessageSelectedProducts: updateQuery,
    downloadMessageSelectedProductsReset: updateQueryReset
  };
}

export default useDownloadMessageSelectedProducts;
