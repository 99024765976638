import { gql } from 'graphql-request';

export const RESEND_MESSAGES_QUERY = gql`
  mutation ResendMessages($messageIds: [ID!]!, $onlyClients: Boolean) {
    resendMessages(
      input: { messageIds: $messageIds, onlyClients: $onlyClients }
    ) {
      errors {
        fullMessages
      }
      status
    }
  }
`;
