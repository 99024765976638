import { gql } from 'graphql-request';

import { MessageRed } from '../messagesTypes';

export interface ToggleMessageRedRecordType {
  red: MessageRed;
}

export const TOGGLE_MESSAGE_RED_QUERY = gql`
  mutation ToggleMessageRed($uuid: ID!) {
    toggleMessageRed(input: { uuid: $uuid }) {
      errors {
        fullMessages
      }
      recordUuid
      status
      record {
        red
      }
    }
  }
`;
