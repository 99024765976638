import { FileUrl } from '../../types';

import { IconsEnum } from '../../assets/icons/types';

import { Files } from '../Files';

function getFileIcon(src: FileUrl): IconsEnum {
  if (Files.isTableFile(src)) return IconsEnum.DOCUMENT_REPORT_SOLID;
  if (Files.isTextFile(src)) return IconsEnum.DOCUMENT_TEXT_SOLID;
  if (Files.isImage(src)) return IconsEnum.PHOTOGRAPH_SOLID;
  if (Files.isArchive(src)) return IconsEnum.DOCUMENT_ARCHIVE_SOLID;
  if (Files.isAudio(src)) return IconsEnum.DOCUMENT_AUDIO_SOLID;
  if (Files.isTextFile(src)) return IconsEnum.DOCUMENT_TEXT_SOLID;
  if (Files.isTableFile(src)) return IconsEnum.DOCUMENT_REPORT_SOLID;
  if (Files.isPDF(src)) return IconsEnum.DOCUMENT_PDF_SOLID;
  if (Files.isVideo(src)) return IconsEnum.DOCUMENT_VIDEO_SOLID;
  return IconsEnum.DOCUMENT_SOLID;
}

export default getFileIcon;
