import React from 'react';
import cl from 'classnames';
import compact from 'lodash/compact';
import { IconsEnum } from '../../../../../../../assets/icons/types';
import { ClassName } from '../../../../../../../types';
import { ItemMessagesListMessageSendEmailNotificationButtonMessage } from './ItemMessagesListMessageSendEmailNotificationButton.types';
import { FetchMessagesCacheKey } from '../../../../../messagesTypes';

import { MessageSendEmailNotificationButton } from '../../../../../components/buttons/MessageSendEmailNotificationButton';
import { CheckPermissions } from '../../../../../../../helpers/CheckPermissions';

import { MessagesPermissions } from '../../../../../messagesConstants';
import { messagesKeys } from '../../../../../../../locales/keys';

interface ItemMessagesListMessageSendEmailNotificationButtonProps {
  message: ItemMessagesListMessageSendEmailNotificationButtonMessage;
  messagesCacheKey?: FetchMessagesCacheKey;
  className?: ClassName;
  messageSendEmailNotificationButtonClassName?: ClassName;
}

function ItemMessagesListMessageSendEmailNotificationButton({
  message,
  messagesCacheKey,
  className,
  messageSendEmailNotificationButtonClassName
}: ItemMessagesListMessageSendEmailNotificationButtonProps) {
  if (!message?.visibleForClient) {
    return null;
  }

  return (
    <CheckPermissions
      action={MessagesPermissions.READ_MESSAGE_SEND_EMAIL_NOTIFICATION_BUTTON}
    >
      <div className={className}>
        <MessageSendEmailNotificationButton
          messageId={message.id}
          className={cl(
            messageSendEmailNotificationButtonClassName ||
              'text-xs text-gray-700 dark:text-gray-300 flex items-center',
            message.resentAt ? '' : 'underline'
          )}
          icon={message.resentAt ? IconsEnum.CHECK : IconsEnum.EMAIL_SOLID}
          iconClassName="h-3.5 w-3.5 mr-0.5"
          i18nText={
            message.resentAt
              ? messagesKeys.emailNotificationSent
              : messagesKeys.sendEmailNotification
          }
          disabled={!!message.resentAt}
          cacheKeys={compact([messagesCacheKey])}
        />
      </div>
    </CheckPermissions>
  );
}

export default ItemMessagesListMessageSendEmailNotificationButton;
