import { gql } from 'graphql-request';

export const CREATE_TODO_ITEMS = gql`
  mutation CreateTodoItems($todoItems: [CreateTodoItemsInputObject!]!) {
    createTodoItems(input: { todoItems: $todoItems }) {
      status
      errors {
        fullMessages
      }
    }
  }
`;
