import {
  MessageUUID,
  DownloadMessageSelectedMaterialsGqlQuery,
  DownloadMessageSelectedMaterialsGqlStatus,
  MessageGqlError,
  MessageDeviceNanoId
} from '../../messagesTypes';

import { useUpdateQuery } from '../../../common/hooks/base/reactQuery/useUpdateQuery';

interface DownloadMessageSelectedMaterialsOptions {
  query: DownloadMessageSelectedMaterialsGqlQuery;
}

export interface DownloadMessageSelectedMaterialsResponse<
  DownloadMessageSelectedMaterialsRecordType
> {
  downloadMessageSelectedMaterials: {
    status: DownloadMessageSelectedMaterialsGqlStatus;
    recordUuid: MessageUUID;
    record: DownloadMessageSelectedMaterialsRecordType;
    errors: DownloadMessageSelectedMaterialsError;
  };
}

export interface DownloadMessageSelectedMaterialsInput {
  uuid: MessageUUID;
  deviceNanoId: MessageDeviceNanoId;
}

export interface DownloadMessageSelectedMaterialsError {
  fullMessages: MessageGqlError;
}

const action = 'downloadMessageSelectedMaterials';

function useDownloadMessageSelectedMaterials<
  DownloadMessageSelectedMaterialsRecordType
>({ query }: DownloadMessageSelectedMaterialsOptions) {
  const {
    updateQueryData,
    updateQuery,
    updateQueryReset,
    updateQueryError,
    updateQueryLoading,
    updateQueryErrorMessage
  } = useUpdateQuery<
    DownloadMessageSelectedMaterialsInput,
    DownloadMessageSelectedMaterialsResponse<DownloadMessageSelectedMaterialsRecordType>,
    DownloadMessageSelectedMaterialsError,
    DownloadMessageSelectedMaterialsRecordType
  >({ action, query });

  return {
    downloadMessageSelectedMaterialsData: updateQueryData,
    downloadMessageSelectedMaterialsError: updateQueryError,
    downloadMessageSelectedMaterialsLoading: updateQueryLoading,
    downloadMessageSelectedMaterialsErrorMessage: updateQueryErrorMessage,
    downloadMessageSelectedMaterials: updateQuery,
    downloadMessageSelectedMaterialsReset: updateQueryReset
  };
}

export default useDownloadMessageSelectedMaterials;
