import React from 'react';
import isEmpty from 'lodash/isEmpty';

import { IconsEnum } from '../../../../../assets/icons/types';
import { ClassName, I18nText } from '../../../../../types';
import { ProjectID, ProjectNanoID } from '../../../../projects/projectsTypes';
import { TaskID, TaskNanoID } from '../../../../tasks/tasksTypes';
import { MessageVisibleForClient } from '../../../../messages/messagesTypes';

import { useCreateTodoItemsForm } from '../../forms/CreateTodoItemsForm/hooks/useCreateTodoItemsForm';

import { CreateTodoItemsForm } from '../../forms/CreateTodoItemsForm';

import { SimpleModalButton } from '../../../../../helpers/buttons/SimpleModalButton';
import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { PureButtonHelper } from '../../../../../helpers/buttons/PureButtonHelper';
import { PureFormButtonHelper } from '../../../../../helpers/buttons/PureFormButtonHelper';
import { PureTooltipIconButtonHelper } from '../../../../../helpers/buttons/PureTooltipIconButtonHelper';

import { todoItemsKeys, words } from '../../../../../locales/keys';
import { CheckPermissions } from '../../../../../helpers/CheckPermissions';
import { MessagesPermissions } from '../../../../messages/messagesConstants';

const CREATE_TODO_ITEMS_FORM = 'create-todo-items-form';

interface CreateMessageWithTodoItemsButtonWithTask {
  projectId?: ProjectID;
  projectNanoId?: ProjectNanoID;
  taskId: TaskID;
  taskNanoId?: TaskNanoID;
}

interface CreateMessageWithTodoItemsButtonWithProject {
  projectId: ProjectID;
  projectNanoId?: ProjectNanoID;
  taskId?: TaskID;
  taskNanoId?: TaskNanoID;
}

interface CreateMessageWithTodoItemsButtonDefaultProps {
  defaultValues?: string[];
  className?: ClassName;
  iconClassName?: ClassName;
  icon?: IconsEnum;
  i18nText?: I18nText;
  tooltipI18nText?: I18nText;
  i18nTextClassName?: ClassName;
  visibleForClient?: MessageVisibleForClient;
  afterCreateTodoItems?: () => void;
}

type CreateMessageWithTodoItemsButtonProps =
  CreateMessageWithTodoItemsButtonDefaultProps &
    (
      | CreateMessageWithTodoItemsButtonWithTask
      | CreateMessageWithTodoItemsButtonWithProject
    );

function CreateMessageWithTodoItemsButton({
  defaultValues,
  className,
  iconClassName,
  icon,
  i18nText,
  i18nTextClassName,
  tooltipI18nText,
  projectId,
  taskId,
  visibleForClient,
  projectNanoId,
  taskNanoId,
  afterCreateTodoItems
}: CreateMessageWithTodoItemsButtonProps) {
  const {
    appendTodoItem,
    control,
    batchAppendTodoItem,
    fields,
    fieldsValidationError,
    remove,
    register,
    createTodoItemsErrorMessage,
    createTodoItemsLoading,
    handleCreateTodoItems,
    resetCreateTodoItemsForm,
    handleDragItemEnd,
    toggleWithoutCheckbox,
    handleToggleVisibleForClient,
    visibleForClientValue
  } = useCreateTodoItemsForm({
    projectId,
    taskId,
    defaultNames: defaultValues,
    visibleForClient,
    projectNanoId,
    taskNanoId,
    afterCreateTodoItems
  });

  return (
    <SimpleModalButton
      className={
        className ||
        'flex text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap'
      }
      i18nTitle={todoItemsKeys.createTodoList}
      i18nText={i18nText}
      i18nTextClassName={i18nTextClassName || 'mr-auto'}
      tooltipI18nText={tooltipI18nText}
      i18nSubmitText={words.create}
      icon={icon}
      iconClassName={iconClassName}
      onOpen={resetCreateTodoItemsForm}
      isLoading={createTodoItemsLoading}
      onSubmit={handleCreateTodoItems}
      childrenClassName="flex-1 overflow-y-auto px-2 z-0"
      renderButtons={({ handleSubmit, hideModal }) => (
        <div className="flex-shrink">
          <div className="flex p-4 space-x-1 justify-between">
            <div className="flex space-x-1">
              <PureButtonHelper
                className="flex-1 sm:flex-initial rounded-md border px-4 py-2 border-transparent text-sm font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-800"
                disabled={createTodoItemsLoading}
                i18nText={words.cancel}
                onClick={hideModal}
              />
            </div>
            <div className="flex space-x-1">
              <CheckPermissions
                action={
                  MessagesPermissions.CHANGE_MESSAGE_VISIBLE_FOR_CLIENTS_IN_CREATE_TODO_LIST_MODAL
                }
              >
                <PureTooltipIconButtonHelper
                  className="py-2 pl-2 pr-2 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
                  tooltipI18nText={
                    visibleForClientValue ? words.visible : words.invisible
                  }
                  onClick={handleToggleVisibleForClient}
                  icon={
                    visibleForClientValue
                      ? IconsEnum.EYE_OUTLINE
                      : IconsEnum.EYE_OFF_OUTLINE
                  }
                  iconClassName="h-6 w-6"
                />
              </CheckPermissions>

              <PureFormButtonHelper
                className="flex-1 sm:flex-initial rounded-md border px-4 py-2 border-transparent text-sm font-medium text-white shadow-sm hover:shadow-md bg-blue-600 hover:bg-blue-700"
                disabled={createTodoItemsLoading || isEmpty(fields)}
                form={CREATE_TODO_ITEMS_FORM}
                i18nText={
                  createTodoItemsLoading ? words.processing : words.create
                }
                onClick={handleSubmit}
              />
            </div>
          </div>
        </div>
      )}
    >
      <CreateTodoItemsForm
        form={CREATE_TODO_ITEMS_FORM}
        isLoading={createTodoItemsLoading}
        control={control}
        fields={fields}
        fieldsValidationError={fieldsValidationError}
        register={register}
        onAddTodoItem={appendTodoItem}
        onAddTodoItems={batchAppendTodoItem}
        onRemoveTodoItem={remove}
        handleDragItemEnd={handleDragItemEnd}
        toggleWithoutCheckbox={toggleWithoutCheckbox}
      />

      <div className="px-4">
        <AlertMessage message={createTodoItemsErrorMessage} />
      </div>
    </SimpleModalButton>
  );
}

export default CreateMessageWithTodoItemsButton;
