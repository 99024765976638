import { FetchItemCacheKey, FetchItemsGqlQuery } from '../../../../types';

import {
  FetchTodoItemsGqlQuery,
  FetchTodoItemsFilters,
  FetchTodoItemsSort,
  FetchTodoItemsPage,
  FetchTodoItemsLimit,
  FetchTodoItemsInProjectCacheKey,
  FetchTodoItemsInTaskCacheKey
} from '../../todoItemsTypes';

import { useReadIndexQuery } from '../../../common/hooks/base/reactQuery/useReadIndexQuery';

import {
  INITIAL_TODO_ITEMS_FILTERS,
  INITIAL_TODO_ITEMS_LIMIT,
  INITIAL_TODO_ITEMS_PAGE,
  INITIAL_TODO_ITEMS_SORT
} from '../../todoItemsConstants';

interface TodoItemsDefaultOptions {
  cacheKey: FetchTodoItemsInProjectCacheKey | FetchTodoItemsInTaskCacheKey;
  query: FetchTodoItemsGqlQuery;
  initialFilters?: FetchTodoItemsFilters;
  initialSort?: FetchTodoItemsSort;
  initialPage?: FetchTodoItemsPage;
  initialLimit?: FetchTodoItemsLimit;
  options?: {
    cacheTime?: number;
    withoutPrefetch?: boolean;
  };
}

interface TodoItemsWithPrefetchItemOptions {
  fetchItemCacheKey: FetchItemCacheKey;
  fetchItemQuery: FetchItemsGqlQuery;
}

interface TodoItemsWithoutPrefetchItemOptions {
  fetchItemCacheKey?: never;
  fetchItemQuery?: never;
}

type TodoItemsOptions = TodoItemsDefaultOptions &
  (TodoItemsWithPrefetchItemOptions | TodoItemsWithoutPrefetchItemOptions);

const scope = 'todoItems';

function usePaginatedTodoItems<ItemType>({
  cacheKey,
  query,
  initialFilters = INITIAL_TODO_ITEMS_FILTERS,
  initialSort = INITIAL_TODO_ITEMS_SORT,
  initialPage = INITIAL_TODO_ITEMS_PAGE,
  initialLimit = INITIAL_TODO_ITEMS_LIMIT,
  fetchItemCacheKey,
  fetchItemQuery,
  options = {}
}: TodoItemsOptions) {
  const {
    data,
    items,
    itemsError,
    itemsTotalCount,
    isFetched,
    isLoading,
    isPlaceholderData,
    currentPage,
    currentLimit,
    currentFilters,
    currentSort,
    clearItemsFilters,
    filterItems,
    prefetchItems,
    sortItems,
    paginateItems,
    limitItems,
    prefetchItem,
    updateItemCache
  } = useReadIndexQuery<ItemType>({
    scope,
    query,
    cacheKey,
    initialFilters,
    initialLimit,
    initialPage,
    initialSort,
    fetchItemCacheKey,
    fetchItemQuery,
    options
  });

  return {
    todoItemsData: data,
    todoItems: items,
    todoItemsError: itemsError,
    todoItemsTotalCount: itemsTotalCount,
    todoItemsFetched: isFetched,
    todoItemsLoading: isLoading,
    todoItemsIsPlaceholderData: isPlaceholderData,
    todoItemsFilters: currentFilters,
    todoItemsSort: currentSort,
    todoItemsPage: currentPage,
    todoItemsLimit: currentLimit,
    filterTodoItems: filterItems,
    clearTodoItemsFilters: clearItemsFilters,
    sortTodoItems: sortItems,
    paginateTodoItems: paginateItems,
    limitTodoItems: limitItems,
    prefetchTodoItems: prefetchItems,
    prefetchTodoItem: prefetchItem,
    updateTodoItemCache: updateItemCache
  };
}

export default usePaginatedTodoItems;
