import React, { useCallback } from 'react';
import compact from 'lodash/compact';

import { ItemMessagesListMessageMenuDropdownMessage } from '../../ItemMessagesListMessageMenuDropdown.types';
import { FetchMessagesCacheKey } from '../../../../../../../messagesTypes';

import {
  TOGGLE_MESSAGE_PINNED_QUERY,
  ToggleMessagePinnedRecordType
} from '../../../../../../../queries/toggleMessagePinned.query';

import { useToggleMessagePinned } from '../../../../../../../hooks/useToggleMessagePinned';

import { PureButtonHelper } from '../../../../../../../../../helpers/buttons/PureButtonHelper';

import { TaskCache } from '../../../../../../../../tasks/TaskCache';
import { ProjectCache } from '../../../../../../../../projects/ProjectCache';
import { messagesKeys } from '../../../../../../../../../locales/keys';

interface MessageDropdownPinnedButtonProps {
  message: ItemMessagesListMessageMenuDropdownMessage;
  messagesCacheKey: FetchMessagesCacheKey;
}

function MessageDropdownPinnedButton({
  message,
  messagesCacheKey
}: MessageDropdownPinnedButtonProps) {
  const { toggleMessagePinned, toggleMessagePinnedLoading } =
    useToggleMessagePinned<ToggleMessagePinnedRecordType>({
      cacheKeys: compact([
        messagesCacheKey,
        message.project?.nanoId
          ? [ProjectCache.pinnedMessagesCacheKey(message.project.nanoId)]
          : null,
        message.task?.nanoId
          ? [TaskCache.pinnedMessagesCacheKey(message.task.nanoId)]
          : null
      ]),
      query: TOGGLE_MESSAGE_PINNED_QUERY
    });

  const handleToggleMessagePinned = useCallback<() => void>(
    () => toggleMessagePinned({ uuid: message.uuid }),
    [message.uuid, toggleMessagePinned]
  );

  return (
    <PureButtonHelper
      className="text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-3 3xl:px-4 py-1.5 3xl:py-2 w-full flex items-center text-xs 3xl:text-sm whitespace-nowrap"
      disabled={toggleMessagePinnedLoading}
      i18nText={message.pinnedAt ? messagesKeys.unpin : messagesKeys.pin}
      onClick={handleToggleMessagePinned}
    />
  );
}

export default MessageDropdownPinnedButton;
