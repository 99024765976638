import React from 'react';
import { Control, Controller, FieldPath } from 'react-hook-form';

import {
  IconBooleanFieldButton,
  IconBooleanFieldButtonRequiredProps
} from './components/IconBooleanFieldButton';

interface IconBooleanFieldProps<T> {
  control: Control<T>;
  name: FieldPath<T>;
  disabled?: boolean;
}

function IconBooleanField<T>({
  control,
  disabled,
  name,
  yesClassName,
  noClassName,
  yesIcon,
  yesIconClassName,
  noIconClassName,
  noIcon
}: IconBooleanFieldProps<T> & IconBooleanFieldButtonRequiredProps) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <IconBooleanFieldButton
          value={value as boolean}
          disabled={disabled}
          yesClassName={yesClassName}
          noClassName={noClassName}
          yesIcon={yesIcon}
          noIcon={noIcon}
          yesIconClassName={yesIconClassName}
          noIconClassName={noIconClassName}
          onChange={onChange}
        />
      )}
    />
  );
}

export default IconBooleanField;
