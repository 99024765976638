import {
  CreatedAt,
  CreatedAtFilter,
  CreateItemGqlQuery,
  CreateItemGqlStatus,
  DeleteItemCacheKeys,
  DeleteItemGqlQuery,
  DeleteItemGqlStatus,
  FetchItemCacheKey,
  FetchItemGqlQuery,
  FetchItemsCacheKey,
  FetchItemsEditItem,
  FetchItemsErrorMessage,
  FetchItemsFetched,
  FetchItemsGqlQuery,
  FetchItemsIsPlaceholderData,
  FetchItemsLimit,
  FetchItemsPage,
  FetchItemsPaginateItems,
  FetchItemsPrefetchItems,
  FetchItemsShowItem,
  FetchItemsSort,
  FetchItemsSortItems,
  FetchItemsTotalCount,
  ID,
  ItemGqlError,
  NanoIDFilter,
  UpdatedAt,
  UpdateItemGqlQuery,
  UpdateItemGqlStatus,
  UUID,
  UUIDFilter
} from '../../types';
import { CreateItemsGqlStatus } from '../../types/createItemsTypes';
import {
  UpdateItemsCacheKeys,
  UpdateItemsGqlQuery,
  UpdateItemsGqlStatus
} from '../../types/updateItemsTypes';

import {
  ProjectID,
  ProjectName,
  ProjectNanoID,
  ProjectUUID
} from '../projects/projectsTypes';

import { TaskID, TaskName, TaskNanoID, TaskUUID } from '../tasks/tasksTypes';

export type TodoItemID = ID;
export type TodoItemUUID = UUID;
export type TodoItemName = string;
export type TodoItemDone = boolean;
export type TodoItemOrder = number;
export type TodoItemVisibleForClients = boolean;
export type TodoItemCreatedAt = CreatedAt;
export type TodoItemUpdatedAt = UpdatedAt;

export type TodoItemProjectID = ProjectID;
export type TodoItemProjectUUID = ProjectUUID;
export type TodoItemProjectNanoID = ProjectNanoID;
export type TodoItemProjectName = ProjectName;

export type TodoItemTaskID = TaskID;
export type TodoItemTaskUUID = TaskUUID;
export type TodoItemTaskNanoID = TaskNanoID;
export type TodoItemTaskName = TaskName;

export enum TodoItemFields {
  ID = 'id',
  UUID = 'uuid',
  NAME = 'name',
  DONE = 'done',
  CREATED_AT = 'createdAt'
}

export interface FetchTodoItemsFilters {
  messageUuid?: UUIDFilter;
  messageNanoId?: NanoIDFilter;
  taskUuid?: UUIDFilter;
  taskNanoId?: NanoIDFilter;
  projectUuid?: UUIDFilter;
  projectNanoId?: NanoIDFilter;
  createdAt?: CreatedAtFilter;
}

export enum FetchTodoItemsSortableFields {
  ID = 'id',
  CREATED_AT = 'createdAt'
}

export enum FetchTodoItemsSortTypes {
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC',
  ORDER_ASC = 'ORDER_ASC',
  ORDER_DESC = 'ORDER_DESC',
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC'
}

export type CreateTodoItemInMessageGqlQuery = CreateItemGqlQuery;
export type FetchTodoItemsGqlQuery = FetchItemsGqlQuery;
export type FetchTodoItemGqlQuery = FetchItemGqlQuery;
export type CreateTodoItemInProjectGqlQuery = CreateItemGqlQuery;
export type CreateTodoItemInTaskGqlQuery = CreateItemGqlQuery;
export type CreateTodoItemsGqlQuery = CreateItemGqlQuery;
export type UpdateTodoItemGqlQuery = UpdateItemGqlQuery;
export type DeleteTodoItemGqlQuery = DeleteItemGqlQuery;
export type CheckTodoItemsGqlQuery = UpdateItemsGqlQuery;
export type CheckTodoItemsGqlStatus = UpdateItemsGqlStatus;
export type UncheckTodoItemsGqlQuery = UpdateItemsGqlQuery;
export type UncheckTodoItemsGqlStatus = UpdateItemsGqlStatus;
export type UpdateTodoItemsGqlQuery = UpdateItemsGqlQuery;

export type CreateTodoItemsGqlStatus = CreateItemsGqlStatus;
export type CreateTodoItemInMessageGqlStatus = CreateItemGqlStatus;
export type CreateTodoItemInProjectGqlStatus = CreateItemGqlStatus;
export type CreateTodoItemInTaskGqlStatus = CreateItemGqlStatus;
export type UpdateTodoItemGqlStatus = UpdateItemGqlStatus;
export type UpdateTodoItemsGqlStatus = UpdateItemsGqlStatus;
export type DeleteTodoItemGqlStatus = DeleteItemGqlStatus;
export type TodoItemGqlError = ItemGqlError;

export type FetchTodoItemsInProjectCacheKey = FetchItemsCacheKey;
export type FetchTodoItemsInTaskCacheKey = FetchItemsCacheKey;
export type FetchTodoItemInProjectCacheKey = FetchItemCacheKey;
export type FetchTodoItemInTaskCacheKey = FetchItemCacheKey;

export type UpdateTodoItemsCacheKeys = UpdateItemsCacheKeys;
export type DeleteTodoItemsCacheKeys = DeleteItemCacheKeys;

export type FetchTodoItemsFetched = FetchItemsFetched;
export type FetchTodoItemsErrorMessage = FetchItemsErrorMessage;
export type FetchTodoItemsIsPlaceholderData = FetchItemsIsPlaceholderData;
export type FetchTodoItemsPage = FetchItemsPage;
export type FetchTodoItemsLimit = FetchItemsLimit;
export type FetchTodoItemsSort = FetchItemsSort; // FetchTodoItemsSortTypes[];
export type FetchTodoItemsTotalCount = FetchItemsTotalCount;
export type FetchTodoItemsSortTodoItems = FetchItemsSortItems;
export type FetchTodoItemsPaginateTodoItems = FetchItemsPaginateItems;
export type FetchTodoItemsPrefetchTodoItems = FetchItemsPrefetchItems;
export type FetchTodoItemsShowTodoItem = FetchItemsShowItem;
export type FetchTodoItemsEditTodoItem = FetchItemsEditItem;
