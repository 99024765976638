import { MessageUUID } from '../../messagesTypes';
import { UpdateItemsCacheKeys } from '../../../../types/updateItemsTypes';

import { useUpdateQuery } from '../../../common/hooks/base/reactQuery/useUpdateQuery';

interface ToggleMessageFavoriteOptions {
  cacheKeys: UpdateItemsCacheKeys;
  query: string;
}

interface ToggleMessageFavoriteInput {
  uuid: MessageUUID;
}

interface ToggleMessageFavoriteError {
  fullMessages: string[] | null;
}

interface ToggleMessageFavoriteResponse<ToggleMessageFavoriteRecordType> {
  toggleMessageFavorite: {
    status: string;
    record: ToggleMessageFavoriteRecordType;
    errors: ToggleMessageFavoriteError;
  };
}

const action = 'toggleMessageFavorite';

function useToggleMessageFavorite<ToggleMessageFavoriteRecordType>({
  cacheKeys,
  query
}: ToggleMessageFavoriteOptions) {
  const {
    updateQueryData,
    updateQuery,
    updateQueryReset,
    updateQueryError,
    updateQueryLoading,
    updateQueryErrorMessage
  } = useUpdateQuery<
    ToggleMessageFavoriteInput,
    ToggleMessageFavoriteResponse<ToggleMessageFavoriteRecordType>,
    ToggleMessageFavoriteError,
    ToggleMessageFavoriteRecordType
  >({ action, cacheKeys, query });

  return {
    toggleMessageFavoriteData: updateQueryData,
    toggleMessageFavoriteError: updateQueryError,
    toggleMessageFavoriteLoading: updateQueryLoading,
    toggleMessageFavoriteErrorMessage: updateQueryErrorMessage,
    toggleMessageFavorite: updateQuery,
    toggleMessageFavoriteReset: updateQueryReset
  };
}

export default useToggleMessageFavorite;
