import { KeyboardEvent, useCallback, useEffect, useRef } from 'react';
import {
  Control,
  FieldArrayWithId,
  FieldError,
  UseFormRegister
} from 'react-hook-form';
import {
  DragDropContext,
  Droppable,
  OnDragEndResponder
} from '@hello-pangea/dnd';
import last from 'lodash/last';

import { IsLoading } from '../../../../../types';
import { CreateTodoItemsFormData } from './CreateTodoItemsForm.types';
import { IconsEnum } from '../../../../../assets/icons/types';
import { LexicalFieldRefType } from '../../../../../helpers/FormFields/LexicalEditorField/LexicalEditorField';

import { CreateTodoItemsFormItemFields } from './components/CreateTodoItemsFormItemFields';

import { LexicalEditorHelper } from '../../../../../helpers/LexicalEditorHelper';
import { Form } from '../../../../../helpers/Form';
import { Icon } from '../../../../../helpers/Icon';
import { TextareaAutosizeField } from '../../../../../helpers/FormFields/TextareaAutosizeField';

import { TodoItemFields } from '../../../todoItemsTypes';
import { todoItemsKeys } from '../../../../../locales/keys';

interface CreateTodoItemsFormProps {
  form: string;
  control: Control<CreateTodoItemsFormData>;
  fields: FieldArrayWithId<CreateTodoItemsFormData, 'todoItems', 'id'>[];
  register: UseFormRegister<CreateTodoItemsFormData>;
  isLoading?: IsLoading;
  onAddTodoItem: (name?: string) => void;
  onAddTodoItems: (names: string[]) => void;
  onRemoveTodoItem: (index: number) => void;
  handleDragItemEnd: OnDragEndResponder;
  toggleWithoutCheckbox: (index: number) => void;
  fieldsValidationError?: {
    name?: {
      text?: FieldError;
    };
  }[];
}

function CreateTodoItemsForm({
  form,
  fields,
  control,
  register,
  onAddTodoItem,
  onAddTodoItems,
  onRemoveTodoItem,
  isLoading,
  handleDragItemEnd,
  toggleWithoutCheckbox,
  fieldsValidationError
}: CreateTodoItemsFormProps) {
  const addFieldRef = useRef<LexicalFieldRefType>(null);

  useEffect(() => {
    addFieldRef.current?.focus();
  }, []);

  const handleKeyDown = useCallback<(e: KeyboardEvent<HTMLDivElement>) => void>(
    (e) => {
      if (e.key === 'Enter' && e.shiftKey) {
        return;
      }

      if (e.key === 'Enter') {
        e.stopPropagation();
        addFieldRef.current?.focus();
      }
    },
    []
  );

  return (
    <Form className="p-4" id={form}>
      <DragDropContext onDragEnd={handleDragItemEnd}>
        <div className="-mx-4">
          <div className="mb-2">
            <TextareaAutosizeField
              i18nPlaceholder={todoItemsKeys.enterListTitle}
              className="w-full resize-none font-semibold text-base bg-transparent placeholder-gray-600 placeholder:font-normal border-0 px-8 py-1 rounded focus:bg-gray-100 dark:focus:bg-gray-800 focus:border-0 dark:focus:border-0 focus:outline-none focus:ring-0 focus:ring-offset-0"
              disabled={isLoading}
              control={control}
              name="title"
            />
          </div>

          <div onKeyDownCapture={handleKeyDown}>
            <Droppable droppableId={form}>
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {fields.map((field, index) => (
                    <CreateTodoItemsFormItemFields
                      key={field.id}
                      index={index}
                      onRemove={onRemoveTodoItem}
                      control={control}
                      registerDone={register(
                        `todoItems.${index}.${TodoItemFields.DONE}`
                      )}
                      todoItemFieldId={field.id}
                      isLoading={isLoading}
                      toggleWithoutCheckbox={toggleWithoutCheckbox}
                      error={
                        fieldsValidationError?.[index]?.name?.text?.message
                      }
                    />
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>

          <div className="flex items-start gap-x-0.5">
            <div className="w-5 h-5" />

            <div className="flex items-center">
              <Icon icon={IconsEnum.PLUS_SOLID} className="w-5 h-5" />
            </div>

            <LexicalEditorHelper
              inputClassName="markdown min-h-[1.25rem] outline-none resize-none bg-transparent placeholder-gray-500 border-0 px-1 py-0 rounded focus:outline-none focus:ring-4 ring-blue-200 focus:bg-transparent"
              wrapperClassName="flex flex-1 -mt-0.5"
              disabled={isLoading}
              name="empty"
              onChange={(value) => {
                if (!value) return;

                const text = value.text?.split(/(?:\r?\n)+/) || [];
                if (text.length > 0) {
                  const textWithoutEmptyLines =
                    last(text) === '' ? text.slice(0, -1) : text;

                  if (textWithoutEmptyLines.length === 1) {
                    onAddTodoItem(text[0]);
                    addFieldRef.current?.blur();
                  }
                  if (textWithoutEmptyLines.length > 1) {
                    onAddTodoItems(text);
                    addFieldRef.current?.blur();
                  }
                }
              }}
              value={{ text: '' }}
              editorRef={addFieldRef}
            />
          </div>
        </div>
      </DragDropContext>
    </Form>
  );
}

export default CreateTodoItemsForm;
