import React from 'react';

import { MessageDropdownUploadToLibraryButtonMessage } from './MessageDropdownUploadToLibraryButton.types';

import { useUploadToLibrary } from './hooks/useUploadToLibrary';

import { NextLinkHelper } from '../../../../../../../../../helpers/links/NextLinkHelper';
import { Translate } from '../../../../../../../../../helpers/Translate';

import { resultsKeys } from '../../../../../../../../../locales/keys';
import { ProductPath } from '../../../../../../../../products/ProductPath';

interface MessageDropdownUploadToLibraryButtonProps {
  message: MessageDropdownUploadToLibraryButtonMessage;
}

function MessageDropdownUploadToLibraryButton({
  message
}: MessageDropdownUploadToLibraryButtonProps) {
  const { setCreateProductData, disabled } = useUploadToLibrary({
    message
  });

  return disabled ? (
    <span className="text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-3 3xl:px-4 py-1.5 3xl:py-2 w-full flex items-center text-xs 3xl:text-sm cursor-default">
      <Translate id={resultsKeys.uploadToLibrary} />
    </span>
  ) : (
    <NextLinkHelper
      id="upload-to-library"
      className="text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-3 3xl:px-4 py-1.5 3xl:py-2 w-full flex items-center text-xs 3xl:text-sm whitespace-nowrap"
      onClick={setCreateProductData}
      href={ProductPath.create()}
      target="_blank"
    >
      <Translate id={resultsKeys.uploadToLibrary} />
    </NextLinkHelper>
  );
}

export default MessageDropdownUploadToLibraryButton;
