import { TaskNanoID } from '../../../tasks/tasksTypes';

import {
  CreateMessageInTaskGqlQuery,
  CreateMessageInTaskGqlStatus,
  MessageGqlError,
  MessageUUID,
  MessageBody,
  MessageTaskID,
  MessageVisibleForClient,
  MessageFileAttachmentIDs,
  MessageSelectedProductIDs,
  MessageSelectedLifestyleIDs,
  MessageSelectedMaterialIDs,
  MessageColors,
  MessageMentionedUserIDs,
  MessageTodoItemName,
  MessageTodoItemDone,
  MessageTodoItemVisibleForClients,
  MessageTodoItemOrder,
  MessagePinnedAt,
  MessageID
} from '../../messagesTypes';

import { useCreateQuery } from '../../../common/hooks/base/reactQuery/useCreateQuery';

import { TaskCache } from '../../../tasks/TaskCache';

export interface CreateMessageInTaskInput {
  uuid?: MessageUUID;
  body: MessageBody;
  taskId: MessageTaskID;
  mentionedUserIds?: MessageMentionedUserIDs;
  visibleForClient: MessageVisibleForClient;
  fileAttachmentIds?: MessageFileAttachmentIDs;
  attachedFileAttachmentIds?: MessageFileAttachmentIDs;
  selectedProductIds?: MessageSelectedProductIDs;
  selectedLifestyleIds?: MessageSelectedLifestyleIDs;
  selectedMaterialIds?: MessageSelectedMaterialIDs;
  colors?: MessageColors;
  todoItems?: {
    name: MessageTodoItemName;
    done: MessageTodoItemDone;
    order?: MessageTodoItemOrder;
    visibleForClients: MessageTodoItemVisibleForClients;
  }[];
  pinnedAt?: MessagePinnedAt;
  repliedMessageId?: MessageID;
}

interface CreateMessageInTaskResponse<CreateMessageInTaskRecordType> {
  createMessageInTask: {
    status: CreateMessageInTaskGqlStatus;
    recordUuid: MessageUUID;
    record: CreateMessageInTaskRecordType;
    errors: CreateMessageInTaskErrors;
  };
}

interface CreateMessageInTaskErrors {
  done: MessageGqlError;
  fullMessages: MessageGqlError;
  name: MessageGqlError;
  taskId: MessageGqlError;
  visibleForClients: MessageGqlError;
}

interface CreateMessageInTaskOptions {
  taskNanoId: TaskNanoID;
  query: CreateMessageInTaskGqlQuery;
}

const action = 'createMessageInTask';

function useCreateMessageInTask<CreateMessageInTaskRecordType>({
  taskNanoId,
  query
}: CreateMessageInTaskOptions) {
  const {
    createQueryData,
    createQuery,
    createQueryReset,
    createQueryError,
    createQueryErrorMessage,
    createQueryLoading
  } = useCreateQuery<
    CreateMessageInTaskInput,
    CreateMessageInTaskResponse<CreateMessageInTaskRecordType>,
    CreateMessageInTaskErrors,
    CreateMessageInTaskRecordType
  >({
    action,
    cacheKeys: [
      TaskCache.messagesCacheKey(taskNanoId),
      TaskCache.lastMessagesCacheKey(taskNanoId),
      TaskCache.versionMessagesCacheKey(taskNanoId),
      TaskCache.pinnedMessagesCacheKey(taskNanoId)
    ],
    query
  });

  return {
    createMessageInTaskData: createQueryData,
    createMessageInTaskError: createQueryError,
    createMessageInTaskLoading: createQueryLoading,
    createMessageInTaskErrorMessage: createQueryErrorMessage,
    createMessageInTask: createQuery,
    createMessageInTaskReset: createQueryReset
  };
}

export default useCreateMessageInTask;
