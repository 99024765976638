import { FetchTodoItemsSortTypes } from './todoItemsTypes';

export const INITIAL_TODO_ITEMS_FILTERS = {};
export const INITIAL_TODO_ITEMS_PAGE = 1;
export const INITIAL_TODO_ITEMS_SORT = [
  FetchTodoItemsSortTypes.CREATED_AT_DESC
];
export const INITIAL_TODO_ITEMS_LIMIT = 50;

export const TODO_ITEM_NAME_WITHOUT_CHECKBOX = '@';
export const TODO_ITEM_NAME_TITLE = '^';
