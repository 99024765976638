import React, { Fragment, useCallback } from 'react';

import { SHOW_DEBUG } from '../../../../../../../config';

import { PureButtonHelper } from '../../../../../../../helpers/buttons/PureButtonHelper';
import { copyToClipboard } from '../../../../../../../utils/copyToClipboard';

import { ItemMessagesListMessageIdButtonsMessage } from './ItemMessagesListMessageIdButtons.types';

interface ItemMessagesListMessageIdButtonsProps {
  message: ItemMessagesListMessageIdButtonsMessage;
}

function ItemMessagesListMessageIdButtons({
  message
}: ItemMessagesListMessageIdButtonsProps) {
  const handleCopyId = useCallback(
    () => copyToClipboard(message.id as string),
    [message.id]
  );

  const handleCopyUuid = useCallback(
    () => copyToClipboard(message.uuid),
    [message.uuid]
  );

  if (!SHOW_DEBUG) {
    return null;
  }

  return (
    <Fragment>
      <PureButtonHelper text={message.id as string} onClick={handleCopyId} />{' '}
      <PureButtonHelper
        text={`${message.uuid} - ${message.messageType}`}
        onClick={handleCopyUuid}
      />{' '}
    </Fragment>
  );
}

export default ItemMessagesListMessageIdButtons;
