import React, { Fragment, useCallback, useState } from 'react';
import Twemoji from 'react-twemoji';
import isEmpty from 'lodash/isEmpty';

import { MessageLikeUsers } from '../../../main/messages/messagesTypes';

import { UserAvatar } from '../../../main/common/helpers/UserAvatar';
import { Tooltip } from '../../tooltips/Tooltip';
import { TooltipPlacement } from '../../tooltips/tooltipsConstants';

type PureTwemojiCountButtonHelperOnClick = (
  e: React.MouseEvent<HTMLButtonElement>
) => void;

export interface PureTwemojiCountButtonHelperProps {
  className?: string;
  disabled?: boolean;
  emojiText: string;
  emojiCount?: number;
  id?: string;
  onClick?: PureTwemojiCountButtonHelperOnClick;
  onMouseEnter?: () => void;
  style?: React.CSSProperties;
  tooltipUsers: MessageLikeUsers;
}

const twemojiOptions = {
  className: 'twemoji h-4 w-4',
  base: 'https://cdn.jsdelivr.net/gh/jdecked/twemoji@latest/assets/'
};

function PureTwemojiCountButtonHelper({
  id,
  className,
  style,
  disabled,
  onClick,
  onMouseEnter,
  emojiText,
  emojiCount,
  tooltipUsers
}: PureTwemojiCountButtonHelperProps) {
  const [referenceTooltipElement, setReferenceTooltipElement] =
    useState<HTMLButtonElement | null>(null);

  const handleClick = useCallback<PureTwemojiCountButtonHelperOnClick>(
    (e) => {
      e.preventDefault();
      onClick?.(e);
    },
    [onClick]
  );

  return (
    <Fragment>
      {isEmpty(tooltipUsers) ? null : (
        <Tooltip
          referenceElement={referenceTooltipElement}
          placement={TooltipPlacement.BOTTOM_START}
          withArrow
        >
          <div className="flex gap-1">
            {tooltipUsers.map((user) => (
              <div key={user.uuid}>
                <UserAvatar user={user} type="xs" />
              </div>
            ))}
          </div>
        </Tooltip>
      )}

      <button
        id={id}
        ref={setReferenceTooltipElement}
        className={className}
        style={style}
        disabled={disabled}
        onClick={handleClick}
        onMouseEnter={onMouseEnter}
        role="menuItem"
        type="button"
      >
        <Twemoji options={twemojiOptions}>{emojiText}</Twemoji>
        {emojiCount ? (
          <span className="text-xs font-medium">{emojiCount}</span>
        ) : null}
      </button>
    </Fragment>
  );
}

export default PureTwemojiCountButtonHelper;
