import { gql } from 'graphql-request';

import { MessagePinnedAt } from '../messagesTypes';

export interface ToggleMessagePinnedRecordType {
  pinnedAt: MessagePinnedAt;
}

export const TOGGLE_MESSAGE_PINNED_QUERY = gql`
  mutation ToggleMessagePinned($uuid: ID!) {
    toggleMessagePinned(input: { uuid: $uuid }) {
      errors {
        fullMessages
      }
      recordUuid
      status
      record {
        pinnedAt
      }
    }
  }
`;
