import { gql } from 'graphql-request';
import { MessageLikeItems } from '../messagesTypes';

export interface ToggleMessageLikeRecordType {
  messageLikes: MessageLikeItems;
}

export const TOGGLE_MESSAGE_LIKE_QUERY = gql`
  mutation ToggleMessageLike($uuid: ID!, $messageLikeType: String!) {
    toggleMessageLike(
      input: { uuid: $uuid, messageLikeType: $messageLikeType }
    ) {
      errors {
        fullMessages
      }
      recordUuid
      status
      record {
        uuid
        favorite
        pinnedAt
        visibleForClient
        red
        flagged
        messageLikes {
          uuid
          messageLikeType
        }
      }
    }
  }
`;
