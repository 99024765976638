const baseUrl = 'messages';

export class MessageBffUrl {
  static createFromAiProductsSearch() {
    return `${baseUrl}/create_from_ai_products_search`;
  }
  static createFromAiProductsSearchByAngle() {
    return `${baseUrl}/create_from_ai_products_search_by_angle`;
  }
  static createFromAiDetectProductsSearch() {
    return `${baseUrl}/create_from_ai_detect_products_search`;
  }
}
