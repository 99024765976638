import React from 'react';

import {
  TodoItemDone,
  TodoItemID,
  TodoItemName,
  TodoItemUUID,
  UpdateTodoItemsCacheKeys
} from '../../../../../../../../todoItems/todoItemsTypes';
import { UpdateIndexQueryItemCacheAction } from '../../../../../../../../common/hooks/base/reactQuery/useIndexQuery';

import {
  UPDATE_TODO_ITEM_QUERY,
  UpdateTodoItemQueryResponse
} from '../../../../../../../../todoItems/queries/updateTodoItem.query';

import { useUpdateTodoItem } from '../../../../../../../../todoItems/hooks/useUpdateTodoItem';

import { Checkbox } from '../../../../../../../../../helpers/Checkbox';
import { MarkdownHelper } from '../../../../../../../../../helpers/MarkdownHelper';

import { TODO_ITEM_NAME_WITHOUT_CHECKBOX } from '../../../../../../../../todoItems/todoItemsConstants';

const regexTodoItemNameWithoutCheckbox = new RegExp(
  `^${TODO_ITEM_NAME_WITHOUT_CHECKBOX}`
);

type ItemMessagesListMessageTodoListItemTodoItem = {
  id: TodoItemID;
  uuid: TodoItemUUID;
  name: TodoItemName;
  done: TodoItemDone;
};

interface ItemMessagesListMessageTodoListItemProps {
  cacheKeys: UpdateTodoItemsCacheKeys;
  todoItem: ItemMessagesListMessageTodoListItemTodoItem;
  updateTodoItemCache: UpdateIndexQueryItemCacheAction<UpdateTodoItemQueryResponse>;
}

function ItemMessagesListMessageTodoListItem({
  cacheKeys,
  todoItem,
  updateTodoItemCache
}: ItemMessagesListMessageTodoListItemProps) {
  const { updateTodoItemLoading, updateTodoItem } =
    useUpdateTodoItem<UpdateTodoItemQueryResponse>({
      cacheKeys,
      query: UPDATE_TODO_ITEM_QUERY,
      updateTodoItemCache
    });

  return (
    <div key={todoItem.id} className="flex gap-1 items-start py-1">
      {!regexTodoItemNameWithoutCheckbox.test(todoItem.name) && (
        <div className="flex items-center">
          <div className="flex items-center">
            <Checkbox
              checked={todoItem.done}
              onChange={(done) => updateTodoItem({ uuid: todoItem.uuid, done })}
              disabled={updateTodoItemLoading}
            />
          </div>
        </div>
      )}

      <MarkdownHelper
        source={todoItem.name.replace(regexTodoItemNameWithoutCheckbox, '')}
        className={
          todoItem.done
            ? 'break-wrap markdown markdown-sm sm:markdown-md markdown-pre:whitespace-pre-wrap leading-snug dark:markdown-invert line-through pl-2 text-sm'
            : 'break-wrap markdown markdown-sm sm:markdown-md markdown-pre:whitespace-pre-wrap leading-snug dark:markdown-invert pl-2 text-sm'
        }
        fromMessage
        linkTarget="_blank"
      />
    </div>
  );
}

export default ItemMessagesListMessageTodoListItem;
