import { gql } from 'graphql-request';

import { MayBe } from '../../../types';

import {
  MessageID,
  MessageUUID,
  MessageBody,
  MessageMessageType,
  MessageFavorite,
  MessageFlagged,
  MessagePinnedAt,
  MessageResentAt,
  MessageVisibleForClient,
  MessageColors,
  MessageCreatedAt,
  MessageHours,
  MessageMinutes,
  MessageUserUUID,
  MessageUserFullName,
  MessageUserClient,
  MessageUserImageUUID,
  MessageUserImageFile,
  MessageBillID,
  MessageBillUUID,
  MessageBillAmount,
  MessageBillCurrency,
  MessageBillBody,
  MessageBillStatus,
  MessageBillCreatedAt,
  MessageBillPayerUUID,
  MessageBillPayerNanoID,
  MessageBillPayerFullName,
  MessagePaidBillID,
  MessagePaidBillUUID,
  MessagePaidBillAmount,
  MessagePaidBillCurrency,
  MessagePaidBillPayerUUID,
  MessagePaidBillPayerFullName,
  MessageFileAttachmentsUUID,
  MessageFileAttachmentsName,
  MessageFileAttachmentsFile,
  MessageFileAttachmentsSize,
  MessageFileAttachmentsDrawing,
  MessageFileAttachmentsReference,
  MessageFileAttachmentsTexture,
  MessageFileAttachmentsCreatedAt,
  MessageFileAttachmentsWidth,
  MessageFileAttachmentsHeight,
  MessageFileAttachmentsDPI,
  MessageSelectedProductsUUID,
  MessageSelectedProductsCreatedAt,
  MessageSelectedProductsProductUUID,
  MessageSelectedProductsProductImageUUID,
  MessageSelectedProductsProductImageName,
  MessageSelectedProductsProductImageFile,
  MessageSelectedLifestylesUUID,
  MessageSelectedLifestylesCreatedAt,
  MessageSelectedLifestylesLifestyleUUID,
  MessageSelectedLifestylesLifestyleImageUUID,
  MessageSelectedLifestylesLifestyleImageName,
  MessageSelectedLifestylesLifestyleImageFile,
  MessageSelectedMaterialsUUID,
  MessageSelectedMaterialsCreatedAt,
  MessageSelectedMaterialsMaterialUUID,
  MessageSelectedMaterialsMaterialImageUUID,
  MessageSelectedMaterialsMaterialImageName,
  MessageSelectedMaterialsMaterialImageFile,
  MessageTaskID,
  MessageTaskUUID,
  MessageTaskStatus,
  MessageTaskMark,
  MessageTaskSupportMark,
  MessageBillPayable,
  MessageBillPaymentForm,
  MessageBillPaymentUrl,
  MessageBillAccount,
  MessageIsResult,
  MessageTaskNanoID,
  MessageSelectedLifestylesLifestyleName,
  MessageSelectedProductsProductBrand,
  MessageSelectedProductsProductName,
  MessagePaidBillPayerNanoID,
  MessageUserNanoID,
  MessageMentionedUserUUID,
  MessageMentionedUserID,
  MessageMentionedUserNanoID,
  MessageFileAttachmentsExt,
  MessageLikeItems,
  MessageFileAttachmentID,
  MessageSelectedMaterialsMaterialNda,
  MessageSelectedLifestylesLifestyleNda,
  MessageSelectedProductsProductNda,
  MessageSelectedProductID,
  MessageSelectedLifestyleID,
  MessageSelectedMaterialID,
  MessageSelectedLifestylesLifestyleCategoryLocalizedName,
  MessageUserUserCurrentTeamNanoID,
  MessageBillPayerClient,
  MessageBillPayerCurrentTeamNanoID,
  MessageProjectUserID,
  MessageTaskName,
  MessageProjectClient,
  MessageTaskProjectNanoID,
  MessageThreadKeyID,
  MessageWhiteboardKeyID,
  MessageThreadStart,
  MessageRed,
  MessageTodoItemID,
  MessageTodoItemName,
  MessageTodoItemDone,
  MessageTodoItemVisibleForClients,
  MessageTodoItemUUID,
  MessageProjectTeamName,
  MessageProjectTeamNanoID,
  MessageSortType,
  MessageResultVersion,
  MessageTodoItemOrder,
  MessageTaskProjectUUID,
  MessageProjectOwnerID,
  MessageProjectOwnerClient,
  MessageSelectedLifestylesLifestyleAuthorLocalizedName,
  MessageSelectedLifestylesLifestylePremiumContentAt,
  MessageSelectedProductsType,
  MessageFileAttachmentsType,
  MessageSelectedLifestylesType,
  MessageSelectedMaterialsType,
  MessageCreatedProductID,
  MessageClassification,
  MessageAiNotRelevantAt
} from '../messagesTypes';

export interface CreateMessageInTaskQueryResponse {
  id: MessageID;
  uuid: MessageUUID;
  body: MessageBody;
  messageType: MessageMessageType;
  favorite: MessageFavorite;
  flagged: MessageFlagged;
  pinnedAt?: MessagePinnedAt;
  resentAt?: MessageResentAt;
  visibleForClient: MessageVisibleForClient;
  colors: MessageColors;
  createdAt: MessageCreatedAt;
  createdProductId: MessageCreatedProductID;
  classification: MessageClassification;
  aiNotRelevantAt?: MessageAiNotRelevantAt;
  hours?: MessageHours;
  minutes?: MessageMinutes;
  taskId: MessageTaskID;
  isResult: MessageIsResult;
  resultVersion: MessageResultVersion;
  messageLikes: MessageLikeItems;
  threadKeyId: MessageThreadKeyID;
  whiteboardKeyId: MessageWhiteboardKeyID;
  threadStart: MessageThreadStart;
  red?: MessageRed;
  task: {
    uuid: MessageTaskUUID;
    id: MessageTaskID;
    nanoId: MessageTaskNanoID;
    name: MessageTaskName;
    status: MessageTaskStatus;
    mark: MessageTaskMark;
    supportMark: MessageTaskSupportMark;
    project: {
      nanoId: MessageTaskProjectNanoID;
      uuid: MessageTaskProjectUUID;
      user: { id: MessageProjectUserID; client: MessageProjectClient };
      owner: {
        id: MessageProjectOwnerID;
        client: MessageProjectOwnerClient;
      };
      team: {
        nanoId: MessageProjectTeamNanoID;
        name: MessageProjectTeamName;
      };
    };
  };
  user: {
    uuid: MessageUserUUID;
    nanoId: MessageUserNanoID;
    fullName: MessageUserFullName;
    client: MessageUserClient;
    currentTeam: MayBe<{
      nanoId: MessageUserUserCurrentTeamNanoID;
    }>;
    image: {
      uuid: MessageUserImageUUID;
      file: MessageUserImageFile;
    };
  };
  mentionedUsers: {
    id: MessageMentionedUserID;
    uuid: MessageMentionedUserUUID;
    nanoId: MessageMentionedUserNanoID;
  };
  bill?: {
    id: MessageBillID;
    uuid: MessageBillUUID;
    account: MessageBillAccount;
    amount: MessageBillAmount;
    currency: MessageBillCurrency;
    body: MessageBillBody;
    status: MessageBillStatus;
    createdAt: MessageBillCreatedAt;
    payable: MessageBillPayable;
    paymentForm: MessageBillPaymentForm;
    paymentUrl: MessageBillPaymentUrl;
    payer: {
      uuid: MessageBillPayerUUID;
      nanoId: MessageBillPayerNanoID;
      fullName: MessageBillPayerFullName;
      client: MessageBillPayerClient;
      currentTeam: MayBe<{
        nanoId: MessageBillPayerCurrentTeamNanoID;
      }>;
    };
  };
  paidBill?: {
    id: MessagePaidBillID;
    uuid: MessagePaidBillUUID;
    amount: MessagePaidBillAmount;
    currency: MessagePaidBillCurrency;
    payer: {
      uuid: MessagePaidBillPayerUUID;
      nanoId: MessagePaidBillPayerNanoID;
      fullName: MessagePaidBillPayerFullName;
    };
  };
  fileAttachments: {
    id: MessageFileAttachmentID;
    uuid: MessageFileAttachmentsUUID;
    type: MessageFileAttachmentsType;
    name: MessageFileAttachmentsName;
    file: MessageFileAttachmentsFile;
    size: MessageFileAttachmentsSize;
    drawing: MessageFileAttachmentsDrawing;
    reference: MessageFileAttachmentsReference;
    texture: MessageFileAttachmentsTexture;
    createdAt: MessageFileAttachmentsCreatedAt;
    ext: MessageFileAttachmentsExt;
    width: MessageFileAttachmentsWidth;
    height: MessageFileAttachmentsHeight;
    dpi: MessageFileAttachmentsDPI;
    whiteboardKeyId: MessageWhiteboardKeyID;
  }[];
  fileAttachment: MayBe<{
    id: MessageFileAttachmentID;
    uuid: MessageFileAttachmentsUUID;
    type: MessageFileAttachmentsType;
    name: MessageFileAttachmentsName;
    file: MessageFileAttachmentsFile;
    size: MessageFileAttachmentsSize;
    createdAt: MessageFileAttachmentsCreatedAt;
    ext: MessageFileAttachmentsExt;
    width: MessageFileAttachmentsWidth;
    height: MessageFileAttachmentsHeight;
    dpi: MessageFileAttachmentsDPI;
  }>;
  selectedProducts: {
    id: MessageSelectedProductID;
    uuid: MessageSelectedProductsUUID;
    type: MessageSelectedProductsType;
    createdAt: MessageSelectedProductsCreatedAt;
    product: {
      uuid: MessageSelectedProductsProductUUID;
      name: MessageSelectedProductsProductName;
      nda: MessageSelectedProductsProductNda;
      brand: MessageSelectedProductsProductBrand;
      image: {
        uuid: MessageSelectedProductsProductImageUUID;
        name: MessageSelectedProductsProductImageName;
        file: MessageSelectedProductsProductImageFile;
      };
    };
  }[];
  selectedLifestyles: {
    id: MessageSelectedLifestyleID;
    uuid: MessageSelectedLifestylesUUID;
    type: MessageSelectedLifestylesType;
    createdAt: MessageSelectedLifestylesCreatedAt;
    lifestyle: {
      uuid: MessageSelectedLifestylesLifestyleUUID;
      name: MessageSelectedLifestylesLifestyleName;
      nda: MessageSelectedLifestylesLifestyleNda;
      author: {
        localizedName: MessageSelectedLifestylesLifestyleAuthorLocalizedName;
      };
      category: {
        localizedName: MessageSelectedLifestylesLifestyleCategoryLocalizedName;
      };
      image: {
        uuid: MessageSelectedLifestylesLifestyleImageUUID;
        name: MessageSelectedLifestylesLifestyleImageName;
        file: MessageSelectedLifestylesLifestyleImageFile;
      };
      premiumContentAt: MessageSelectedLifestylesLifestylePremiumContentAt;
    };
  }[];
  selectedMaterials: {
    id: MessageSelectedMaterialID;
    uuid: MessageSelectedMaterialsUUID;
    type: MessageSelectedMaterialsType;
    createdAt: MessageSelectedMaterialsCreatedAt;
    material: {
      uuid: MessageSelectedMaterialsMaterialUUID;
      nda: MessageSelectedMaterialsMaterialNda;
      name: MessageSelectedMaterialsMaterialImageName;
      image: {
        uuid: MessageSelectedMaterialsMaterialImageUUID;
        name: MessageSelectedMaterialsMaterialImageName;
        file: MessageSelectedMaterialsMaterialImageFile;
      };
    };
  }[];
  sortType: MessageSortType;
  forwardedMessage?: {
    uuid: MessageUUID;
    body: MessageBody;
    createdAt: MessageCreatedAt;
    classification: MessageClassification;
    aiNotRelevantAt?: MessageAiNotRelevantAt;
    visibleForClient: MessageVisibleForClient;
    colors: MessageColors;
    user: {
      fullName: MessageUserFullName;
      client: MessageUserClient;
    };
  };
  repliedMessage?: {
    id: MessageID;
    uuid: MessageUUID;
    body: MessageBody;
    createdAt: MessageCreatedAt;
    classification: MessageClassification;
    aiNotRelevantAt?: MessageAiNotRelevantAt;
    visibleForClient: MessageVisibleForClient;
    colors: MessageColors;
    user: {
      fullName: MessageUserFullName;
      client: MessageUserClient;
    };
    fileAttachments: {
      uuid: MessageFileAttachmentsUUID;
      type: MessageFileAttachmentsType;
      name: MessageFileAttachmentsName;
      file: MessageFileAttachmentsFile;
      drawing: MessageFileAttachmentsDrawing;
      reference: MessageFileAttachmentsReference;
      texture: MessageFileAttachmentsTexture;
      createdAt: MessageFileAttachmentsCreatedAt;
    }[];
    selectedProducts: {
      uuid: MessageSelectedProductsUUID;
      type: MessageSelectedProductsType;
      createdAt: MessageSelectedProductsCreatedAt;
      product: {
        uuid: MessageSelectedProductsProductUUID;
        name: MessageSelectedProductsProductName;
        brand: MessageSelectedProductsProductBrand;
        image: {
          uuid: MessageSelectedProductsProductImageUUID;
          name: MessageSelectedProductsProductImageName;
          file: MessageSelectedProductsProductImageFile;
        };
      };
    }[];
    selectedLifestyles: {
      uuid: MessageSelectedLifestylesUUID;
      type: MessageSelectedLifestylesType;
      createdAt: MessageSelectedLifestylesCreatedAt;
      lifestyle: {
        uuid: MessageSelectedLifestylesLifestyleUUID;
        name: MessageSelectedLifestylesLifestyleName;
        image: {
          uuid: MessageSelectedLifestylesLifestyleImageUUID;
          name: MessageSelectedLifestylesLifestyleImageName;
          file: MessageSelectedLifestylesLifestyleImageFile;
        };
      };
    }[];
    selectedMaterials: {
      uuid: MessageSelectedMaterialsUUID;
      type: MessageSelectedMaterialsType;
      createdAt: MessageSelectedMaterialsCreatedAt;
      material: {
        uuid: MessageSelectedMaterialsMaterialUUID;
        name: MessageSelectedMaterialsMaterialImageName;
        image: {
          uuid: MessageSelectedMaterialsMaterialImageUUID;
          name: MessageSelectedMaterialsMaterialImageName;
          file: MessageSelectedMaterialsMaterialImageFile;
        };
      };
    }[];
    todoItems: {
      id: MessageTodoItemID;
      name: MessageTodoItemName;
      done: MessageTodoItemDone;
      order: MessageTodoItemOrder;
    }[];
  };
  todoItems: {
    id: MessageTodoItemID;
    uuid: MessageTodoItemUUID;
    name: MessageTodoItemName;
    order: MessageTodoItemOrder;
    done: MessageTodoItemDone;
    visibleForClients: MessageTodoItemVisibleForClients;
  }[];
}

export const CREATE_MESSAGE_IN_TASK_QUERY = gql`
  mutation CreateMessageInTask(
    $taskId: ID!
    $uuid: UUID
    $body: String!
    $mentionedUserIds: [ID!]
    $visibleForClient: Boolean
    $red: Boolean
    $flagged: Boolean
    $propagate: Boolean
    $pinnedAt: DateTime
    $colors: [String!]
    $fileAttachmentIds: [ID!]
    $attachedFileAttachmentIds: [ID!]
    $selectedProductIds: [ID!]
    $selectedLifestyleIds: [ID!]
    $selectedMaterialIds: [ID!]
    $todoItems: [CreateMessageInTaskTodoItem!]
    $repliedMessageId: ID
  ) {
    createMessageInTask(
      input: {
        taskId: $taskId
        uuid: $uuid
        body: $body
        mentionedUserIds: $mentionedUserIds
        visibleForClient: $visibleForClient
        red: $red
        flagged: $flagged
        propagate: $propagate
        pinnedAt: $pinnedAt
        colors: $colors
        fileAttachmentIds: $fileAttachmentIds
        attachedFileAttachmentIds: $attachedFileAttachmentIds
        selectedProductIds: $selectedProductIds
        selectedLifestyleIds: $selectedLifestyleIds
        selectedMaterialIds: $selectedMaterialIds
        todoItems: $todoItems
        repliedMessageId: $repliedMessageId
      }
    ) {
      status
      recordUuid
      record {
        id
        uuid
        body
        messageType
        favorite
        flagged
        pinnedAt
        resentAt
        visibleForClient
        colors
        createdAt
        createdProductId
        classification
        aiNotRelevantAt
        taskId
        hours
        minutes
        isResult
        resultVersion
        threadKeyId
        whiteboardKeyId
        threadStart
        red
        messageLikes {
          uuid
          messageLikeType
          userId
          user {
            uuid
            fullName
            image {
              file
            }
          }
        }
        task {
          id
          uuid
          nanoId
          name
          status
          mark
          supportMark
          project {
            nanoId
            uuid
            user {
              id
              client
            }
            owner {
              id
              client
            }
            team {
              nanoId
              name
            }
          }
        }
        user {
          uuid
          nanoId
          fullName
          client
          currentTeam {
            nanoId
          }
          image {
            uuid
            file
          }
        }
        mentionedUsers {
          id
          uuid
          nanoId
        }
        bill {
          id
          uuid
          account
          amount
          currency
          body
          status
          createdAt
          payable
          payer {
            uuid
            nanoId
            fullName
            client
            currentTeam {
              nanoId
            }
          }
        }
        paidBill {
          id
          uuid
          amount
          currency
          payer {
            uuid
            nanoId
            fullName
          }
        }
        fileAttachments {
          id
          uuid
          type
          name
          file
          size
          drawing
          reference
          texture
          createdAt
          ext
          width
          height
          dpi
          whiteboardKeyId
        }
        fileAttachment {
          id
          uuid
          type
          name
          file
          size
          createdAt
          ext
          width
          height
          dpi
        }
        selectedProducts {
          id
          uuid
          type
          createdAt
          product {
            uuid
            name
            nda
            brand {
              localizedName
            }
            image {
              uuid
              file
            }
          }
        }
        selectedLifestyles {
          id
          uuid
          type
          createdAt
          lifestyle {
            uuid
            name
            nda
            author {
              localizedName
            }
            category {
              localizedName
            }
            image {
              uuid
              file
            }
            premiumContentAt
          }
        }
        selectedMaterials {
          id
          uuid
          type
          createdAt
          material {
            uuid
            name
            nda
            image {
              uuid
              file
            }
          }
        }
        sortType
        forwardedMessage {
          uuid
          body
          colors
          createdAt
          classification
          aiNotRelevantAt
          visibleForClient
          user {
            fullName
            client
          }
        }
        repliedMessage {
          id
          uuid
          body
          createdAt
          classification
          aiNotRelevantAt
          visibleForClient
          colors
          user {
            fullName
            client
          }
          fileAttachments {
            uuid
            type
            name
            file
            drawing
            reference
            texture
            createdAt
          }
          selectedProducts {
            uuid
            type
            createdAt
            product {
              uuid
              name
              brand {
                localizedName
              }
              image {
                uuid
                name
                file
              }
            }
          }
          selectedLifestyles {
            uuid
            type
            createdAt
            lifestyle {
              uuid
              name
              image {
                uuid
                name
                file
              }
            }
          }
          selectedMaterials {
            uuid
            type
            createdAt
            material {
              uuid
              name
              image {
                uuid
                name
                file
              }
            }
          }
          todoItems {
            id
            name
            done
            order
          }
        }
        todoItems {
          id
          uuid
          name
          done
          order
          visibleForClients
        }
      }
      errors {
        taskId
        body
        visibleForClient
        red
        flagged
        propagate
        colors
        fileAttachmentIds
        attachedFileAttachmentIds
        selectedProductIds
        selectedLifestyleIds
        selectedMaterialIds
        fullMessages
      }
    }
  }
`;
