import React, { useCallback } from 'react';

import { ItemMessagesListMessageMenuDropdownMessage } from '../../ItemMessagesListMessageMenuDropdown.types';

import { useAddToDescriptionMessage } from '../../../../../../../hooks/useAddToDescriptionMessage';
import { useShowToastOnErrorChange } from '../../../../../../../../../common/hooks/useShowToastOnErrorChange';

import { PureButtonHelper } from '../../../../../../../../../helpers/buttons/PureButtonHelper';

import {
  AddToDescriptionMessageRecordType,
  ADD_TO_DESCRIPTION_MESSAGE_QUERY
} from '../../../../../../../queries/addToDescriptionMessage.query';

import { messagesKeys } from '../../../../../../../../../locales/keys';

import { ProjectCache } from '../../../../../../../../projects/ProjectCache';
import { TaskCache } from '../../../../../../../../tasks/TaskCache';

interface MessageDropdownAddToDescriptionButtonProps {
  message: ItemMessagesListMessageMenuDropdownMessage;
}

function MessageDropdownAddToDescriptionButton({
  message
}: MessageDropdownAddToDescriptionButtonProps) {
  const addToDescriptionCacheKeys = message.projectId
    ? [
        ProjectCache.showCacheKey(),
        ProjectCache.showSidebarCacheKey(),
        ProjectCache.showHeaderActionsCacheKey()
      ]
    : [
        TaskCache.showCacheKey(),
        TaskCache.showSidebarCacheKey(),
        TaskCache.showDashboardCacheKey(),
        TaskCache.showHeaderActionsCacheKey()
      ];

  const {
    addToDescriptionMessage,
    addToDescriptionMessageLoading,
    addToDescriptionMessageErrorMessage
  } = useAddToDescriptionMessage<AddToDescriptionMessageRecordType>({
    cacheKeys: addToDescriptionCacheKeys,
    query: ADD_TO_DESCRIPTION_MESSAGE_QUERY
  });

  useShowToastOnErrorChange({ error: addToDescriptionMessageErrorMessage });

  const handleAddToDescriptionMessage = useCallback<() => void>(
    () => addToDescriptionMessage({ uuid: message.uuid }),
    [addToDescriptionMessage, message.uuid]
  );

  return (
    <PureButtonHelper
      className="text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-3 3xl:px-4 py-1.5 3xl:py-2 w-full flex items-center text-xs 3xl:text-sm whitespace-nowrap"
      disabled={addToDescriptionMessageLoading}
      i18nText={messagesKeys.addToDescription}
      onClick={handleAddToDescriptionMessage}
    />
  );
}

export default MessageDropdownAddToDescriptionButton;
