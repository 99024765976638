import React, { useCallback } from 'react';

import { ItemMessagesListMessageMenuDropdownMessage } from '../../ItemMessagesListMessageMenuDropdown.types';
import { CheckableMessagesProps } from '../../../../../../../../../common/hooks/useMessagesCheckable';

import { PureButtonHelper } from '../../../../../../../../../helpers/buttons/PureButtonHelper';

import { words } from '../../../../../../../../../locales/keys';

type MessageDropdownSelectButtonProps = {
  message: ItemMessagesListMessageMenuDropdownMessage;
} & CheckableMessagesProps;

function MessageDropdownSelectButton({
  message,
  checkedMessages,
  onSetCheckedMessage
}: MessageDropdownSelectButtonProps) {
  const handleSelect = useCallback<() => void>(() => {
    onSetCheckedMessage(message);
  }, [message, onSetCheckedMessage]);

  return (
    <PureButtonHelper
      className="text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-3 3xl:px-4 py-1.5 3xl:py-2 w-full flex items-center text-xs 3xl:text-sm whitespace-nowrap"
      i18nText={checkedMessages?.[message.uuid] ? words.deselect : words.select}
      onClick={handleSelect}
    />
  );
}

export default MessageDropdownSelectButton;
