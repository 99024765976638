import React from 'react';

import { Icon } from '../../../../../../../helpers/Icon';
import { NextLinkHelper } from '../../../../../../../helpers/links/NextLinkHelper';
import { Translate } from '../../../../../../../helpers/Translate';
import { Checkbox } from '../../../../../../../helpers/Checkbox';

import { FoldersListItemView } from '../../FoldersListItem.types';

function FoldersListItemGridView({
  folder,
  handleCheck,
  checkboxChecked,
  checkable
}: FoldersListItemView) {
  return (
    <div className="flex flex-col relative rounded-md max-w-80 justify-self-center w-full border border-gray-200 dark:border-gray-700">
      {checkboxChecked ? (
        <div className="absolute inset-0 rounded-md ring-4 ring-blue-500 bg-blue-500 opacity-10 pointer-events-none" />
      ) : null}
      <div className="p-2 flex items-center space-x-2 2xl:space-x-4 truncate z-5 pointer-events-none">
        {checkable && (
          <div className="pointer-events-auto">
            <div className="flex items-center">
              <Checkbox checked={checkboxChecked} onChange={handleCheck} />
            </div>
          </div>
        )}
        {folder.icon ? <Icon icon={folder.icon} /> : null}
        <div className="flex-1 flex truncate leading-6">
          <span className="truncate">
            {folder.i18nText ? <Translate id={folder.i18nText} /> : folder.name}
          </span>
        </div>
      </div>
      <NextLinkHelper
        href={folder.href}
        className="absolute inset-0 rounded-md focus:ring-base focus:ring-offset-0"
        data-id="attachment-link"
      >
        <span className="sr-only">
          <span className="truncate">
            {folder.i18nText ? <Translate id={folder.i18nText} /> : folder.name}
          </span>
        </span>
      </NextLinkHelper>
    </div>
  );
}

export default FoldersListItemGridView;
