import React, { useCallback } from 'react';

import { CommonPermissions } from '../../../../../commonConstants';

import { Icon } from '../../../../../../../helpers/Icon';
import { NextLinkHelper } from '../../../../../../../helpers/links/NextLinkHelper';
import { Translate } from '../../../../../../../helpers/Translate';
import { Checkbox } from '../../../../../../../helpers/Checkbox';
import { DateTimeHelper } from '../../../../../../../helpers/DateTimeHelper';
import { UserAvatarLink } from '../../../../../helpers/UserAvatarLink';
import { CheckPermissions } from '../../../../../../../helpers/CheckPermissions';

import { words } from '../../../../../../../locales/keys';

import { FoldersListItemView } from '../../FoldersListItem.types';

type LinkHelperOnClick = (e: React.MouseEvent<HTMLAnchorElement>) => void;

function FoldersListItemListView({
  folder,
  handleCheck,
  checkboxChecked,
  checkable,
  onClick
}: FoldersListItemView) {
  const handleClick = useCallback<LinkHelperOnClick>(
    (e) => {
      if (onClick) {
        e.preventDefault();
        onClick(folder);
      }
    },
    [folder, onClick]
  );

  return (
    <div className="flex py-1 2xl:py-1.5 -mx-4 px-4 sm:-mx-6 sm:px-6 hover:bg-gray-100 dark:hover:bg-gray-850 border-t border-b border-transparent">
      <div className="flex items-center flex-1 space-x-2 2xl:space-x-4">
        {checkable && (
          <div className="flex items-center">
            <Checkbox checked={checkboxChecked} onChange={handleCheck} />
          </div>
        )}
        <div className="flex flex-1 relative h-6">
          <div className="absolute inset-x-0 -inset-y-1 2xl:-inset-y-1.5 flex items-center gap-2">
            <Icon icon={folder.icon} />
            <NextLinkHelper
              href={folder.href}
              className="flex-1 flex truncate leading-6"
              onClick={handleClick}
            >
              <span className="truncate">
                {folder.i18nText ? (
                  <Translate id={folder.i18nText} />
                ) : (
                  folder.name
                )}
              </span>
            </NextLinkHelper>
          </div>
        </div>
        <CheckPermissions
          action={CommonPermissions.READ_FOLDERS_LIST_FILE_COUNT_FIELD}
        >
          {folder.fileCount ? (
            <div className="hidden md:block w-16 text-right whitespace-nowrap lowercase">
              {folder.fileCount} <Translate id={words.files} />
            </div>
          ) : null}
        </CheckPermissions>
        <CheckPermissions
          action={CommonPermissions.READ_FOLDERS_LIST_DATE_FIELD}
        >
          {folder.date ? (
            <div className="hidden sm:block w-min text-right whitespace-nowrap">
              <DateTimeHelper date={folder.date} />
            </div>
          ) : null}
        </CheckPermissions>
        <CheckPermissions
          action={CommonPermissions.READ_FOLDERS_LIST_USER_FIELD}
        >
          {folder.user ? (
            <div className="hidden md:block w-8 text-right whitespace-nowrap">
              <UserAvatarLink
                className="h-6 w-6 ml-2 flex rounded-full overflow-hidden focus:ring-base"
                user={folder.user}
              />
            </div>
          ) : null}
        </CheckPermissions>
      </div>
    </div>
  );
}

export default FoldersListItemListView;
