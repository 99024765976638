import { HexColor } from '../../types';

function isBlackText(color: HexColor) {
  if (color.substring(0, 1) === '#') {
    color = color.substring(1) as HexColor;
  }

  const rgbColor = {
    r: parseInt(color.substring(0, 2), 16),
    g: parseInt(color.substring(2, 4), 16),
    b: parseInt(color.substring(4), 16)
  };

  const sum = Math.round(
    (rgbColor.r * 299 + rgbColor.g * 587 + rgbColor.b * 114) / 1000
  );

  return sum > 128;
}

export default isBlackText;
