import { Fragment, useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import countBy from 'lodash/countBy';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import keys from 'lodash/keys';
import find from 'lodash/find';

import { ItemMessagesListMessageLikesType } from './ItemMessagesListMessageLikesList.types';
import {
  FetchMessagesCacheKey,
  MessageLikeCount,
  MessageLikeType
} from '../../../../../messagesTypes';

import { useCurrentUser } from '../../../../../../../auth/hooks/useAuth';

import {
  ItemMessagesListMessageLikesListItem,
  ItemMessagesListMessageLikesListItemType
} from './components/ItemMessagesListMessageLikesListItem';

import { messageLikes } from '../ItemMessagesListMessageLikesPopover/messageLikesPopoverConstants';

interface ItemMessagesListMessageLikesListLikeCount {
  [key: MessageLikeType]: MessageLikeCount;
}

interface ItemMessagesListMessageLikesListProps {
  message: ItemMessagesListMessageLikesType;
  messagesCacheKey: FetchMessagesCacheKey;
}

function ItemMessagesListMessageLikesList({
  message,
  messagesCacheKey
}: ItemMessagesListMessageLikesListProps) {
  const { id: currentUserId } = useCurrentUser();
  const messageLikesList = useMemo<
    ItemMessagesListMessageLikesListItemType[]
  >(() => {
    const currentUserLikeType = find(message.messageLikes, {
      userId: currentUserId
    })?.messageLikeType;

    const messageLikeTypes: ItemMessagesListMessageLikesListLikeCount = countBy(
      map(message.messageLikes, 'messageLikeType')
    );

    return filter(messageLikes, ({ messageLikeType }) =>
      includes(keys(messageLikeTypes), messageLikeType)
    ).map((like) => {
      const likeUsers = message.messageLikes?.reduce((acc: [], messageLike) => {
        if (
          messageLike.messageLikeType === like.messageLikeType &&
          messageLike.user
        ) {
          return [...acc, messageLike.user];
        }
        return acc;
      }, []);

      return {
        ...like,
        count: messageLikeTypes[like.messageLikeType],
        isCurrentUserMessageLike: currentUserLikeType === like.messageLikeType,
        users: likeUsers
      };
    });
  }, [message?.messageLikes, currentUserId]);

  if (isEmpty(messageLikesList)) {
    return null;
  }

  return (
    <Fragment>
      {messageLikesList.map(
        ({
          emojiText,
          messageLikeType,
          users,
          count,
          isCurrentUserMessageLike
        }) => (
          <ItemMessagesListMessageLikesListItem
            tooltipUsers={users}
            messageLikeType={messageLikeType}
            messageUuid={message.uuid}
            messagesCacheKey={messagesCacheKey}
            emojiText={emojiText}
            key={messageLikeType}
            emojiCount={count}
            isCurrentUserMessageLike={isCurrentUserMessageLike}
          />
        )
      )}
    </Fragment>
  );
}

export default ItemMessagesListMessageLikesList;
