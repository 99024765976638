import map from 'lodash/map';

import { ItemMessagesListMessageMenuDropdownMessage } from '../../ItemMessagesListMessageMenuDropdown.types';
import { MessageFileAttachments } from '../../../../../../../messagesTypes';

import { SubmitResultsToTaskModalButton } from '../../../../../../../../tasks/components/modalButtons/SubmitResultsToTaskModalButton';

import { convertFileToDropzoneFile } from '../../../../../../../../../helpers/dropzone/DropzoneHelper/utils/convertFileToDropzoneFile';

import { tasksKeys } from '../../../../../../../../../locales/keys';

interface MessageDropdownSendResultProps {
  message: ItemMessagesListMessageMenuDropdownMessage;
}

function MessageDropdownSendResult({
  message
}: MessageDropdownSendResultProps) {
  const attachments = message.fileAttachments as MessageFileAttachments;

  const initialFiles = convertFileToDropzoneFile(attachments);

  const initialFileIds = map(attachments, 'id');

  return (
    <SubmitResultsToTaskModalButton
      taskNanoId={message.task?.nanoId}
      projectNanoId={message.project?.nanoId || message.task?.project?.nanoId}
      teamNanoId={
        message.project?.team?.nanoId || message.task?.project?.team?.nanoId
      }
      initialFileIds={initialFileIds}
      initialFiles={initialFiles}
      i18nText={tasksKeys.submitAsTaskResult}
      className="text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-3 3xl:px-4 py-1.5 3xl:py-2 w-full flex items-center text-xs 3xl:text-sm whitespace-nowrap"
    />
  );
}

export default MessageDropdownSendResult;
