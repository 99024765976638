import { MessageAiNotRelevantAt, MessageUUID } from '../../messagesTypes';
import { UpdateMessageAiNotRelevantRecordType } from '../../queries/updateMessageAiNotRelevant.query';
import { UpdateItemsCacheKeys } from '../../../../types/updateItemsTypes';

import { useUpdateQuery } from '../../../common/hooks/base/reactQuery/useUpdateQuery';

interface UpdateMessageAiNotRelevantOptions {
  cacheKeys: UpdateItemsCacheKeys;
  query: string;
}

interface UpdateMessageAiNotRelevantInput {
  uuid: MessageUUID;
  aiNotRelevantAt: MessageAiNotRelevantAt | null;
}

interface UpdateMessageAiNotRelevantError {
  fullMessages: string[] | null;
}

interface UpdateMessageAiNotRelevantResponse<UpdateMessageAiNotRelevantRecord> {
  updateMessageAiNotRelevantAt: {
    status: string;
    record: UpdateMessageAiNotRelevantRecord;
    errors: UpdateMessageAiNotRelevantError;
  };
}

const action = 'updateMessageAiNotRelevantAt';

function useUpdateMessageAiNotRelevant<
  UpdateMessageAiNotRelevantRecord extends UpdateMessageAiNotRelevantRecordType
>({ cacheKeys, query }: UpdateMessageAiNotRelevantOptions) {
  const {
    updateQueryData,
    updateQuery,
    updateQueryReset,
    updateQueryError,
    updateQueryLoading,
    updateQueryErrorMessage
  } = useUpdateQuery<
    UpdateMessageAiNotRelevantInput,
    UpdateMessageAiNotRelevantResponse<UpdateMessageAiNotRelevantRecord>,
    UpdateMessageAiNotRelevantError,
    UpdateMessageAiNotRelevantRecord
  >({
    action,
    cacheKeys,
    query
  });

  return {
    updateMessageAiNotRelevantData: updateQueryData,
    updateMessageAiNotRelevantError: updateQueryError,
    updateMessageAiNotRelevantLoading: updateQueryLoading,
    updateMessageAiNotRelevantErrorMessage: updateQueryErrorMessage,
    updateMessageAiNotRelevant: updateQuery,
    updateMessageAiNotRelevantReset: updateQueryReset
  };
}

export default useUpdateMessageAiNotRelevant;
