import {
  ModelingCategoryID,
  ModelingCategoryName,
  ModelingCategorySlug,
  ModelingCategoryExternalId,
  ModelingCategoryIsMatchingAllowed,
  ModelingCategoryIsTextSearchAllowed
} from '../../modelingCategoriesTypes';

import {
  useBffInfiniteIndexQuery,
  BffInfiniteIndexQueryBaseNodeType
} from '../../../common/hooks/base/reactQuery/useBffInfiniteIndexQuery';

import { ModelingCategoryBffUrl } from '../../ModelingCategoryBffUrl';
import { ModelingCategoryCache } from '../../ModelingCategoryCache';

export interface FetchModelingCategoriesModelingCategoryItemType
  extends BffInfiniteIndexQueryBaseNodeType {
  id: ModelingCategoryID;
  name: ModelingCategoryName;
  slug: ModelingCategorySlug;
  externalId: ModelingCategoryExternalId;
  isMatchingAllowed: ModelingCategoryIsMatchingAllowed;
  isTextSearchAllowed: ModelingCategoryIsTextSearchAllowed;
}

const scope = 'modelingCategories';

const options = {
  staleTime: 1000 * 60 * 60
};

function useModelingCategories() {
  const {
    data,
    items,
    itemsError,
    itemsErrorMessage,
    itemsTotalCount,
    isFetched,
    isLoading,
    isFetchingNextPage,
    isPlaceholderData,
    currentPage,
    currentLimit,
    currentFilters,
    currentSort,
    hasNextPage,
    clearItemsFilters,
    filterItems,
    changeItemsFilters,
    prefetchItems,
    sortItems,
    limitItems,
    loadMoreItems
  } = useBffInfiniteIndexQuery<FetchModelingCategoriesModelingCategoryItemType>(
    {
      cacheKey: ModelingCategoryCache.indexCacheKey(),
      scope,
      url: ModelingCategoryBffUrl.index(),
      options
    }
  );

  return {
    modelingCategoriesData: data,
    modelingCategories: items,
    modelingCategoriesError: itemsError,
    modelingCategoriesErrorMessage: itemsErrorMessage,
    modelingCategoriesTotalCount: itemsTotalCount,
    modelingCategoriesFetched: isFetched,
    modelingCategoriesLoading: isLoading,
    modelingCategoriesFetchingNextPage: isFetchingNextPage,
    modelingCategoriesIsPlaceholderData: isPlaceholderData,
    modelingCategoriesFilters: currentFilters,
    modelingCategoriesSort: currentSort,
    modelingCategoriesPage: currentPage,
    modelingCategoriesLimit: currentLimit,
    hasNextModelingCategoriesPage: hasNextPage,
    filterModelingCategories: filterItems,
    changeModelingCategoriesFilters: changeItemsFilters,
    clearModelingCategoriesFilters: clearItemsFilters,
    sortModelingCategories: sortItems,
    loadMoreModelingCategories: loadMoreItems,
    limitModelingCategories: limitItems,
    prefetchModelingCategories: prefetchItems
  };
}

export default useModelingCategories;
