import * as yup from 'yup';

import { formsErrors } from '../../../../../../../locales/keys';

export const updateTodoItemsFormValidationSchema = yup.object().shape({
  todoItems: yup.array().of(
    yup.object().shape({
      name: yup.object().shape({
        text: yup.string().required(formsErrors.required)
      })
    })
  )
});
