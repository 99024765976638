import { gql } from 'graphql-request';

import { MessageLikeItems } from '../messagesTypes';

export interface DownloadMessageSelectedMaterialsQueryResponse {
  messageLikes: MessageLikeItems;
}

export const DOWNLOAD_MESSAGE_SELECTED_MATERIALS_QUERY = gql`
  mutation DownloadMessageSelectedMaterials(
    $uuid: ID!
    $deviceNanoId: String!
  ) {
    downloadMessageSelectedMaterials(
      input: { uuid: $uuid, deviceNanoId: $deviceNanoId }
    ) {
      recordUuid
      status
      record {
        uuid
      }
      errors {
        fullMessages
      }
    }
  }
`;
