import React, { FocusEvent, KeyboardEvent } from 'react';
import { Control, Controller, FieldPath } from 'react-hook-form';

import { ClassName, ErrorMessage, I18nText } from '../../../types';

import { TextareaAutosizeInput } from '../../TextareaAutosizeInput';

interface TextareaAutosizeFieldProps<T> {
  autoFocus?: boolean;
  className?: string;
  control: Control<T>;
  disabled?: boolean;
  errorMessage?: ErrorMessage;
  i18nLabel?: I18nText;
  i18nPlaceholder?: I18nText;
  inputWrapperClassName?: ClassName;
  label?: string;
  labelClassName?: ClassName;
  maxRows?: number;
  minRows?: number;
  name: FieldPath<T>;
  onBlur?: (e: FocusEvent<HTMLTextAreaElement>) => void;
  onKeyDown?: (e: KeyboardEvent<HTMLTextAreaElement>) => void;
  pasteAsMarkdown?: boolean;
}

function TextareaAutosizeField<T>({
  autoFocus,
  className,
  control,
  disabled,
  errorMessage,
  i18nLabel,
  i18nPlaceholder,
  inputWrapperClassName,
  label,
  labelClassName,
  maxRows,
  minRows,
  name,
  onBlur,
  onKeyDown,
  pasteAsMarkdown
}: TextareaAutosizeFieldProps<T>) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <TextareaAutosizeInput
          autoFocus={autoFocus}
          className={className}
          disabled={disabled}
          errorMessage={errorMessage}
          i18nLabel={i18nLabel}
          i18nPlaceholder={i18nPlaceholder}
          inputWrapperClassName={inputWrapperClassName}
          label={label}
          labelClassName={labelClassName}
          maxRows={maxRows}
          minRows={minRows}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          onKeyDown={onKeyDown}
          pasteAsMarkdown={pasteAsMarkdown}
          value={value as string}
        />
      )}
    />
  );
}

export default TextareaAutosizeField;
