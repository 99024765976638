import { MessageUUID } from '../../messagesTypes';
import { UpdateItemsCacheKeys } from '../../../../types/updateItemsTypes';

import { useUpdateQuery } from '../../../common/hooks/base/reactQuery/useUpdateQuery';

interface ToggleMessageFlaggedOptions {
  cacheKeys: UpdateItemsCacheKeys;
  query: string;
}

interface ToggleMessageFlaggedInput {
  uuid: MessageUUID;
}

interface ToggleMessageFlaggedError {
  fullMessages: string[] | null;
}

interface ToggleMessageFlaggedResponse<ToggleMessageFlaggedRecordType> {
  toggleMessageFlagged: {
    status: string;
    record: ToggleMessageFlaggedRecordType;
    errors: ToggleMessageFlaggedError;
  };
}

const action = 'toggleMessageFlagged';

function useToggleMessageFlagged<ToggleMessageFlaggedRecordType>({
  cacheKeys,
  query
}: ToggleMessageFlaggedOptions) {
  const {
    updateQueryData,
    updateQuery,
    updateQueryReset,
    updateQueryError,
    updateQueryLoading,
    updateQueryErrorMessage
  } = useUpdateQuery<
    ToggleMessageFlaggedInput,
    ToggleMessageFlaggedResponse<ToggleMessageFlaggedRecordType>,
    ToggleMessageFlaggedError,
    ToggleMessageFlaggedRecordType
  >({ action, cacheKeys, query });

  return {
    toggleMessageFlaggedData: updateQueryData,
    toggleMessageFlaggedError: updateQueryError,
    toggleMessageFlaggedLoading: updateQueryLoading,
    toggleMessageFlaggedErrorMessage: updateQueryErrorMessage,
    toggleMessageFlagged: updateQuery,
    toggleMessageFlaggedReset: updateQueryReset
  };
}

export default useToggleMessageFlagged;
