import React, { useCallback } from 'react';
import cl from 'classnames';

import { CustomColorButtonCommonProps } from './CustomColorButton.types';

import { Icon } from '../../../helpers/Icon';
import { ButtonHelper } from '../../../helpers/buttons/ButtonHelper';

import { isBlackText } from '../../../utils/isBlackText';

type CustomColorButtonProps = CustomColorButtonCommonProps;

function CustomColorButton({ icon, color, onClick }: CustomColorButtonProps) {
  const handleClick = useCallback(() => onClick(color), [color, onClick]);

  const isBlackTextButton = isBlackText(color);

  return (
    <ButtonHelper
      className={cl(
        'px-3 py-2 rounded mt-0.5 mr-0.5 flex items-center space-x-1 cursor-pointer focus:ring-0 focus:shadow-none',
        isBlackTextButton ? 'text-black' : 'text-white'
      )}
      style={{ background: color }}
      onClick={handleClick}
    >
      <span>{color}</span>
      <Icon icon={icon} />
    </ButtonHelper>
  );
}

export default CustomColorButton;
