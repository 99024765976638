import { useCallback } from 'react';

import { I18nText } from '../../../../../../../../../types';

import {
  FetchMessagesCacheKey,
  MessageLikeType,
  MessageUUID
} from '../../../../../../../messagesTypes';

import {
  TOGGLE_MESSAGE_LIKE_QUERY,
  ToggleMessageLikeRecordType
} from '../../../../../../../queries/toggleMessageLike.query';

import {
  ToggleMessageLikeResponse,
  useToggleMessageLike
} from '../../../../../../../hooks/useToggleMessageLike';

import { TooltipPlacement } from '../../../../../../../../../helpers/tooltips/tooltipsConstants';
import { PureTooltipTwemojiButtonHelper } from '../../../../../../../../../helpers/buttons/PureTooltipTwemojiButtonHelper';

interface MessageLikesPopoverItemProps {
  emojiText: string;
  messageUuid: MessageUUID;
  messageLikeType: MessageLikeType;
  messagesCacheKey: FetchMessagesCacheKey;
  tooltipI18nText: I18nText;
  tooltipPlacement: TooltipPlacement;
}

function MessageLikesPopoverItem({
  emojiText,
  messageUuid,
  messageLikeType,
  messagesCacheKey,
  tooltipI18nText,
  tooltipPlacement
}: MessageLikesPopoverItemProps) {
  const { toggleMessageLike, toggleMessageLikeLoading } =
    useToggleMessageLike<ToggleMessageLikeRecordType>({
      indexCacheKey: messagesCacheKey,
      query: TOGGLE_MESSAGE_LIKE_QUERY
    });

  const handleToggleMessageLike = useCallback<
    () => Promise<ToggleMessageLikeResponse<ToggleMessageLikeRecordType>>
  >(
    () =>
      toggleMessageLike({
        uuid: messageUuid,
        messageLikeType: messageLikeType
      }),
    [messageUuid, messageLikeType, toggleMessageLike]
  );

  return (
    <PureTooltipTwemojiButtonHelper
      className="flex p-1.5 rounded w-9 h-9 dark:hover:bg-gray-800 hover:bg-gray-100"
      disabled={toggleMessageLikeLoading}
      emojiText={emojiText}
      key={emojiText}
      onClick={handleToggleMessageLike}
      tooltipI18nText={tooltipI18nText}
      tooltipPlacement={tooltipPlacement}
    />
  );
}

export default MessageLikesPopoverItem;
