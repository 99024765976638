import { words } from '../../../../../../../locales/keys';
import { TooltipPlacement } from '../../../../../../../helpers/tooltips/tooltipsConstants';

export const messageLikes = [
  {
    emojiText: '👍',
    tooltipI18nText: words.like,
    tooltipPlacement: TooltipPlacement.TOP,
    messageLikeType: 'thumbs-up'
  },
  {
    emojiText: '👎',
    tooltipI18nText: words.dislike,
    tooltipPlacement: TooltipPlacement.TOP,
    messageLikeType: 'thumbs-down'
  },
  {
    emojiText: '❤',
    tooltipI18nText: words.love,
    tooltipPlacement: TooltipPlacement.TOP,
    messageLikeType: 'red-heart'
  },
  {
    emojiText: '👏',
    tooltipI18nText: words.clap,
    tooltipPlacement: TooltipPlacement.TOP,
    messageLikeType: 'clapping-hands'
  },
  {
    emojiText: '🎉',
    tooltipI18nText: words.celebrate,
    tooltipPlacement: TooltipPlacement.TOP,
    messageLikeType: 'party-popper'
  },
  {
    emojiText: '🔥',
    tooltipI18nText: words.fire,
    tooltipPlacement: TooltipPlacement.TOP,
    messageLikeType: 'fire'
  },
  {
    emojiText: '🥰',
    tooltipI18nText: words.adorable,
    tooltipPlacement: TooltipPlacement.BOTTOM,
    messageLikeType: 'hugging-face'
  },
  {
    emojiText: '😆',
    tooltipI18nText: words.laughing,
    tooltipPlacement: TooltipPlacement.BOTTOM,
    messageLikeType: 'grinning-squinting-face'
  },
  {
    emojiText: '😯',
    tooltipI18nText: words.surprised,
    tooltipPlacement: TooltipPlacement.BOTTOM,
    messageLikeType: 'astonished-face'
  },
  {
    emojiText: '😢',
    tooltipI18nText: words.sad,
    tooltipPlacement: TooltipPlacement.BOTTOM,
    messageLikeType: 'crying-face'
  },
  {
    emojiText: '😱',
    tooltipI18nText: words.terrified,
    tooltipPlacement: TooltipPlacement.BOTTOM,
    messageLikeType: 'face-screaming-in-fear'
  },
  {
    emojiText: '😡',
    tooltipI18nText: words.mad,
    tooltipPlacement: TooltipPlacement.BOTTOM,
    messageLikeType: 'angry-face'
  }
];
