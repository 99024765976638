import { gql } from 'graphql-request';

import { MessageVisibleForClient } from '../messagesTypes';

export interface ToggleMessageVisibleForClientRecordType {
  visibleForClient: MessageVisibleForClient;
}

export const TOGGLE_MESSAGE_VISIBLE_FOR_CLIENT_QUERY = gql`
  mutation ToggleMessageVisibleForClient($uuid: ID!) {
    toggleMessageVisibleForClient(input: { uuid: $uuid }) {
      errors {
        fullMessages
      }
      recordUuid
      status
      record {
        visibleForClient
      }
    }
  }
`;
