import { MessageLikeType, MessageUUID } from '../../messagesTypes';
import { ToggleMessageLikeResponse } from './useToggleMessageLike.types';

import { useUpdateQuery } from '../../../common/hooks/base/reactQuery/useUpdateQuery';

interface ToggleMessageLikeOptions {
  indexCacheKey: string;
  query: string;
}

export interface ToggleMessageLikeInput {
  uuid: MessageUUID;
  messageLikeType: MessageLikeType;
}

export interface ToggleMessageLikeError {
  messageLikeType: string | null;
  fullMessages: string[] | null;
}

const action = 'toggleMessageLike';

function useToggleMessageLike<ToggleMessageLikeRecordType>({
  indexCacheKey,
  query
}: ToggleMessageLikeOptions) {
  const {
    updateQueryData,
    updateQuery,
    updateQueryReset,
    updateQueryError,
    updateQueryLoading,
    updateQueryErrorMessage
  } = useUpdateQuery<
    ToggleMessageLikeInput,
    ToggleMessageLikeResponse<ToggleMessageLikeRecordType>,
    ToggleMessageLikeError,
    ToggleMessageLikeRecordType
  >({ action, pluralScope: indexCacheKey, query });

  return {
    toggleMessageLikeData: updateQueryData,
    toggleMessageLikeError: updateQueryError,
    toggleMessageLikeLoading: updateQueryLoading,
    toggleMessageLikeErrorMessage: updateQueryErrorMessage,
    toggleMessageLike: updateQuery,
    toggleMessageLikeReset: updateQueryReset
  };
}

export default useToggleMessageLike;
