import { FileUrl, ImageDimensions } from '../../types';

function getImageUrlDimensions(url: FileUrl): Promise<ImageDimensions> {
  return new Promise((resolve, reject) => {
    const img = new Image();

    img.onload = () => resolve({ width: img.width, height: img.height });
    img.onerror = reject;
    img.src = url;
  });
}

export default getImageUrlDimensions;
