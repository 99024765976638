import isEmpty from 'lodash/isEmpty';

import { ClassName, I18nText } from '../../../../../types';
import {
  FetchMessagesCacheKey,
  MessageProjectUUID,
  MessageTaskUUID
} from '../../../messagesTypes';

import { IconsEnum } from '../../../../../assets/icons/types';

import { TooltipPlacement } from '../../../../../helpers/tooltips/tooltipsConstants';
import { SimpleModalButton } from '../../../../../helpers/buttons/SimpleModalButton';
import { AlertMessage } from '../../../../../helpers/AlertMessage';

import { useForwardMessageForm } from '../../forms/ForwardMessageForm/hooks/useForwardMessageForm';

import { ForwardMessageForm } from '../../forms/ForwardMessageForm';

import { words, messagesKeys } from '../../../../../locales/keys';

import { ForwardMessageModalButtonMessage } from './ForwardMessageModalButton.types';

interface ForwardMessageModalButtonProps {
  message: ForwardMessageModalButtonMessage;
  messagesCacheKey: FetchMessagesCacheKey;
  projectUuid: MessageProjectUUID;
  taskUuid?: MessageTaskUUID;
  className?: ClassName;
  i18nText?: I18nText;
  icon?: IconsEnum;
  tooltipI18nText?: I18nText;
  tooltipPlacement?: TooltipPlacement;
  tooltipSingleton?: boolean;
}

const FORWARD_MESSAGE_FORM = 'forward-message-form';

function ForwardMessageModalButton({
  message,
  messagesCacheKey,
  projectUuid,
  taskUuid,
  className,
  i18nText,
  icon,
  tooltipI18nText,
  tooltipPlacement,
  tooltipSingleton
}: ForwardMessageModalButtonProps) {
  const withAttachments =
    !isEmpty(message.fileAttachments) ||
    !isEmpty(message.selectedLifestyles) ||
    !isEmpty(message.selectedProducts) ||
    !isEmpty(message.selectedMaterials) ||
    !isEmpty(message.todoItems);

  const {
    control,
    validationErrors,
    forwardMessageSelectedProjectUuid,
    forwardMessageProjectDefaultValue,
    forwardMessageTaskDefaultValue,
    forwardMessageLoading,
    forwardMessageErrorMessage,
    handleSubmitForwardMessage,
    resetForwardMessageForm,
    handleCloseForwardMessage
  } = useForwardMessageForm({
    projectUuid,
    taskUuid,
    uuid:
      !message.body && message.forwardedMessage
        ? message.forwardedMessage?.uuid
        : message.uuid,
    messagesCacheKey,
    withAttachments
  });

  return (
    <SimpleModalButton
      className={
        className ||
        'py-0.5 pl-0.5 pr-0.5 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base text-gray-500 dark:text-gray-400 hover:text-gray-800 dark:hover:text-gray-100 focus:ring-offset-0'
      }
      submitClass="py-2 pl-4 pr-4 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base text-white bg-blue-500 hover:bg-blue-600 shadow-sm hover:shadow-md"
      childrenClassName="flex-1 overflow-y-auto px-2 z-0"
      modalSize="xl"
      submitDisabled={forwardMessageLoading}
      tooltipI18nText={tooltipI18nText}
      tooltipPlacement={tooltipPlacement}
      tooltipSingleton={tooltipSingleton}
      i18nSubmitText={words.send}
      i18nTitle={messagesKeys.forward}
      i18nText={i18nText}
      icon={icon}
      onOpen={resetForwardMessageForm}
      onSubmit={handleSubmitForwardMessage}
      onClose={handleCloseForwardMessage}
      withoutInitialFocus
    >
      <ForwardMessageForm
        message={message}
        withAttachments={withAttachments}
        control={control}
        form={FORWARD_MESSAGE_FORM}
        forwardMessageLoading={forwardMessageLoading}
        selectedProjectUuid={forwardMessageSelectedProjectUuid}
        forwardMessageProjectDefaultValue={forwardMessageProjectDefaultValue}
        forwardMessageTaskDefaultValue={forwardMessageTaskDefaultValue}
        taskUuidsValidationError={validationErrors.taskUuidsValidationError}
      />
      <AlertMessage addClassName="mt-4" message={forwardMessageErrorMessage} />
    </SimpleModalButton>
  );
}

export default ForwardMessageModalButton;
