import { gql } from 'graphql-request';

export const CHECK_TODO_ITEMS_QUERY = gql`
  mutation CheckTodoItems($ids: [ID!]!) {
    checkTodoItems(input: { ids: $ids }) {
      status
      errors {
        fullMessages
      }
    }
  }
`;
