import React from 'react';

import { MessageID } from '../../../../../../../messagesTypes';

import { ItemMessagesListMessageMenuDropdownMessage } from '../../ItemMessagesListMessageMenuDropdown.types';

import { ReplyMessageButton } from '../../../../../../buttons/ReplyMessageButton';

import { messagesKeys } from '../../../../../../../../../locales/keys';

interface MessageDropdownReplyModalButtonProps {
  message: ItemMessagesListMessageMenuDropdownMessage;
  onReplyMessage?: (messageId: MessageID) => void;
}

function MessageDropdownReplyModalButton({
  message,
  onReplyMessage
}: MessageDropdownReplyModalButtonProps) {
  if (!onReplyMessage) {
    return null;
  }

  return (
    <ReplyMessageButton
      messageId={message.id}
      onReplyMessage={onReplyMessage}
      className="text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-3 3xl:px-4 py-1.5 3xl:py-2 w-full flex items-center text-xs 3xl:text-sm whitespace-nowrap"
      i18nText={messagesKeys.reply}
    />
  );
}

export default MessageDropdownReplyModalButton;
