import { useCallback } from 'react';
import { Control, Controller, useWatch } from 'react-hook-form';
import { Draggable } from '@hello-pangea/dnd';

import { ID, IsLoading } from '../../../../../../../types';
import { UpdateTodoItemsFormData } from '../../UpdateTodoItemsForm.types';
import { TodoItemFields } from '../../../../../todoItemsTypes';

import { LexicalEditorField } from '../../../../../../../helpers/FormFields/LexicalEditorField';
import { CheckBoxField } from '../../../../../../../helpers/FormFields/CheckBoxField';
import { PureIconButtonHelper } from '../../../../../../../helpers/buttons/PureIconButtonHelper';
import { Icon } from '../../../../../../../helpers/Icon';
import { Translate } from '../../../../../../../helpers/Translate';

import { IconsEnum } from '../../../../../../../assets/icons/types';

interface UpdateTodoItemsFormItemFieldsProps {
  todoItemFieldId: ID;
  index: number;
  isLoading?: IsLoading;
  control: Control<UpdateTodoItemsFormData>;
  onRemove: (index: number) => void;
  toggleWithoutCheckbox: (index: number) => void;
  error?: string;
}

function UpdateTodoItemsFormItemFields({
  todoItemFieldId,
  index,
  isLoading,
  control,
  onRemove,
  toggleWithoutCheckbox,
  error
}: UpdateTodoItemsFormItemFieldsProps) {
  const withoutCheckbox = useWatch({
    control,
    name: `todoItems.${index}.withoutCheckbox`
  });

  const handleRemove = useCallback<() => void>(() => {
    onRemove(index);
  }, [index, onRemove]);

  const changeWithoutCheckbox = useCallback<() => void>(() => {
    toggleWithoutCheckbox(index);
  }, [index, toggleWithoutCheckbox]);

  return (
    <Draggable draggableId={`${index}-${todoItemFieldId}`} index={index}>
      {(provided) => (
        <div
          className="flex items-start gap-x-0.5"
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          <div
            className="flex items-center opacity-100 cursor-move"
            {...provided.dragHandleProps}
          >
            <Icon icon={IconsEnum.MENU_ALT_SOLID} className="w-5 h-5" />
          </div>

          {!withoutCheckbox && (
            <div className="flex items-center">
              <Controller
                control={control}
                name={`todoItems.${index}.${TodoItemFields.DONE}`}
                render={({ field: { onChange, value, name } }) => (
                  <CheckBoxField
                    id={name}
                    name={name}
                    onChange={onChange}
                    checked={value}
                    disabled={isLoading}
                  />
                )}
              />
            </div>
          )}

          <div className="flex-col flex-1 -mt-0.5">
            <LexicalEditorField
              inputClassName="markdown min-h-[1.25rem] outline-none resize-none bg-transparent placeholder-gray-500 border-0 px-1 py-0 rounded focus:outline-none focus:ring-4 ring-blue-200 focus:bg-transparent"
              wrapperClassName={
                error
                  ? 'flex flex-1 rounded border border-red-500'
                  : 'flex flex-1'
              }
              disabled={isLoading}
              control={control}
              name={`todoItems.${index}.${TodoItemFields.NAME}`}
            />
            {error && (
              <div className="text-red-500 text-xs">
                <Translate id={error} />
              </div>
            )}
          </div>

          <PureIconButtonHelper
            icon={IconsEnum.TRASH_OUTLINE}
            className="py-0.5 pl-0.5 pr-0.5 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
            onClick={handleRemove}
            iconClassName="w-5 h-5"
          />

          <PureIconButtonHelper
            icon={withoutCheckbox ? IconsEnum.CHAT : IconsEnum.CHAT_OUTLINE}
            className="py-0.5 pl-0.5 pr-0.5 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
            onClick={changeWithoutCheckbox}
            iconClassName="w-5 h-5"
          />
        </div>
      )}
    </Draggable>
  );
}

export default UpdateTodoItemsFormItemFields;
