import React, { useCallback } from 'react';

import { PureIconButtonHelper } from '../../../../buttons/PureIconButtonHelper';

import { IconBooleanFieldButtonRequiredProps } from './IconBooleanFieldButton.types';

interface IconBooleanFieldButtonProps {
  value: boolean;
  disabled?: boolean;
  onChange: (value: boolean) => void;
}

function IconBooleanFieldButton({
  value,
  yesClassName,
  noClassName,
  yesIcon,
  noIcon,
  disabled,
  onChange,
  yesIconClassName,
  noIconClassName
}: IconBooleanFieldButtonProps & IconBooleanFieldButtonRequiredProps) {
  const handleClick = useCallback<() => void>(
    () => onChange(!value),
    [value, onChange]
  );

  return (
    <PureIconButtonHelper
      className={value ? yesClassName : noClassName}
      disabled={disabled}
      icon={value ? yesIcon : noIcon}
      iconClassName={value ? yesIconClassName : noIconClassName}
      onClick={handleClick}
    />
  );
}

export default IconBooleanFieldButton;
