import { gql } from 'graphql-request';

import { MessageUUID } from '../messagesTypes';

export interface PropagateMessageRecordType {
  uuid: MessageUUID;
}

export const PROPAGATE_MESSAGE_QUERY = gql`
  mutation PropagateMessage($uuid: ID!) {
    propagateMessage(input: { uuid: $uuid }) {
      errors {
        fullMessages
      }
      recordUuid
      status
    }
  }
`;
