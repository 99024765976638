import { MessageUUID } from '../../messagesTypes';

import { useUpdateQuery } from '../../../common/hooks/base/reactQuery/useUpdateQuery';

interface ToggleMessageVisibleForClientOptions {
  indexCacheKey: string;
  query: string;
}

interface ToggleMessageVisibleForClientInput {
  uuid: MessageUUID;
}

interface ToggleMessageVisibleForClientError {
  fullMessages: string[] | null;
}

interface ToggleMessageVisibleForClientResponse<
  ToggleMessageVisibleForClientRecordType
> {
  toggleMessageVisibleForClient: {
    status: string;
    record: ToggleMessageVisibleForClientRecordType;
    errors: ToggleMessageVisibleForClientError;
  };
}

const action = 'toggleMessageVisibleForClient';

function useToggleMessageVisibleForClient<
  ToggleMessageVisibleForClientRecordType
>({ indexCacheKey, query }: ToggleMessageVisibleForClientOptions) {
  const {
    updateQueryData,
    updateQuery,
    updateQueryReset,
    updateQueryError,
    updateQueryLoading,
    updateQueryErrorMessage
  } = useUpdateQuery<
    ToggleMessageVisibleForClientInput,
    ToggleMessageVisibleForClientResponse<ToggleMessageVisibleForClientRecordType>,
    ToggleMessageVisibleForClientError,
    ToggleMessageVisibleForClientRecordType
  >({ action, cacheKeys: [indexCacheKey], query });

  return {
    toggleMessageVisibleForClientData: updateQueryData,
    toggleMessageVisibleForClientError: updateQueryError,
    toggleMessageVisibleForClientLoading: updateQueryLoading,
    toggleMessageVisibleForClientErrorMessage: updateQueryErrorMessage,
    toggleMessageVisibleForClient: updateQuery,
    toggleMessageVisibleForClientReset: updateQueryReset
  };
}

export default useToggleMessageVisibleForClient;
