import { gql } from 'graphql-request';

import {
  TodoItemDone,
  TodoItemID,
  TodoItemName,
  TodoItemUUID
} from '../todoItemsTypes';

export interface UpdateTodoItemQueryResponse {
  id: TodoItemID;
  uuid: TodoItemUUID;
  name: TodoItemName;
  done: TodoItemDone;
}

export const UPDATE_TODO_ITEM_QUERY = gql`
  mutation UpdateTodoItem(
    $uuid: ID!
    $name: String
    $visibleForClients: Boolean
    $done: Boolean
  ) {
    updateTodoItem(
      input: {
        uuid: $uuid
        name: $name
        visibleForClients: $visibleForClients
        done: $done
      }
    ) {
      status
      recordUuid
      record {
        id
        uuid
        name
        visibleForClients
        done
        createdAt
        updatedAt
      }
      errors {
        name
        visibleForClients
        done
        fullMessages
      }
    }
  }
`;
