import {
  CheckTodoItemsGqlQuery,
  CheckTodoItemsGqlStatus,
  TodoItemGqlError,
  TodoItemID
} from '../../todoItemsTypes';

import { useUpdateQuery } from '../../../common/hooks/base/reactQuery/useUpdateQuery';

interface CheckTodoItemsInput {
  ids: TodoItemID[];
}

interface CheckTodoItemsResponse {
  checkTodoItems: {
    status: CheckTodoItemsGqlStatus;
    errors: CheckTodoItemsError;
  };
}

interface CheckTodoItemsError {
  fullMessages: TodoItemGqlError;
}

interface CheckTodoItemsOptions {
  indexCacheKey: string;
  query: CheckTodoItemsGqlQuery;
}

const action = 'checkTodoItems';

function useCheckTodoItems({ query, indexCacheKey }: CheckTodoItemsOptions) {
  const {
    updateQueryData,
    updateQuery,
    updateQueryReset,
    updateQueryError,
    updateQueryLoading,
    updateQueryErrorMessage
  } = useUpdateQuery<
    CheckTodoItemsInput,
    CheckTodoItemsResponse,
    CheckTodoItemsError,
    undefined
  >({ action, pluralScope: indexCacheKey, query });

  return {
    checkTodoItemsData: updateQueryData,
    checkTodoItemsError: updateQueryError,
    checkTodoItemsLoading: updateQueryLoading,
    checkTodoItemsErrorMessage: updateQueryErrorMessage,
    checkTodoItems: updateQuery,
    checkTodoItemsReset: updateQueryReset
  };
}

export default useCheckTodoItems;
