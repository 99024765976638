import { ItemMessagesListMessageMenuDropdownMessage } from './ItemMessagesListMessageMenuDropdown.types';

import { PositionStyleEnum } from '../../../../../../../types';
import {
  FetchMessagesCacheKey,
  MessageID,
  MessageProjectUUID,
  MessageTaskUUID
} from '../../../../../messagesTypes';
import { CheckableMessagesProps } from '../../../../../../../common/hooks/useMessagesCheckable';

import { MessageDropdownContent } from './components/MessageDropdownContent';

import { TooltipDropdownHelper } from '../../../../../../../helpers/dropdowns/TooltipDropdownHelper';

import { words } from '../../../../../../../locales/keys';

import { PopoverPlacement } from '../../../../../../../helpers/Popover/popoverConstants';

type ItemMessagesListMessageMenuDropdownProps = {
  message: ItemMessagesListMessageMenuDropdownMessage;
  messagesCacheKey: FetchMessagesCacheKey;
  onReplyMessage?: (messageId: MessageID) => void;
  forwardMessageProjectUuid: MessageProjectUUID;
  forwardMessageTaskUuid?: MessageTaskUUID;
  withPropagateButton?: boolean;
  withPinButton?: boolean;
  tooltipSingleton?: boolean;
  dropdownPlacement?: PopoverPlacement;
  popoverPositionStyle?: PositionStyleEnum;
} & CheckableMessagesProps;

function ItemMessagesListMessageMenuDropdown({
  message,
  messagesCacheKey,
  forwardMessageProjectUuid,
  forwardMessageTaskUuid,
  onReplyMessage,
  withPropagateButton = false,
  withPinButton = false,
  tooltipSingleton,
  dropdownPlacement,
  popoverPositionStyle = PositionStyleEnum.fixed,
  checkedMessages,
  onSetCheckedMessage
}: ItemMessagesListMessageMenuDropdownProps) {
  return (
    <TooltipDropdownHelper
      tooltipSingleton={tooltipSingleton}
      tooltipI18nText={words.more}
      popoverPositionStyle={popoverPositionStyle}
      dropdownPlacement={dropdownPlacement}
      closeOnOutOfView
      buttonClassName="py-0.5 pl-0.5 pr-0.5 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base text-gray-500 dark:text-gray-400 hover:text-gray-800 dark:hover:text-gray-100 focus:ring-offset-0 inline-flex"
    >
      <MessageDropdownContent
        message={message}
        messagesCacheKey={messagesCacheKey}
        onReplyMessage={onReplyMessage}
        forwardMessageProjectUuid={forwardMessageProjectUuid}
        forwardMessageTaskUuid={forwardMessageTaskUuid}
        withPropagateButton={withPropagateButton}
        withPinButton={withPinButton}
        checkedMessages={checkedMessages}
        onSetCheckedMessage={onSetCheckedMessage}
      />
    </TooltipDropdownHelper>
  );
}

export default ItemMessagesListMessageMenuDropdown;
