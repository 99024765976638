import {
  UncheckTodoItemsGqlQuery,
  UncheckTodoItemsGqlStatus,
  TodoItemGqlError,
  TodoItemID
} from '../../todoItemsTypes';

import { useUpdateQuery } from '../../../common/hooks/base/reactQuery/useUpdateQuery';

interface UncheckTodoItemsInput {
  ids: TodoItemID[];
}

interface UncheckTodoItemsResponse {
  uncheckTodoItems: {
    status: UncheckTodoItemsGqlStatus;
    errors: UncheckTodoItemsError;
  };
}

interface UncheckTodoItemsError {
  fullMessages: TodoItemGqlError;
}

interface UncheckTodoItemsOptions {
  indexCacheKey: string;
  query: UncheckTodoItemsGqlQuery;
}

const action = 'uncheckTodoItems';

function useUncheckTodoItems({
  query,
  indexCacheKey
}: UncheckTodoItemsOptions) {
  const {
    updateQueryData,
    updateQuery,
    updateQueryReset,
    updateQueryError,
    updateQueryLoading,
    updateQueryErrorMessage
  } = useUpdateQuery<
    UncheckTodoItemsInput,
    UncheckTodoItemsResponse,
    UncheckTodoItemsError,
    undefined
  >({ action, pluralScope: indexCacheKey, query });

  return {
    uncheckTodoItemsData: updateQueryData,
    uncheckTodoItemsError: updateQueryError,
    uncheckTodoItemsLoading: updateQueryLoading,
    uncheckTodoItemsErrorMessage: updateQueryErrorMessage,
    uncheckTodoItems: updateQuery,
    uncheckTodoItemsReset: updateQueryReset
  };
}

export default useUncheckTodoItems;
